import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { transparentize } from 'polished';
import CallToAction from 'ui/components/CallToAction';
import Text from 'ui/components/Text';
import theme from 'styles/theme';

import ScheduleImg from 'resources/img/schedule.png';

const ScheduleContainer = styled.div`
  overflow: hidden;
  padding: 40px 0;
  background-color: ${transparentize(0.9, '#9fbfff')};
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 8px;

    button {
      text-align: center;
    }
  }
`;

const ScheduleWrppaer = styled(Container)`
  margin: auto;

  &&& {
    max-width: 1120px;
  }
`;

const ScheduleContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 319px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    justify-content: center;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    width: 100%;
    padding: 0 0 0 0;
/* 
    div > div {
      justify-content: left;
    } */
  }
`;

const ScheduleHeader = styled.h2`
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 1rem;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    max-width: unset;
    padding-top: 1rem;
  }
`;

const ScheduleDesc = styled.h2`
  max-width: 421px;
  font-family: ProductSans;
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  padding-bottom: 31px;

`;

const ScheduleAnimatedImg = styled(animated.img)`
  height: 319px;
  width: 565px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 60px 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
  }
`;

const Schedule = props => {
  return (
    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
    >
      <ScheduleContainer>
        <ScheduleWrppaer>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ScheduleContentWrapper >
                <div>
                  <Text fontSize="25px" fontWeight={700} mdTextAlign="center" maxWidth="358px" marginLeft="0" mdMarginLeft="auto">
                  Start <span>chatting</span> with them today
                  </Text>
                  <Text mdTextAlign="center" fontSize="18px" fontWeight={400} maxWidth="512px" color={theme.palette.text.four} marginTop="17px" marginBottom="30px">
                  You’re one click away from talking to your visitors, prospects, and customers in real-time—all while they’re thinking about you.
                  </Text>
                  <CallToAction justify="left"  mdJustify="center" smJustify="center" />
                </div>
              </ScheduleContentWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <ScheduleAnimatedImg src={ScheduleImg} alt="schedule" />
            </Grid>
          </Grid>
        </ScheduleWrppaer>
      </ScheduleContainer>
    </Waypoint>
  );
};

Schedule.propTypes = {};

export default Schedule;
