import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { transparentize } from 'polished';

const Input = styled(animated.input)`
  width: ${p => p.width || '313px'};
  height: 37px;
  padding: 0px 20px;
  font-size: 16px;
  border-radius: 24px;
  border-width: 0px;
  background-color: #e9ebf4;
  border-color: #e9ebf4;
  color: ${transparentize(0.46, '#21242c')};

  :focus {
    outline: 0;
    border-color: #0a7ef9;
  }
`;

export default Input;

