import React from 'react';
import { Container as MuiContainer, Grid as MuiGrid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import marketoProcess1 from 'resources/img/marketoProcess1.png';
import marketoProcess2 from 'resources/img/marketoProcess2.png';
import livechatProcess1 from 'resources/img/livechatProcess1.png';
import livechatProcess2 from 'resources/img/livechatProcess2.png';
import purpleChatPopup from 'resources/img/purpleChatPopup.png';
import roseChatPopup from 'resources/img/roseChatPopup.png';
import roseChatPopup2x from 'resources/img/roseChatPopup2x.png';
import { TabletDesktop, Mobile } from "ui/components/Media";
import theme from 'styles/theme';
import burgerBullet2x from 'resources/img/burgerBullet2x.png';
import burgerBullet from 'resources/img/burgerBullet.png';
import flash from 'resources/img/flash.png';
import flash2x from 'resources/img/flash2x.png';

const ProcessBulletPointWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
  margin-top: ${p => p.marginTop};
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      margin-bottom: 15px;
    }
`;


const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const Grid = styled(MuiGrid)`
        justify-content: center;
`;

const ProcessContainer = styled(animated.div)`
    padding: 1rem;
    text-align: center;
    vertical-align:middle;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      margin-top: 1rem;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    } 
`;

const SectionHeader = styled.h1`

  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  margin: 0 auto;
  padding-bottom: 10px;
  max-width: 700px;
  text-align: center;

  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
    margin-bottom: 2rem;
  }
`;




const ProcessWrapper = styled.div`
  overflow: hidden;
  padding: 30px 0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {

    button {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {

    button {
      text-align: center;
    }
  }
`;

const ProcessContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
  &&& {
    ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
    ${p => p.paddingLeft && `padding-left:${p.paddingLeft}`}
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
      &&& {
        padding: 0
      }
    }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    &&& {
      padding: 0
    }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    &&& {
      padding: 0;
    }

    div > div {
      justify-content: left;
    }
  }
`;

const ProcessHeader = styled.h2`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 24px;
  text-align: left;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    max-width: unset;
    padding-top: 1rem;
  }
`;

const ProcessDesc = styled.h2`
    max-width: 500px;
    font-family: ProductSans;
    font-size: 18px;
    line-height: 1.22;
    color: #757575;
    font-weight: normal;
    text-align: left;

`;

const ProcessAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 510px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    max-width: calc(414px - 2rem);
    width: 100%;
  }
`;

const ColorText = styled.span`
  &&& {
      color: ${p => p.color}
  }
`;


const ProcessBonus = styled.div`
  padding: 20px;
  background: ${p => p.theme.palette.blue.one};
  display: flex;
  align-items: center;
  margin-top:20px;
  border-radius: 20px;
  max-width: 500px; 
`;

const RevenueDetail = styled.div`
    margin-left: 20px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        margin-left: 0px;
    } 
`;


const RevenueCard = styled.div`
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    width: 50%;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
            width: 100%;
            margin-top: 30px
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
            width: 100%;
    } 
`;


const ProcessHeaderSection = styled.div`
    padding: 50px 1rem 28px;
    
    text-align: center;    
    vertical-align:middle;
    background: ${p => p.theme.palette.blue.one};

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        padding: 60px 36px;
    }

    
`;

const ProcessHeaderWrapper = styled.div`
    max-width: 1148px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        flex-direction: column;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        flex-direction: column;
    } 

`;


const ProcessHeaderImage = styled.div`
    width: 50%;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        width: 100%;
    } 

`;


const Process = props => {

    const ProcessTabletDesktopView = props => {
        return (
            <TabletDesktop>
                <ProcessWrapper>
                    <Container>
                        <Grid container spacing={4}>

                            <Grid md={6}>
                                <Image hdSrc={livechatProcess1} src={livechatProcess1} alt="Process" maxWidth="510px" width="100%" marginTop="4rem" mdMarginTop="0" height="unset" />
                            </Grid>
                            <Grid md={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Speak to Buyers Live On Site (Office Hours)
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Make the most of your sales teams’ time in the office by connecting them to qualified buyers who visit your website. You no longer need to wait for buyers to fill out a form on your website to talk to sales. It can happen now, while they’re researching on site.
                                        </ProcessDesc>
                                        <ProcessBulletPointWrapper marginTop="30px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                            Build a target audience directly in Insent or leverage our powerful integrations with <ColorText color={theme.palette.black}>Salesforce, Hubspot, and Marketo to build lists</ColorText> that target your most engaged buyers.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>Route your visitors</ColorText> in real-time to their appropriate sales representatives to start those sales conversations.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>Notify your sales team</ColorText> when their target accounts arrive on your website so they can proactively initiate conversations on site.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid md={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Generate Leads and Book Meetings with Chatbots (24/7)
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Automate chat conversations on behalf of your team while they’re away or out of office. Insent extends the power of live chat to offline hours so you can continue generating leads and booking meetings so don’t miss another sales opportunity.
                                    </ProcessDesc>
                                    <ProcessBulletPointWrapper marginTop="30px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                            <ColorText color={theme.palette.black}>Create chat templates</ColorText> for multiple use cases (like converting anonymous visitors into leads or booking a meeting with an engaged buyer) when your office is closed or teams are busy.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>Access chat reporting</ColorText> to see how your live conversations and chatbots are performing to make improvements and tweaks to templates.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>A/B test your chatbot greeting messages</ColorText> to optimize chat conversion rates.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                            <Grid md={6}>
                                <ProcessAnimatedImg src={livechatProcess2} alt="Process" />
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
            </TabletDesktop>
        );
    };

    const ProcessMobileView = props => {
        return (
            <Mobile>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={livechatProcess1} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Speak to Buyers Live On Site (Office Hours)
                                            </ProcessHeader>
                                        <ProcessDesc>
                                        Make the most of your sales teams’ time in the office by connecting them to qualified buyers who visit your website. You no longer need to wait for buyers to fill out a form on your website to talk to sales. It can happen now, while they’re researching on site.
                                            </ProcessDesc>
                                            <ProcessBulletPointWrapper marginTop="30px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                            Build a target audience directly in Insent or leverage our powerful integrations with <ColorText color={theme.palette.black}>Salesforce, Hubspot, and Marketo to build lists</ColorText> that target your most engaged buyers.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>Route your visitors</ColorText> in real-time to their appropriate sales representatives to start those sales conversations.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>Notify your sales team</ColorText> when their target accounts arrive on your website so they can proactively initiate conversations on site.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>

                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={livechatProcess2} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Generate Leads and Book Meetings with Chatbots (24/7)
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Automate chat conversations on behalf of your team while they’re away or out of office. Insent extends the power of live chat to offline hours so you can continue generating leads and booking meetings so don’t miss another sales opportunity.
                                    </ProcessDesc>
                                    <ProcessBulletPointWrapper marginTop="30px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                            <ColorText color={theme.palette.black}>Create chat templates</ColorText> for multiple use cases (like converting anonymous visitors into leads or booking a meeting with an engaged buyer) when your office is closed or teams are busy.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>Access chat reporting</ColorText> to see how your live conversations and chatbots are performing to make improvements and tweaks to templates.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                        <ProcessBulletPointWrapper marginTop="16px">
                                        <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                                        <Text fontSize="18px" smFontSize="14px" marginLeft="10px" textAlign="left"> 
                                        <ColorText color={theme.palette.black}>A/B test your chatbot greeting messages</ColorText> to optimize chat conversion rates.
                                        </Text>
                                        </ProcessBulletPointWrapper>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
            </Mobile>
        );
    };

    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            <>
                <ProcessContainer>
                <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center" maxWidth="800px" marginBottom="61px">
                A Live Chat that balances <br /> <span>human-first conversations</span> with automation
                        </Text>

                    <ProcessTabletDesktopView />
                    <ProcessMobileView />
                </ProcessContainer>
            </>
        </Waypoint>
    );
};

Process.propTypes = {};

export default Process;