import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import { Element } from 'react-scroll';

import noConversation from 'resources/img/noConversation.png';

const NoConversationContainer = styled(animated.div)`
    margin: 5rem;
    text-align: center;
    vertical-align: middle;
    background: ${p => p.theme.palette.blue.one};
    padding: 2rem;


    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      margin: 2rem;
    }
`;

const SectionHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  margin: 0 auto;
  padding-bottom: 12px;
  max-width: 700px;
  text-align: center;

  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
  }
`;

const SectionDescription = styled.p`
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  max-width: 675px;
  text-align: center; 
  padding-bottom: 36px;
  margin: 0 auto;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;

const SectionImage = styled(animated.img)`
  max-width: 541px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 425px;
  background-size: cover;
  padding: 2rem 0;
`;

const ConversationStarter = props => {




  return (
    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
    >

      <NoConversationContainer>
        <SectionHeader>Say no to generic conversation starters.</SectionHeader>
        <SectionImage src={noConversation} />
        <SectionDescription>Show your prospects what true personalization looks like when you use conversational marketing to usher them through a seamless buyer journey.</SectionDescription>
      </NoConversationContainer>
    </Waypoint>
  );
};

ConversationStarter.propTypes = {};

export default ConversationStarter;