import React from 'react';

const RightLine = props => (
  <svg viewBox="0 0 414.524 491.852" {...props}>
    <g fill="none">
      <path data-name="Line 4" stroke="#0a7ef9" d="M414.267.429l-396 238" />
      <path data-name="Line 5" stroke="#0a7ef9" d="M18.267 238.429l236 139" />
      <path data-name="Line 6" stroke="#0a7ef9" d="M230.267 491.429l-230-145" />
      <path data-name="Line 7" stroke="#0a7ef9" d="M378.267 119.429l-378 227" />
      <path data-name="Path 3" d="M253.075 377.427l-36.808 32" stroke="#067ef9" />
      <path data-name="Path 4" d="M215.767 409.429l74.616-9.471" stroke="#067ef9" />
      <path data-name="Path 1" d="M290.383 399.959l74.884 45.47" stroke="#067ef9" />
    </g>
  </svg>
);

export default RightLine;
