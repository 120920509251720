import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { transparentize } from 'polished';
import badge from 'resources/img/badge.png';
import coin from 'resources/img/coin.png';
import piggyCoin from 'resources/img/piggyCoin.png';
import chatMessage from 'resources/img/chatMessage.png';
import leftLine1 from 'resources/img/leftLine1.svg';

const LeftLine1 = styled.img`
  position: absolute;
  top: -23rem;
  left: -33rem;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: none;
    }
`;
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const SummarizeContainer = styled.div`
    opacity: 1;
    position: relative;
    overlfow: hidden;
`;


const DifferentiatorContainer = styled.div`
  overflow: hidden;
  padding: 120px 2rem;


  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 4rem 0;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 2rem 0;

    button {
      text-align: center;
    }
  }
`;

const SummarizeHeader = styled.h2`
  font-size: ${p => p.fontSize};
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 30px;
  padding-top: 10px;
  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    max-width: unset;
    padding-top: 1rem;
  }
`;

const SummarizeDesc = styled.h2`
  font-family: ProductSans;
  font-size: ${p => p.fontSize};
  line-height: 1.22;
  color: #757575;
  font-weight: normal;

`;

const BenefitContainer = styled.div`
  background-color: ${transparentize(0.9, '#9fbfff')};

overflow: hidden;
  padding: 100px 2rem;


  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 4rem 0;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 2rem 0;

    button {
      text-align: center;
    }
  }
`;

const BenefitContentWrapper = styled.div`
  text-align: center;
`;

const BenefitPoints = styled.div`
    text-align: center;
    margin-top: 36px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        width: calc(100vw - 48px);
    }
`;

const SummarizeAnimatedImg = styled(animated.img)`
  height: ${p => p.height};
`;

const DifferentiatorContentWrapper = styled.div`
    max-width: 800px;
`;

const Summarize = props => {
    const [isContentVisible, setContentIsVisible] = React.useState(false);

    const leftCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
    });
    const rightCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
    });

    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
            onEnter={() => setContentIsVisible(true)}
            onLeave={() => setContentIsVisible(false)}
        >
            <SummarizeContainer>
                <LeftLine1 src={leftLine1} />
                <BenefitContainer styled={leftCardStyleProps}>
                    <Container>
                        <BenefitContentWrapper>
                            <SummarizeDesc fontSize="20px">SO, LET’S SUMMARIZE:</SummarizeDesc>
                            <SummarizeHeader fontSize="40px">The benefits of human-first conversations:</SummarizeHeader>
                        </BenefitContentWrapper>
                        <Grid container spacing={6}>
                            <Grid item sm={6} md={3}>
                                <BenefitPoints>
                                    <SummarizeAnimatedImg
                                        src={chatMessage}
                                        height="60px"
                                    />
                                    <SummarizeHeader fontSize="22px">
                                        More conversations:
                                    </SummarizeHeader>
                                    <SummarizeDesc fontSize="18px">
                                        Buyers get answers they need from the people who know it best.
                                    </SummarizeDesc>
                                </BenefitPoints>
                            </Grid>
                            <Grid item sm={6} md={3}>
                                <BenefitPoints>
                                    <SummarizeAnimatedImg
                                        src={coin}
                                        height="60px"
                                    />
                                    <SummarizeHeader fontSize="22px">
                                        Faster revenue cycles:
                                    </SummarizeHeader>
                                    <SummarizeDesc fontSize="18px">
                                        You allow less friction and fewer stalled sales opportunities.
                                    </SummarizeDesc>
                                </BenefitPoints>
                            </Grid>
                            <Grid item sm={6} md={3}>
                                <BenefitPoints>
                                    <SummarizeAnimatedImg
                                        src={badge}
                                        height="70px"
                                    />
                                    <SummarizeHeader fontSize="22px">
                                        VIP buying experience:
                                    </SummarizeHeader>
                                    <SummarizeDesc fontSize="18px">
                                        Your best prospects expect it, so it’s only right you deliver.
                                    </SummarizeDesc>
                                </BenefitPoints>
                            </Grid>
                            <Grid item sm={6} md={3}>
                                <BenefitPoints>
                                    <SummarizeAnimatedImg
                                        src={piggyCoin}
                                        height="70px"
                                    />
                                    <SummarizeHeader fontSize="22px">
                                        Happier sales team:
                                    </SummarizeHeader>
                                    <SummarizeDesc fontSize="18px">
                                        They get connected to their top accounts from the start.
                                    </SummarizeDesc>
                                </BenefitPoints>
                            </Grid>
                        </Grid>
                    </Container>
                </BenefitContainer>
                <DifferentiatorContainer style={rightCardStyleProps}>
                    <Container>
                        <DifferentiatorContentWrapper>
                            <SummarizeDesc fontSize="20px">ALAS,</SummarizeDesc>
                            <SummarizeHeader fontSize="40px">The "<span>human</span>" competitive differentiator</SummarizeHeader>
                            <SummarizeDesc fontSize="20px">
                                Channels evolve, personas change, technologies advance, but the one thing that stays constant through it all is our humanity. So the best marketing strategy in an age of explosive technological innovation turns out to be the oldest—being human.
                            <br />
                                <br />
                                If you can cut through the noise of the digital world and help marketers forge deep connections at a human level, you can stand out from the competition.
                            <br />
                                At Insent, our vision is to help connect your best prospects to you— from the moment they land on your website.
                            <br />
                                <br />
                                We want to equip you with the platform that opens the back door while they’re already engaged and enables you with content consumption and campaign insights to trigger relevant conversations.
                        </SummarizeDesc>
                        </DifferentiatorContentWrapper>
                    </Container>
                </DifferentiatorContainer>
            </SummarizeContainer>
        </Waypoint>
    );
};

Summarize.propTypes = {};

export default Summarize;
