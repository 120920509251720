import axios from 'axios';




export default function request({ method, endpoint, config, companyName }) {
    let url = '';

    if (companyName && companyName.length) {
        url = `https://${companyName}.api.insent.ai/${endpoint}`;
    } else {
        return {errMessage: 'No company name found'}
    }

    return axios.request({
        method,
        url,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        ...config,
    });
}
