import React from 'react';
import { Container as MuiContainer, Grid as MuiGrid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import salesforceProcess1 from 'resources/img/salesforceProcess1.png';
import salesforceProcess2 from 'resources/img/salesforceProcess2.png';
import salesforceProcess3 from 'resources/img/salesforceProcess3.png';
import purpleChatPopup from 'resources/img/purpleChatPopup.png';
import roseChatPopup from 'resources/img/roseChatPopup.png';
import roseChatPopup2x from 'resources/img/roseChatPopup2x.png';
import { TabletDesktop, Mobile } from "ui/components/Media";
import theme from 'styles/theme';

import flash from 'resources/img/flash.png';
import flash2x from 'resources/img/flash2x.png';
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const Grid = styled(MuiGrid)`
        justify-content: center;
`;

const ProcessContainer = styled(animated.div)`
    padding: 1rem;
    margin-top: 3rem;
    text-align: center;
    vertical-align:middle;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      margin-top: 1rem;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    } 
`;



const ProcessWrapper = styled.div`
  overflow: hidden;
  padding: 30px 0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {

    button {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {

    button {
      text-align: center;
    }
  }
`;

const ProcessContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
  &&& {
    ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
    ${p => p.paddingLeft && `padding-left:${p.paddingLeft}`}
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
      &&& {
        padding: 0
      }
    }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    &&& {
      padding: 0
    }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    &&& {
      padding: 0;
    }

    div > div {
      justify-content: left;
    }
  }
`;

const ProcessHeader = styled.h2`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 24px;
  text-align: left;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
    max-width: unset;
    padding-top: 1rem;
  }
`;

const ProcessDesc = styled.h2`
    max-width: 500px;
    font-family: ProductSans;
    font-size: 18px;
    line-height: 1.22;
    color: #757575;
    font-weight: normal;
    text-align: left;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
  }
`;

const ProcessAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 510px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    max-width: calc(414px - 2rem);
    width: 100%;
  }
`;

const ColorText = styled.span`
  &&& {
      color: ${p => p.color}
  }
`;


const ProcessBonus = styled.div`
  padding: 20px;
  background: ${p => p.theme.palette.blue.one};
  display: flex;
  align-items: center;
  margin-top:20px;
  border-radius: 20px;
  max-width: 500px; 
`;

const RevenueDetail = styled.div`
    margin-left: 20px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        margin-left: 0px;
    } 
`;


const RevenueCard = styled.div`
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    width: 50%;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
            width: 100%;
            margin-top: 30px
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
            width: 100%;
    } 
`;


const ProcessHeaderSection = styled.div`
    padding: 50px 1rem 28px;
    
    text-align: center;    
    vertical-align:middle;
    background: ${p => p.theme.palette.blue.one};

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        padding: 60px 36px;
    }

    
`;

const ProcessHeaderWrapper = styled.div`
    max-width: 1148px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        flex-direction: column;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        flex-direction: column;
    } 

`;


const ProcessHeaderImage = styled.div`
    width: 50%;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        width: 100%;
    } 

`;


const Process = props => {


    const ProcessTabletDesktopView = props => {
        return (
            <TabletDesktop>
                

                <ProcessWrapper>
                    <Container>
                        <Grid container spacing={4}>

                            <Grid lg={6}>
                                <Image hdSrc={salesforceProcess1} src={salesforceProcess1} alt="Process" maxWidth="510px" width="100%" marginTop="0rem" mdMarginTop="0" height="unset" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Route and notify about who’s on site
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Prospects who chat are 82% more likely to convert than non-chatters. 
                                            <br />
                                            <br />
                                            Insent will immediately identify your Salesforce contacts who visit your site, and once they start engaging in a conversation, you can configure advanced routing systems. That includes alerting the account owner, a specific channel in your Slack or MS Teams, round robin the entire BDR team, or even one dedicated rep. It’s all based on how your sales organization is structured.
                                        </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                        Full account-view alongside chat
                                            </ProcessHeader>
                                        <ProcessDesc>
                                        83% of marketers say creating personalized content is their biggest challenge, so here’s how you can enable your sales team to personalize one-on-one conversations for engaged buyers. Access engagement metrics, campaign membership data, and account-level insights from Salesforce into one complete account view alongside Insent.ai’s chat platform. No more switching between screens to gather intel on your prospects.
                                    </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={salesforceProcess2} alt="Process" />
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container spacing={4}>

                            <Grid lg={6}>
                                <ProcessAnimatedImg src={salesforceProcess3} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Track conversations and impact
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            If its not in Salesforce, did it actually happen?
                                                <br />
                                            All of our sales reps' conversations will be stamped in Salesforce under the appropriate contract and account, along with any new  encriched and captured data. Leverage this information to track the impact of these conversations on pipeline and revenue,
                                            </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
            </TabletDesktop>
        );
    };

    const ProcessMobileView = props => {
        return (
            <Mobile>
                <ProcessWrapper>
                    <Container>
                        <Grid container spacing={4}>

                            <Grid lg={6}>
                                <ProcessAnimatedImg src={salesforceProcess1} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                <div>
                                        <ProcessHeader>
                                            Route and notify about who’s on site
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Prospects who chat are 82% more likely to convert than non-chatters. 
                                            <br />
                                            <br />
                                            Insent will immediately identify your Salesforce contacts who visit your site, and once they start engaging in a conversation, you can configure advanced routing systems. That includes alerting the account owner, a specific channel in your Slack or MS Teams, round robin the entire BDR team, or even one dedicated rep. It’s all based on how your sales organization is structured.
                                        </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={salesforceProcess2} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                <div>
                                        <ProcessHeader>
                                        Full account-view alongside chat
                                            </ProcessHeader>
                                        <ProcessDesc>
                                        83% of marketers say creating personalized content is their biggest challenge, so here’s how you can enable your sales team to personalize one-on-one conversations for engaged buyers. Access engagement metrics, campaign membership data, and account-level insights from Salesforce into one complete account view alongside Insent.ai’s chat platform. No more switching between screens to gather intel on your prospects.
                                    </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={salesforceProcess3} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Track conversations and impact
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            If its not in Salesforce, did it actually happen?
                                                <br />
                                            All of our sales reps' conversations will be stamped in Salesforce under the appropriate contract and account, along with any new  encriched and captured data. Leverage this information to track the impact of these conversations on pipeline and revenue,
                                            </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>

                        </Grid>
                    </Container>
                </ProcessWrapper>
            </Mobile>
        );
    };

    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            <>
                <ProcessContainer>

                    <ProcessTabletDesktopView />
                    <ProcessMobileView />
                </ProcessContainer>
            </>
        </Waypoint>
    );
};

Process.propTypes = {};

export default Process;