import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled, { keyframes } from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Header from '../Home/Header';
import Button from 'ui/components/Button';
import growth from './icons/growth.png';
import midMarket from './icons/midMarket.png';
import greenTick from './icons/greenTick.png';
import { TabletDesktop, Mobile } from "ui/components/Media";
import pricingHeroBg3x from './img/pricingHeroBg3x.png';
import entImg from './img/entImg.png';
import Select from 'react-select'
import AnimationInput from './AnimationInput';

import { growthPricing, midMarketPricing, pricingPlan, techStacksList, selectStyles, premiumPricing } from './constants';
const slowShakeAnimation = keyframes`
 0% {     margin-top: -12px; }
 50% { margin-top: 12px; }
 100% { margin-top: -12px; }
`
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;
  margin: auto;
  padding-top: 134px;
  padding-bottom: 57px;
  align-items: flex-end;
  display: flex;
  background: ${p => p.theme.palette.background.five};

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 158px 0 42;

    button {
      text-align: center;
    }
  }
`;

const HeroWrapper = styled.div`
align-items: center;
max-width: 1148px;
margin: auto;
display: flex;
width: 100%;
padding: 0 27px;
justify-content: space-between;

@media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
  flex-direction: column;
}
`;

const HeroContentWrapper = styled(animated.div)`
  display: flex;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 616px;

  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
  }

  .hs-form input[type=email] 
  {
    height: 50px;
    padding: 0px 171px 0 30px;
  }
  .hs-form input[type=submit] {
    height: 50px;
    padding: 12px 31px 12px 33px;
    font-weight: 600;
    font-size: 14px;
    line-height: 139.3%;
  }
`;


const HeroImageContainer = styled.div`
  max-width: 442px;
  margin: 0 auto;
  margin-right: 0;
  position: relative;
  WIDTH: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width:442px;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 40px;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-right: auto;
  }
`;

const PricingCalculatorContainer = styled.div`
  position: relative;
  padding: 24px 28px;
  margin: 54px 26px 49px 37px;
  height: 100%;
  max-width: 379px;
  background: linear-gradient(235.8deg, #65D0FB 2.46%, #0A7EF9 67.6%);
  box-shadow: 0px 20px 24px rgba(19, 24, 48, 0.14);
  border-radius: 20px;
  top: 0;
  left: 0;
  min-height: 339px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    
    margin: 54px 15px 49px;

    max-width:  unset;
  }
`;


const PriceListingFooter = styled.div`
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      flex-direction: column;
      }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
      }

`;
const PriceListingCard = styled.div`
position: relative;
min-height: 875px;
    background: #FFFFFF;
    padding: 41px 20px 39px 35px;
    height: 100%;
    max-width: 533px;
    margin-bottom: 74px;
    flex: 1;
    &:not(:last-child) {
        margin-right: 33px;
    }
    align-items: center;
    display: flex;
    flex-direction: column;
    ${p => p.showShadow && `box-shadow: 0px 20px 24px rgba(19, 24, 48, 0.04);`}
border-radius: 15px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: block;
      width: 100%;
      }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      padding: 57px 17px 34px 16px;
        min-width: unset;
        margin-right: 22px;
        margin-left: 22px;
        min-height: unset;
        display: unset;
        width: unset;
        &:not(:last-child) {
          margin-right: 22px;
      }
      }
`;


const PriceListingFullCard = styled.div`
position: relative;
    background: #FFFFFF;
    padding: 0 0 0 63px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    ${p => p.showShadow && `box-shadow: 0px 20px 24px rgba(19, 24, 48, 0.04);`}
  border-radius: 15px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      flex-direction: column;
      padding: 41px 0 0 0;
    height: 100%;
    max-width: 533px;
      img {
        width: 100% !important;
      }
      }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      padding: 57px 0 0 0;
              margin-right: 22px;
        margin-left: 22px;
      }


      img {
        width: 50%;
        max-width: 
        @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
          width: 100%;
          }
      }
`;

const PriceListingContent = styled.div`
.hs-form input[type=email] 
  {
    height: 50px;
    padding: 0px 171px 0 30px;
  }
  .hs-form input[type=submit] {
    height: 50px;
    padding: 12px 31px 12px 33px;
    font-weight: 600;
    font-size: 14px;
    line-height: 139.3%;
  }
@media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 0 18px 43px;
  }
`;

const PriceListingPointWrapper = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    max-width: 247px;
    img {
      margin-right: 15px;

      @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        margin-right: 30px;
      }
    }
`;


const PriceListingContainer = styled.div`
  overflow: hidden;
  padding: 19px 0 0;

  background: ${p => p.theme.palette.background.five};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {

    button {
      text-align: center;
    }
  }
`;

const PricingCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  margin: 0 auto;

  img {
    margin-right: 9px;
  }
`;

const ListingContainer = styled.div`
  width: 100%;
  max-height: 521px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  flex-direction: column;
  margin-top: ${p => p.marginTop};
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      flex-direction: column;
      max-height: unset;
    }
`;

const SeeAllFeature = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 139.3%;
  letter-spacing: -0.035em;
  text-align: center;
  color: #0A7EF9;
  border: none;
  background: transparent;
  display: block;
  margin: auto;
  margin-top: 18px;
  margin-bottom: 41px;
  cursor: pointer;
`;



const CalculatorField = styled.div`
  width: 100%;
  max-width: 360px;
  margin-bottom: ${p => p.marginBottom ? p.marginBottom : '41px'};
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    max-width:  unset;
  }
`;

const CalculatorFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  align-items: center;
`;

const CalculatorFieldFooter = styled.div`
    display: flex;
    align-items: center;
`;

const CalculatorFieldLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150.3%;
    /* identical to box height, or 24px */
    max-width: 253px;
    margin: 0;
    color: #FFFFFF;


  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
     max-width: 200px;
    }
`;

const CalculatorFieldValue = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100.3%;
    /* identical to box height, or 24px */
    margin: 0;
    max-width: 253px;
    color: #FFFFFF;

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      max-width: 153px;
     }
`;

const CalculatorFieldSlider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;

  background: #ffffff4D;
  border-radius: 10px;


  &::hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 21px;
    height: 21px;
    border-radius: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:active,&:focus,&:hover {
        background: #FFFFFFBF;
        border: 1px solid rgba(0, 0, 0, 0.13);
        box-sizing: border-box;
        box-shadow: 0px 0px 9px #FFFFFFBF;
    }
  }
  
  &::-moz-range-thumb {
    appearance: none;
    width: 21px;
    height: 21px;
    border-radius: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:active,&:focus,&:hover {
        background: #FFFFFFBF;
        border: 1px solid rgba(0, 0, 0, 0.13);
        box-sizing: border-box;
        box-shadow: 0px 0px 9px #FFFFFFBF;
    }
  }


`;


const RecommendTag = styled.div`
  background: #00C658;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 16px 20px 16px 29px;
  position: absolute;
  right: 45px;
  top: -19px;
  height: 46px;
  min-width: 157px;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    right:  unset;
    left: 50%;
    transform: translateX(-50%);
    height: 46px;
  }
`;


function numberWithCommas(number) {

  let decimals = 0
  let decpoint = '.'
  let thousand = ','

  let n = Math.abs(number).toFixed(decimals).split('.')
  n[0] = n[0].split('').reverse().map((c, i, a) =>
    i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
  ).reverse().join('')
  let final = (Math.sign(number) < 0 ? '-' : '') + n.join(decpoint)
  return final;
}

const Hero = props => {

  const [fullMidMarketList, setFullMidMarketList] = React.useState(false);
  const [fullGrowthList, setFullGrowthList] = React.useState(false);


  const [SalesTeamValue, setSalesTeamValue] = React.useState(0);
  const [MonthlyWebTraffic, setMonthlyWebTraffic] = React.useState(0);
  const [TechStackList, setTechStackList] = React.useState([]);

  const [isFormSubmittedGrowth, setIsFormSubmittedGrowth] = React.useState(false);

  const [recommendedPlan, setRecommendedPlan] = React.useState(pricingPlan.GROWTH);
  React.useEffect(() => {

    let defaultPlan = pricingPlan.GROWTH;
    console.log("TechStackList",TechStackList.map(tech => tech.value), premiumPricing.includes("HubSpot"))
    if (TechStackList.length > 0 && TechStackList.map(tech => tech.value).find(value => premiumPricing.includes(value))) {
      defaultPlan = pricingPlan.MID_MARKET;
    }
    if ((SalesTeamValue > 10 && SalesTeamValue <= 25) && (MonthlyWebTraffic > 50000 && MonthlyWebTraffic <= 500000)) {
      console.log("SalesTeamValue", SalesTeamValue, MonthlyWebTraffic);
      defaultPlan = pricingPlan.MID_MARKET;
    } else if (SalesTeamValue > 25 || MonthlyWebTraffic > 500000) {
      defaultPlan = pricingPlan.ENTERPRISE;
      console.log("EnterPRIC", SalesTeamValue, MonthlyWebTraffic);
    } else if (SalesTeamValue > 10 && SalesTeamValue <= 25) {
      console.log("SalesTeamValue", SalesTeamValue, MonthlyWebTraffic);
      defaultPlan = pricingPlan.MID_MARKET;
    } else if (MonthlyWebTraffic > 50000 && MonthlyWebTraffic <= 500000) {
      console.log("SalesTeamValue", SalesTeamValue, MonthlyWebTraffic);
      defaultPlan = pricingPlan.MID_MARKET;
    }

    setRecommendedPlan(defaultPlan);
  }, [SalesTeamValue, MonthlyWebTraffic,TechStackList]);

  const renderCalculatorField = ({ label, value, icon, min, max, step, formattedValue, setValue }) => {

    return (
      <CalculatorField>
        <CalculatorFieldHeader>
          <CalculatorFieldLabel>{label}</CalculatorFieldLabel>
          <CalculatorFieldValue>{formattedValue}</CalculatorFieldValue>
        </CalculatorFieldHeader>
        <CalculatorFieldFooter>
          <CalculatorFieldSlider type="range" min={min} max={max} step={step} value={value} onChange={(event) => setValue(event.target.value)} />
        </CalculatorFieldFooter>
      </CalculatorField>
    );
  };


  const renderCalculatorDropdown = ({ label, options, value, setValue }) => {



    return (
      <CalculatorField marginBottom={'0'}>
        <CalculatorFieldHeader>
          <CalculatorFieldLabel>{label}</CalculatorFieldLabel>
        </CalculatorFieldHeader>
        <CalculatorFieldFooter>
          <Select
            classNamePrefix="react-select"
            options={options}
            closeMenuOnSelect={false}
            isMulti
            onChange={(val) => {
              console.log("val",val)
              setValue(val)
            }}
            isClearable={false}
            value={value}
            styles={selectStyles}
          />
        </CalculatorFieldFooter>
      </CalculatorField>
    );
  }
  return (


    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
    >
      <>
        <HeroContainer>
          <Header />
          <HeroWrapper>
            <HeroContentWrapper>
              <div>
                <Text maxWidth="616px" fontSize="48px" smFontSize="23px" fontWeight={700} mdTextAlign="center" lineHeight="144%">
                  A Conversational Marketing Plan for Every Budget
                                  </Text>
                <Text fontSize="18px" smFontSize="13px" mdTextAlign="center" marginLeft="0" mdMarginLeft="auto" marginTop="25px" marginBottom="30px" smMarginBottom="30px" maxWidth="530px" opacity="0.8" lineHeight="170.3%">
                  Invest in your website with Insent’s comprehensive pricing plan that has something for every B2B revenue team.
                                  </Text>
                <CallToAction justify="left" mdJustify="center" formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a" />
              </div>
            </HeroContentWrapper>
            <HeroImageContainer>
              <img src={pricingHeroBg3x} />
              <PricingCalculatorContainer>
                {renderCalculatorField({
                  label: 'What is your monthly web traffic?',
                  formattedValue: MonthlyWebTraffic>1000000 ?  numberWithCommas(1000000) + '+' :  numberWithCommas(MonthlyWebTraffic),
                  setValue: setMonthlyWebTraffic,
                  value: MonthlyWebTraffic,
                  min: 0,
                  max: 10000001,
                  step: 50
                })}
                {renderCalculatorField({
                  label: 'How large is your sales team?',
                  formattedValue: numberWithCommas(SalesTeamValue) > 100 ? numberWithCommas(100) + '+' : numberWithCommas(SalesTeamValue),
                  setValue: setSalesTeamValue,
                  value: SalesTeamValue,
                  min: 0,
                  max: 101,
                  step: 1
                })}
                {renderCalculatorDropdown({
                  label: 'Which of the following tech does your marketing and sales team use?',
                  value: TechStackList,
                  setValue: setTechStackList,
                  options: techStacksList,
                })}
              </PricingCalculatorContainer>
            </HeroImageContainer>
          </HeroWrapper>


        </HeroContainer>
        <PriceListingContainer>

          <PriceListingFooter>
            <PriceListingCard showShadow={recommendedPlan === pricingPlan.GROWTH}>
              {recommendedPlan === pricingPlan.GROWTH && (
                <RecommendTag>
                  <Text color="#ffffff" fontSize="14PX" fontWeight={700}>Best Plan For You</Text>
                </RecommendTag>
              )}
              <PricingCardHeader>
                <Image hdSrc={growth} src={growth} height="19px" width="15px" />
                <Text maxWidth="246px" fontWeight={400} margin="0" textAlign="center" fontSize="24px" lineHeight="29PX" color="black" opacity="0.8" >
                  Growth
                        </Text>
              </PricingCardHeader>
              <Text marginBottom="23px" maxWidth="246px" fontWeight={400} textAlign="center" marginTop="16px" fontSize="14px" lineHeight="176%" color="black" opacity="0.6" >
                A flexible plan for B2B marketing and sales teams in growth mode.
                        </Text>
              <TabletDesktop>
                <AnimationInput 
                  portalId={5712917}
                  formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a"  
                  isFormSubmitted={isFormSubmittedGrowth}
                  setIsFormSubmitted={setIsFormSubmittedGrowth}
                  pageName=""
                  pageUri=""
                />
              </TabletDesktop>


              <TabletDesktop>
                <ListingContainer marginTop="78px">
                  {growthPricing.map(pricing => {
                    return (
                      <PriceListingPointWrapper key={pricing}>
                        <Image hdSrc={greenTick} src={greenTick} height="22px" width="22px" />
                        <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                          {pricing}
                        </Text>
                      </PriceListingPointWrapper>
                    )
                  })}
                </ListingContainer>
              </TabletDesktop>
              <Mobile>
                <ListingContainer marginTop="78px">
                  {midMarketPricing.map((pricing, pricingId) => {
                    if (pricingId > 4 && !fullGrowthList) {
                      return null;
                    }
                    return (
                      <PriceListingPointWrapper key={pricing}>
                        <Image hdSrc={greenTick} src={greenTick} height="22px" width="22px" />
                        <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                          {pricing}
                        </Text>
                      </PriceListingPointWrapper>
                    )
                  })}
                  {!fullGrowthList && (<SeeAllFeature onClick={() => setFullGrowthList(true)}>See all features</SeeAllFeature>)}
                  <AnimationInput 
                    portalId={5712917}
                    formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a"  
                    isFormSubmitted={isFormSubmittedGrowth}
                    setIsFormSubmitted={setIsFormSubmittedGrowth}
                    pageName=""
                    pageUri=""
                  />
                </ListingContainer>
              </Mobile>

            </PriceListingCard>
            <PriceListingCard showShadow={recommendedPlan === pricingPlan.MID_MARKET}>
              {recommendedPlan === pricingPlan.MID_MARKET && (
                <RecommendTag>
                  <Text color="#ffffff" fontSize="14PX" fontWeight={700}>Best Plan For You</Text>
                </RecommendTag>
              )}

              <PricingCardHeader>
                <Image hdSrc={midMarket} src={midMarket} height="21px" width="33px" />
                <Text maxWidth="246px" fontWeight={400} margin="0" textAlign="center" fontSize="24px" lineHeight="29PX" color="black" opacity="0.8" >
                  Mid-Market
                        </Text>
              </PricingCardHeader>
              <Text marginBottom="23px" maxWidth="246px" fontWeight={400} textAlign="center" marginTop="16px" fontSize="14px" lineHeight="176%" color="black" opacity="0.6" >
                A fully-loaded plan for mid-market B2B marketing, sales, and success teams.
                        </Text>
              <TabletDesktop>
              <AnimationInput 
                  portalId={5712917}
                  formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a"  
                  isFormSubmitted={isFormSubmittedGrowth}
                  setIsFormSubmitted={setIsFormSubmittedGrowth}
                  pageName=""
                  pageUri=""
                />
              </TabletDesktop>

              <Text color="#171B35" fontSize="14px" fontWeight={700} lineHeight="139%" textAlign="left" marginTop="68px">
                Everything in Growth plus:
                        </Text>
              <TabletDesktop>
                <ListingContainer marginTop="25px">
                  {midMarketPricing.map(pricing => {
                    return (
                      <PriceListingPointWrapper key={pricing}>
                        <Image hdSrc={greenTick} src={greenTick} height="22px" width="22px" />
                        <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                          {pricing}
                        </Text>
                      </PriceListingPointWrapper>
                    )
                  })}
                </ListingContainer>
              </TabletDesktop>
              <Mobile>
                <ListingContainer marginTop="25px">
                  {midMarketPricing.map((pricing, pricingId) => {
                    if (pricingId > 4 && !fullMidMarketList) {
                      return null;
                    }
                    return (
                      <PriceListingPointWrapper key={pricing}>
                        <Image hdSrc={greenTick} src={greenTick} height="22px" width="22px" />
                        <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                          {pricing}
                        </Text>
                      </PriceListingPointWrapper>
                    )
                  })}
                  {!fullMidMarketList && (<SeeAllFeature onClick={() => setFullMidMarketList(true)}>See all features</SeeAllFeature>)}
                  <AnimationInput 
                    portalId={5712917}
                    formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a"  
                    isFormSubmitted={isFormSubmittedGrowth}
                    setIsFormSubmitted={setIsFormSubmittedGrowth}
                    pageName=""
                    pageUri=""
                  />
                </ListingContainer>
              </Mobile>

            </PriceListingCard>

          </PriceListingFooter>
          <PriceListingFooter>
            <PriceListingFullCard showShadow={recommendedPlan === pricingPlan.ENTERPRISE}>
              {recommendedPlan === pricingPlan.ENTERPRISE && (
                <RecommendTag>
                  <Text color="#ffffff" fontSize="14PX" fontWeight={700}>Best Plan For You</Text>
                </RecommendTag>
              )}
              <PriceListingContent>
                <Text maxWidth="462px" fontSize="36px" smFontSize="23px" fontWeight={700} mdTextAlign="center" lineHeight="139%">
                  Looking for an Enterprise Plan?
                </Text>
                <Text fontSize="14px" smFontSize="13px" mdTextAlign="center" marginLeft="0" mdMarginLeft="auto" marginTop="12px" marginBottom="20px" smMarginBottom="30px" maxWidth="462px" opacity="0.6" lineHeight="150.3%" color="black" fontWeight={400}>
                  Get more information on our completely customizable package for B2B enterprise revenue organizations. It includes everything you see in Growth, and more.
                </Text>
                <CallToAction justify="left" mdJustify="center" formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a" height="50px"/>
              </PriceListingContent>
              <img src={entImg} />
            </PriceListingFullCard>

          </PriceListingFooter>
        </PriceListingContainer>
      </>
    </Waypoint>
  );
};

Hero.propTypes = {};

export default Hero;
