import React from 'react';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import { menuList } from './constants';

const UseCasesContainer = styled.div`
    padding: 34px 36px;
    margin: 19px 16px;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
    border-bottom: 6px solid #0A7EF9;
    min-height: 715px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${p => p.isFullWidth && `
        flex-direction: row;
        min-height: unset;
    `}
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        flex-direction: column;
        padding: 14px 12px;
      }
`;

const UseCaseTagsContainer = styled.div`
    display: flex;
`;

const UseCaseTags = styled.p`
    border-radius: 4px;
    margin: 0;
    margin-left: 9px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.6px;

    color: #0A7EF9;

`;


const  UseCaseImage = styled.div`
    justify-content: center;
    display: flex;
`;

const UseCasesContent = styled.div`

`;

const UseCaseTagWrapper = styled.div`
    display: flex;
    margin: 0 16px 16px 0;
    padding: 8px 5px 8px 9px;
    border-radius: 4px;
    align-items: center;
    background: #E7F2FE;

`;


const UseCases = ({title, desc, tags, webImage3x, isFullWidth}) => {


    return (
        <UseCasesContainer isFullWidth={isFullWidth}>
            <UseCasesContent>
                <UseCaseTagsContainer>
                    {tags.map((tag, tagId) => (
                        <UseCaseTagWrapper>
                            <img src={menuList.find(menu => {
                                return menu.text === tag.text
                            }).activeIcon} />
                            <UseCaseTags>
                                {tag.text}
                            </UseCaseTags>
                        </UseCaseTagWrapper>
                        
                    ))}
                </UseCaseTagsContainer>
                <Text fontSize="24px" fontWeight={700}  marginBottom="12px" marginTop="20px" position="inherit" letterSpacing="0.6px">
                    {title}
                </Text>
                <Text fontSize="16px" fontWeight={400}  color="#757575" maxWidth={isFullWidth && '545px'} marginLeft="0" position="inherit" letterSpacing="0.6px">
                    {desc}
                </Text>
            </UseCasesContent>
            <UseCaseImage>
                <Image src={webImage3x} hdSrc={webImage3x} height="100%" width="100%"  maxWidth="402px" maxHeight="395px" position="inherit" zIndex="#"/>
            </UseCaseImage>
        </UseCasesContainer>
    );
};


export default UseCases;