import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';


const ChatbotContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 0 100px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 36px 0;
  }
`;

const Chatbot = props => {


    return (
        <Element name="Why ZoomInfo Chat">
            <ChatbotContainer>
                <Waypoint
                    scrollableAncestor={window}
                    topOffset="10%"
                    bottomOffset="35%"
                >
                    <Container>
                        <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center" maxWidth="810px" marginBottom="61px">
                            Say no to the <span>chatbot</span> takeover
                        </Text>
                        <Text fontSize="18px" smFontSize="13px" maxWidth="800px" textAlign="center" marginTop="14px" marginBottom="37px" smMarginBottom="36px" >
                        We may have the best chatbot on the market, but it’s not here to mimic or replace real human interaction. Instead, our AI bot technology is built to help your team work more efficiently.
                        </Text>
                    </Container>
                </Waypoint>
            </ChatbotContainer>
        </Element>
    );
};

Chatbot.propTypes = {};

export default Chatbot;
