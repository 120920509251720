import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import hubspotHero from 'resources/img/hubspotHero.png';
import pricingAction3x from './img/pricingAction3x.png';
import pricingAction1x from './img/pricingAction1x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Button from 'ui/components/Button';


const ActionHeader = styled(MuiContainer)`
  background-color: white;

  max-width: unset !important;

`;

const ActionContainer = styled.div`
  overflow: hidden;
  padding: 0 ;

  background: ${p => p.theme.palette.background.five};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 0;

  }
`;


const ActionContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 513px;
  margin: 0 35px 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 423px;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 50px;
    min-height: unset;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 25px 81px 23px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    min-height: unset;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 25px 81px 23px;

  }
  .hs-form input[type=email] 
  {
    height: 50px;
    padding: 0px 171px 0 30px;
  }
  .hs-form input[type=submit] {
    height: 50px;
    padding: 12px 31px 12px 33px;
    font-weight: 600;
    font-size: 14px;
    line-height: 139.3%;
  }
`;

const ActionImageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;


const ActionFooter = styled.div`
  display: flex;
  
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }

  
`;

const ActionFooterLeft = styled.div`
    display: flex;
    justify-content: flex-end;
    background: #0A7EF9;
    width: 100%;
    padding: 41px 85px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      justify-content: center;
      padding: 41px;
    }
`;

const ActionFooterRight = styled.div`
    background: #65D0FB;
    width: 100%;
    padding: 41px 85px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: flex;
      padding: 41px;

      justify-content: center;
    }
`;

const Content = styled.div`
  max-width: 574px;
`;

const Action = props => {
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <ActionContainer>

                <ActionHeader>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <ActionContentWrapper>
                                  <Text marginBottom="37px" maxWidth="513px" fontWeight={700} marginRight="0" mdMarginLeft="auto" mdMarginTop="57px" fontSize="36px" smFontSize="24px" mdTextAlign="center" lineHeight="139.3%" >
                                    Ready to experience unrivalled support to implement chat on your website?
                                  </Text>
                                  <Text fontSize="14px"  mdTextAlign="center" marginLeft="0" mdMarginLeft="auto"  marginBottom="30px" smMarginBottom="30px" maxWidth="530px" opacity="0.8" lineHeight="170.3%" display="none" mdDisplay="block" >
                                  Get a personalized walkthrough of Insent’s platform and its integrations with your existing tools so you can accelerate deals, reduce your sales cycle, and win more customers.
                                  </Text>
                                  <CallToAction margin="0" justify="left" mdJustify="center" formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a" />
                            </ActionContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ActionImageContainer>
                            <Image height="auto" width="100%" maxWidth="722px" maxHeight="383px" margin="0 auto 0 0" mdMarginLeft="auto" mdPaddingTop="40px" mdPaddingBottom="0px" display="flex" hdSrc={pricingAction3x} src={pricingAction1x} />
                          </ActionImageContainer>
                          
                        </Grid>
                    </Grid>
                </ActionHeader>
                <Text fontSize="24px" lineHeight="170%" textAlign="center" smFontSize="18PX" maxWidth="790px" color="black" padding="87px 43px 50px">
                  Not sure which is the best fit for you? Speak to our Conversational Advisors today and we’ll find a plan that’s right for you. 
                </Text>
                <ActionFooter>
                    <ActionFooterLeft>
                        <Content>
                          <Text cursor="pointer" id="all-use-cases" onClick={() => { window.location.href = "https://us.insent.ai/use-cases" }} marginLeft="0" mdMarginLeft="auto" maxWidth="300px" color="white" fontSize="24px" smFontSize="18px" fontWeight={700} mdTextAlign="center" lineHeight="139.3%" marginBottom="27px" mdMarginBottom="19px">
                              All the Ways You Can Use Insent → 
                          </Text>
                          <Text cursor="pointer" id="all-use-cases" onClick={() => { window.location.href = "https://us.insent.ai/use-cases" }} marginLeft="0" mdMarginLeft="auto" maxWidth="464px" color="white" fontSize="18px" fontWeight={400} mdTextAlign="center" lineHeight="170.3%" smFontSize="12px">
                              Discover all the ways your entire revenue team can use Insent to power your demand generation, ABM, and sales acceleration strategy.
                          </Text>
                        </Content>
                    </ActionFooterLeft>
                    <ActionFooterRight>
                        <Content>
                        <Text cursor="pointer" marginLeft="0" mdMarginLeft="auto" maxWidth="300px" color="white" fontSize="24px" smFontSize="18px" fontWeight={700} mdTextAlign="center" lineHeight="139.3%" marginBottom="27px" mdMarginBottom="19px">
                            Talk to a Conversational Advisor → 
                        </Text>
                        <Text cursor="pointer" marginLeft="0" mdMarginLeft="auto" maxWidth="464px" color="white" fontSize="18px" fontWeight={400} mdTextAlign="center" lineHeight="170.3%" smFontSize="12px">
                            Connect with one of our conversational advisors who can recommend the appropriate conversational strategy for your GTM plan. 
                        </Text>
                        </Content>
                    </ActionFooterRight>
                </ActionFooter>
            </ActionContainer>
        </Waypoint>
    );
};

Action.propTypes = {};

export default Action;
