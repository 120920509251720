import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Image from 'ui/components/Image';

import styled from 'styled-components';
// import heroImage from 'resources/img/accountView.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';

import g2crowd from 'resources/img/g2crowd.png';
import g2crowd2x from 'resources/img/g2crowd2x.png';
import capterra from 'resources/img/capterra.png';
import crozdesk from 'resources/img/crozdesk.png';
import trustradius from 'resources/img/trustradius.png';

import star from 'resources/img/star.png';
import star2x from 'resources/img/star2x.png';

const ReviewContainer = styled.div`
    background: ${theme.palette.background.one};
    padding: 20px 0;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }

`;
const ReviewCard = styled.div`
    background: ${theme.palette.white};
    padding: 14px 20px;
    border-radius: 20px;
    box-shadow: 0px 10px 20px #0000000D;
    display: flex;
    max-width: 235px;
    min-width: 235px;
    margin: ${p => p.margin};

`;
const ReivewCardDetail = styled.div`
    margin-left: 15px;
`;

const ReviewRatingContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ReviewRatingStarsWrapper = styled.div`
    margin-right: 5px;
`;
const ReviewAppContainer = styled.div`
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: auto;
  min-width: 1130px;
  max-width: 1130px;
`;




const Review = props => {


    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            <ReviewContainer>

                <ReviewAppContainer>
                    <ReviewCard margin="0 0 0 30px">
                        <Image height="50px" width="50px" hdSrc={g2crowd2x} src={g2crowd} />
                        <ReivewCardDetail>
                            <ReviewRatingContainer>
                                <ReviewRatingStarsWrapper>
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                </ReviewRatingStarsWrapper>
                                <Text fontSize="26px" fontWeight={700} mdTextAlign="center" minWidth="35px">
                                    4.8
                                        </Text>
                            </ReviewRatingContainer>
                            <Text fontSize="12px" fontWeight={400}>
                                g2.com
                                    </Text>
                        </ReivewCardDetail>
                    </ReviewCard>
                    <ReviewCard margin="0 0 0 40px">
                        <Image height="50px" width="50px" hdSrc={capterra} src={capterra} />
                        <ReivewCardDetail>
                            <ReviewRatingContainer>
                                <ReviewRatingStarsWrapper>
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                </ReviewRatingStarsWrapper>
                                <Text fontSize="26px" fontWeight={700} mdTextAlign="center" minWidth="35px">
                                    5
                                        </Text>
                            </ReviewRatingContainer>
                            <Text fontSize="12px" fontWeight={400}>
                                capterra.com
                                    </Text>
                        </ReivewCardDetail>
                    </ReviewCard>
                    <ReviewCard margin="0 0 0 40px">
                        <Image height="50px" width="50px" hdSrc={trustradius} src={trustradius} />
                        <ReivewCardDetail>
                            <ReviewRatingContainer>
                                <ReviewRatingStarsWrapper>
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                </ReviewRatingStarsWrapper>
                                <Text fontSize="26px" fontWeight={700} mdTextAlign="center" minWidth="35px">
                                    9.3
                                        </Text>
                            </ReviewRatingContainer>
                            <Text fontSize="12px" fontWeight={400}>
                                trustradius.com
                                    </Text>
                        </ReivewCardDetail>
                    </ReviewCard>
                    <ReviewCard margin="0 40px">
                        <Image height="50px" width="50px" hdSrc={crozdesk} src={crozdesk} />
                        <ReivewCardDetail>
                            <ReviewRatingContainer>
                                <ReviewRatingStarsWrapper>
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                    <Image height="17px" width="17px" hdSrc={star2x} src={star} />
                                </ReviewRatingStarsWrapper>
                                <Text fontSize="26px" fontWeight={700} mdTextAlign="center" minWidth="35px">
                                    4.9
                                        </Text>
                            </ReviewRatingContainer>
                            <Text fontSize="12px" fontWeight={400}>
                                crozdesk.com
                                    </Text>
                        </ReivewCardDetail>
                    </ReviewCard>
                </ReviewAppContainer>
            </ReviewContainer>

        </Waypoint>
    );
};

Review.propTypes = {};

export default Review;
