import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
const Text = styled.p`
position: ${p => p.position ? p.position : 'relative'};
  font-size: ${p => p.fontSize};
  font-weight: ${p => p.fontWeight};
  font-style: normal;
  font-stretch: normal;
  line-height: ${p => p.lineHeight ? p.lineHeight : '1.2'};
  letter-spacing: ${p => p.letterSpacing};
  color: ${p => p.fontWeight > 400 ? 'black' : p.color ? p.color : theme.palette.text.two};
  color: ${p => p.color};
  margin: ${p => p.margin ? p.margin : '0 auto'};
  padding: ${p => p.padding};
  padding-top: ${p => p.paddingTop};
  padding-left: ${p => p.paddingLeft};
  padding-bottom: ${p => p.paddingBottom};
  padding-right: ${p => p.paddingRight};
  margin-top: ${p => p.marginTop};
  margin-left: ${p => p.marginLeft};
  margin-bottom: ${p => p.marginBottom};
  margin-right: ${p => p.marginRight};
  text-align: ${p => p.textAlign};
  max-width: ${p => p.maxWidth};
  display: ${p => p.display};
  cursor: ${p => p.cursor}
  span {
    color: #0a7ef9;
    font-weight: ${p => p.fontWeight};
  }
  opacity: ${p => p.opacity};

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    font-size: ${p => p.lgFontSize ? p.lgFontSize : p.fontSize};
    padding-top: ${p => p.lgPaddingTop};
    padding-left: ${p => p.lgPaddingLeft};
    padding-bottom: ${p => p.lgPaddingBottom};
    padding-right: ${p => p.lgPaddingRight};
    margin-top: ${p => p.lgMarginTop};
    margin-left: ${p => p.lgMarginLeft};
    margin-bottom: ${p => p.lgMarginBottom};
    margin-right: ${p => p.lgMarginRight};
    text-align: ${p => p.lgTextAlign};
    max-width: ${p => p.lgMaxWidth};
    display: ${p => p.lgDisplay};
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    font-size: ${p => p.mdFontSize ? p.mdFontSize : p.fontSize};
    padding-top: ${p => p.mdPaddingTop};
    padding-left: ${p => p.mdPaddingLeft};
    padding-bottom: ${p => p.mdPaddingBottom};
    padding-right: ${p => p.mdPaddingRight};
    margin-top: ${p => p.mdMarginTop};
    margin-left: ${p => p.mdMarginLeft};
    margin-bottom: ${p => p.mdMarginBottom};
    margin-right: ${p => p.mdMarginRight};
    text-align: ${p => p.mdTextAlign};
    max-width: ${p => p.mdMaxWidth};
    display: ${p => p.mdDisplay};
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: ${p => p.smFontSize ? p.smFontSize : p.fontSize};
    padding-top: ${p => p.smPaddingTop};
    padding-left: ${p => p.smPaddingLeft};
    padding-bottom: ${p => p.smPaddingBottom};
    padding-right: ${p => p.smPaddingRight};
    margin-top: ${p => p.smMarginTop};
    margin-left: ${p => p.smMarginLeft};
    margin-bottom: ${p => p.smMarginBottom};
    margin-right: ${p => p.smMarginRight};
    text-align: ${p => p.smTextAlign};
    max-width: ${p => p.smMaxWidth};
    display: ${p => p.smDisplay};
  }

`;

export default (props) => {
    return <Text {...props}>{props.children}</Text>
}