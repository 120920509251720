import React from 'react';
import { Container as MuiContainer, Grid as MuiGrid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import marketoProcess1 from 'resources/img/marketoProcess1.png';
import marketoProcess2 from 'resources/img/marketoProcess2.png';
import marketoProcess3 from 'resources/img/marketoProcess3.png';
import marketoProcess4 from 'resources/img/marketoProcess4.png';
import purpleChatPopup from 'resources/img/purpleChatPopup.png';
import roseChatPopup from 'resources/img/roseChatPopup.png';
import roseChatPopup2x from 'resources/img/roseChatPopup2x.png';
import { TabletDesktop, Mobile } from "ui/components/Media";
import theme from 'styles/theme';

import flash from 'resources/img/flash.png';
import flash2x from 'resources/img/flash2x.png';
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const Grid = styled(MuiGrid)`
        justify-content: center;
`;

const ProcessContainer = styled(animated.div)`
    padding: 1rem;
    text-align: center;
    vertical-align:middle;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      margin-top: 1rem;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    } 
`;

const SectionHeader = styled.h1`

  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  margin: 0 auto;
  padding-bottom: 10px;
  max-width: 700px;
  text-align: center;

  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
    margin-bottom: 2rem;
  }
`;




const ProcessWrapper = styled.div`
  overflow: hidden;
  padding: 30px 0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {

    button {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {

    button {
      text-align: center;
    }
  }
`;

const ProcessContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
  &&& {
    ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
    ${p => p.paddingLeft && `padding-left:${p.paddingLeft}`}
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
      &&& {
        padding: 0
      }
    }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    &&& {
      padding: 0
    }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    &&& {
      padding: 0;
    }

    div > div {
      justify-content: left;
    }
  }
`;

const ProcessHeader = styled.h2`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 24px;
  text-align: left;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
    max-width: unset;
    padding-top: 1rem;
  }
`;

const ProcessDesc = styled.h2`
    max-width: 500px;
    font-family: ProductSans;
    font-size: 18px;
    line-height: 1.22;
    color: #757575;
    font-weight: normal;
    text-align: left;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
  }
`;

const ProcessAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 510px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    max-width: calc(414px - 2rem);
    width: 100%;
  }
`;

const ColorText = styled.span`
  &&& {
      color: ${p => p.color}
  }
`;


const ProcessBonus = styled.div`
  padding: 20px;
  background: ${p => p.theme.palette.blue.one};
  display: flex;
  align-items: center;
  margin-top:20px;
  border-radius: 20px;
  max-width: 500px; 
`;

const RevenueDetail = styled.div`
    margin-left: 20px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        margin-left: 0px;
    } 
`;


const RevenueCard = styled.div`
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    width: 50%;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
            width: 100%;
            margin-top: 30px
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
            width: 100%;
    } 
`;


const ProcessHeaderSection = styled.div`
    padding: 50px 1rem 28px;
    
    text-align: center;    
    vertical-align:middle;
    background: ${p => p.theme.palette.blue.one};

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        padding: 60px 36px;
    }

    
`;

const ProcessHeaderWrapper = styled.div`
    max-width: 1148px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        flex-direction: column;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        flex-direction: column;
    } 

`;


const ProcessHeaderImage = styled.div`
    width: 50%;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        width: 100%;
    } 

`;


const Process = props => {


    const HeaderTableDesktopView = props => (
        <TabletDesktop>
            <ProcessHeaderSection>
                <ProcessHeaderWrapper>
                    <RevenueCard>
                        <Image height="33px" width="16px" hdSrc={flash2x} src={flash} margin="8px 0 0" />
                        <RevenueDetail>
                            <Text fontSize="40px" fontWeight={700} textAlign="left">
                                Make that <span>marketing-to-sales handoff</span> in real-time.
                            </Text>
                            <Text fontSize="18px" fontWeight={400} textAlign="left" marginBottom="20px" marginTop="20px" color={theme.palette.text.four}>
                                Now sales can initiate a chat with them while they’re on your site to accelerate sales conversations.
                            </Text>
                        </RevenueDetail>
                    </RevenueCard>
                    <ProcessHeaderImage>
                        <Image hdSrc={roseChatPopup2x} src={roseChatPopup} width="100%" height="100%" maxWidth="380px" smMaxWidth="231px"/>
                    </ProcessHeaderImage>
                </ProcessHeaderWrapper>
            </ProcessHeaderSection>
        </TabletDesktop>

    );

    const HeaderMobileView = props => (
        <Mobile>
            <ProcessHeaderSection>
                <ProcessHeaderWrapper>
                    <ProcessHeaderImage>
                        <Image hdSrc={roseChatPopup2x} src={roseChatPopup} width="100%" height="100%" maxWidth="380px" smMaxWidth="231px"/>
                    </ProcessHeaderImage>
                    <RevenueCard>
                        <Image height="33px" width="16px" hdSrc={flash2x} src={flash} margin="8px 0 0" smDisplay="none" />
                        <RevenueDetail>
                            <Text fontSize="40px" smFontSize="30px"  fontWeight={700} textAlign="left">
                                Make that <span>marketing-to-sales handoff</span> in real-time.
                            </Text>
                            <Text fontSize="18px" fontWeight={400} textAlign="left" marginTop="20px" color={theme.palette.text.four}>
                                Now sales can initiate a chat with them while they’re on your site to accelerate sales conversations.
                            </Text>
                        </RevenueDetail>
                    </RevenueCard>

                </ProcessHeaderWrapper>
            </ProcessHeaderSection>
        </Mobile>

    );

    const ProcessTabletDesktopView = props => {
        return (
            <TabletDesktop>
                <ProcessWrapper>
                    <Container>
                        <Grid container spacing={4}>

                            <Grid lg={6}>
                                <ProcessAnimatedImg src={marketoProcess1} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Build your own audience
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            You know your audience more than anybody, and when they’re actually sales-ready.
                                                <br />
                                            <br />
                                                Insent enables you to easily build your own custom audiences based on website behavior, firmographic data, and IP resolution. So when a really hot prospect from a target account list meets that criteria—you get notified!
                                            </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Get notified about who’s engaged and online
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Insent will immediately identify your Marketo leads the moment they land on your website. Once the visitor is known and deemed to be significantly engaged (based on your rules), they will get routed to the appropriate sales rep.
                                    </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={marketoProcess2} alt="Process" />
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container spacing={4}>

                            <Grid lg={6}>
                                <Image hdSrc={marketoProcess3} src={marketoProcess3} alt="Process" maxWidth="510px" width="100%" marginTop="4rem" mdMarginTop="0" height="unset" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Send actual buyers over to sales
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            You know your audience more than anybody, and when they’re actually sales-ready.
                                                <br />
                                            <br />
                                            Insent will not alert sales with leads that aren’t qualified or engaged. Instead, you can focus on handing over actual buyers that have engaged the most with your marketing activity. Whether that is from a Marketo nurture email or webinar program, your sales reps will be notified to chat within seconds of an engagement threshold being met—or better yet, a human is requested.
                                            </ProcessDesc>
                                        <ProcessBonus>
                                            <Image height="44px" width="56px" hdSrc={purpleChatPopup} src={purpleChatPopup} margin="0 20px 0 0" />

                                            <Text textAlign="left" fontSize="18px" color="#191919" display="inline">
                                                <Text color="#191919" fontWeight={700} display="inline-block">Bonus:</Text>  Prospects who chat are 82% more likely to convert than non-chatters.
                                            </Text>
                                        </ProcessBonus>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Full engagement view alongside chat
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            83% of marketers say creating personalized content is their biggest challenge, so here’s how you can enable your sales team to personalize one-on-one conversations for engaged buyers. Access engagement data and marketo-sales insights into one complete account view alongside Insent.ai’s chat platform. No more switching between screens to gather intel on your prospects.
                                    </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={marketoProcess4} alt="Process" />
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
            </TabletDesktop>
        );
    };

    const ProcessMobileView = props => {
        return (
            <Mobile>
                <ProcessWrapper>
                    <Container>
                        <Grid container spacing={4}>

                            <Grid lg={6}>
                                <ProcessAnimatedImg src={marketoProcess1} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Build your own audience
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            You know your audience more than anybody, and when they’re actually sales-ready.
                                                <br />
                                            <br />
                                                Insent enables you to easily build your own custom audiences based on website behavior, firmographic data, and IP resolution. So when a really hot prospect from a target account list meets that criteria—you get notified!
                                            </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={marketoProcess2} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Get notified about who’s engaged and online
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Insent will immediately identify your Marketo leads the moment they land on your website. Once the visitor is known and deemed to be significantly engaged (based on your rules), they will get routed to the appropriate sales rep.
                                    </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={marketoProcess3} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Send actual buyers over to sales
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            Insent will not alert sales with leads that aren’t qualified or engaged. Instead, you can focus on handing over actual buyers that have engaged the most with your marketing activity. Whether that is from a Marketo nurture email or webinar program, your sales reps will be notified to chat within seconds of an engagement threshold being met—or better yet, a human is requested.
                                            </ProcessDesc>
                                        <ProcessBonus>
                                            <Image height="44px" width="56px" hdSrc={purpleChatPopup} src={purpleChatPopup} margin="0 20px 0 0" />

                                            <Text textAlign="left" fontSize="18px" color="#191919" display="inline">
                                                <Text color="#191919" fontWeight={700} display="inline-block">Bonus:</Text>  Prospects who chat are 82% more likely to convert than non-chatters.
                                            </Text>
                                        </ProcessBonus>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>

                        </Grid>
                    </Container>
                </ProcessWrapper>
                <ProcessWrapper>
                    <Container>
                        <Grid container>
                            <Grid lg={6}>
                                <ProcessAnimatedImg src={marketoProcess4} alt="Process" />
                            </Grid>
                            <Grid lg={6}>
                                <ProcessContentWrapper paddingLeft="3rem" >
                                    <div>
                                        <ProcessHeader>
                                            Full engagement view alongside chat
                                            </ProcessHeader>
                                        <ProcessDesc>
                                            83% of marketers say creating personalized content is their biggest challenge, so here’s how you can enable your sales team to personalize one-on-one conversations for engaged buyers. Access engagement data and marketo-sales insights into one complete account view alongside Insent.ai’s chat platform. No more switching between screens to gather intel on your prospects.
                                    </ProcessDesc>
                                    </div>
                                </ProcessContentWrapper>
                            </Grid>
                        </Grid>
                    </Container>
                </ProcessWrapper>
            </Mobile>
        );
    };

    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            <>
                <HeaderTableDesktopView />
                <HeaderMobileView />
                <ProcessContainer>

                    <ProcessTabletDesktopView />
                    <ProcessMobileView />
                    <Text fontSize="40px" fontWeight={700} textAlign="center" mdMarginTop="60px" smMarginTop="70px" marginBottom="12px">
                        <span>Results</span>
                    </Text>
                    <Text fontSize="22px" color="#191919" textAlign="center" marginBottom="110px" smMarginBottom="80px">
                        Create new leads, trigger new marketing workflows and influence more pipeline—all in <ColorText color={theme.palette.purple.one}>Marketo.</ColorText>
                    </Text>
                </ProcessContainer>
            </>
        </Waypoint>
    );
};

Process.propTypes = {};

export default Process;