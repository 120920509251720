import React from 'react';
import MediaQuery from 'react-responsive';
import theme from 'styles/theme';

const Desktop = ({ children }) => {
  return <MediaQuery minWidth={theme.breakpoints.md}>{children}</MediaQuery>;
};

const TabletDesktop = ({ children }) => {
  return <MediaQuery minWidth={theme.breakpoints.sm}>{children}</MediaQuery>;
};

const Tablet = ({ children }) => {
  return (
    <MediaQuery minWidth={theme.breakpoints.sm} maxWidth={theme.breakpoints.md}>
      {children}
    </MediaQuery>
  );
};
const Mobile = ({ children }) => {
  return <MediaQuery maxWidth={theme.breakpoints.sm}>{children}</MediaQuery>;
};

const MobileTablet  = ({ children }) => {
  return <MediaQuery maxWidth={theme.breakpoints.md}>{children}</MediaQuery>;
};

export { Desktop, TabletDesktop, Tablet, Mobile, MobileTablet };
