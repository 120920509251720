import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import request from "utils/request.js";
import * as microsoftTeams from "@microsoft/teams-js";

const PageContainer = styled.div`
  height: 100vh;
  background: #f2f6ff;
  border-radius: 4px;
  border-top: 4px solid #0061ff;
  border-bottom: 4px solid #0061ff;
  > div {
    max-width: 450px;
    margin: 0 auto;
  }
`;

const PageTitle = styled.h1`
  font-size: 20px;
  line-height: 23px;
  color: #404040;
  margin: 42px 0 28px;
  font-family: "Sharp Sans";
  font-weight: 600;
`;

const PageText = styled.p`
  font-family: "Sharp Sans";
  font-size: 16px;
  line-height: 22px;
  color: #404040;
  margin: 0;
  font-weight: 500;
  a {
    color: #417dff;
    text-decoration: none;
  }
`;

const Input = styled.input`
  width: 100%;
  font-size: 12px;
  background: #ffffff;
  border: 1px solid #dbdee3;
  border-radius: 6px;
  padding: 10px 13px;
  ::placeholder {
    font-family: "Sharp Sans";
    color: #929dab;
    font-size: 12px;
    line-height: 20px;
  }
`;

const Submit = styled.button`
  font-family: "Sharp Sans";
  color: #ffffff;
  background: #0061ff;
  border-radius: 20px;
  padding: 4px 16px;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  margin-left: 12px;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 0 44px;
`;

export const MSTeamsConfig = () => {
  const [open, setOpen] = React.useState(false);
  const [verified, setVerified] = React.useState(false);

  const intervalId = setInterval(function () {
    let cookiePopup = document.getElementById("hs-eu-cookie-confirmation");
    if (cookiePopup) {
      cookiePopup.remove();
      if (intervalId) clearInterval(intervalId);
    }
  }, 100);

  const handleSubmit = async (value) => {
    try {
      const { company } = value;
      const response = await request({
        method: "get",
        endpoint: "health",
        config: {},
        companyName: company,
      })
        .then((data) => {
          const websiteUrl =
            company === "sandbox"
              ? `https://${company}.insent.ai`
              : `https://${company}.insent.ai/teams-success`;
          const contentUrl =
            company === "sandbox"
              ? `https://${company}.insent.ai/login?redirectUrl=%2Fteams-success`
              : `https://${company}.insent.ai/teams-success`;
          microsoftTeams.settings.setValidityState(true);
          microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            microsoftTeams.settings.setSettings({
              websiteUrl,
              contentUrl,
              entityId: "insentLogin",
            });
            saveEvent.notifySuccess();
          });
          setVerified(true);
        })
        .catch((e) => {
          console.log("err", e);
          setOpen(!open);
        });
    } catch {
      setOpen(!open);
    }
  };

  return (
    <PageContainer>
      <div>
        <PageTitle>Welcome to Zoominfo Chat</PageTitle>
        {microsoftTeams.initialize()}
        <PageText>
          Level up your website chat experience with a solution that engages
          visitors based on who they are.
        </PageText>
        <br />
        <PageText>Enter your company name to sign in.</PageText>
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            if (values) {
              handleSubmit(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormWrapper>
                <Input
                  width="100%"
                  type="text"
                  name="company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company}
                  placeholder="eg. zoominfo"
                  required
                />
                {!verified && <Submit>Continue</Submit>}
              </FormWrapper>
            </form>
          )}
        </Formik>
        <PageText>
          Note: If you don’t have a Zoominfo Chat account, reach out to our
          sales team at{" "}
          <a href="https://www.zoominfo.com/chat" target="_blank">
            zoominfo.com/chat
          </a>
        </PageText>
      </div>
    </PageContainer>
  );
};

export default MSTeamsConfig;
