import React, { useEffect, useState } from 'react';

import styled from 'styled-components';



const FormSubmitElement = styled.p`
  text-align: center;
  color: green;
`;

const OverlayEmailField = styled.form`
  position: relative;
  height: 37px;
`;

const OverlayEmailInput = styled.input`
min-width: 300px;
border: 1px solid red;
    color: rgba(33, 36, 44, 0.54);
    padding: 0px 30px;
    border-radius: 24px;
    border-width: 0px;
    border-color: #e9ebf4;
    height: 37px;
    background-color: ${p => p.bgColor ? p.bgColor : '#e9ebf4'};
    width: 100%;
    min-width: 400px;
    outline: 0;
    font-size: 15px; 


  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    min-width: 300px;
  }
    
    &::placeholder {
      color: rgba(33, 36, 44, 0.54);
    }

`;

const OverlayEmailSubmit = styled.button`
position: absolute;
top:0;
right: 0;
z-index: 1;
height: 37px;
font-size: 12px;
font-weight: bold;
background-color: #0a7ef9;
color: white;
will-change: transform;
border-radius: 24px;
padding: 0px 20px;
border-color: #0a7ef9;
border-width: 0px;
transition: transform 0.1s ease-in-out 0s;
margin-bottom: -90px;
outline: 0;
-webkit-appearance: button;
cursor: pointer; 
`;


const HubspotFormReact = (props) => {

    const { 
        isFormSubmitted, 
        setIsFormSubmitted, 
        roiInputFields, 
        roiOutputMinFields,
        roiOutputAntFields,
        inputBgColor, 
        portalId, 
        roiInputFieldsFormId, 
        roiOutputMinFieldsFormId,
        roiOutputAntFieldsFormId,
        pageUri, 
        pageName } = props;
    const [email, setEmail] = useState('');
    const [thankYou, showThankYou] = useState(false);
    const [IP, setIP] = useState('');
    let [inputDataFields, setInputDataFields] = useState([]);
    let [outputMinDataFields, setOutputMinDataFields] = useState([]);
    let [outputAntDataFields, setOutputAntDataFields] = useState([]);

    // Get IP address from client for Hubspot analytics
    async function fetchIP() {
        const res = await fetch('https://ip.nf/me.json');
        res.json().then((res) => setIP(res.ip.ip)).catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchIP();
        if (roiInputFields) {
            const customFields = roiInputFields.map((field) => {
                return {
                    name: field.name,
                    value: field.value
                };
            });
            setInputDataFields([...customFields]);
        }
        if (roiOutputMinFields) {
            const customFields = roiOutputMinFields.map((field) => {
                return {
                    name: field.name,
                    value: field.value
                };
            });
            setOutputMinDataFields([...customFields]);

        }
        if (roiOutputAntFields) {
            const customFields = roiOutputAntFields.map((field) => {
                return {
                    name: field.name,
                    value: field.value
                };
            });
            setOutputAntDataFields([...customFields]);

        }
    }, [roiOutputAntFields,roiOutputMinFields,roiInputFields]);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };


    const submitToHubspot = (portalId, formId, dataFields) => {
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

        // hsCookie gets the data necessary to track Hubspot analytics
        const hsCookie = document.cookie.split(';').reduce((cookies, cookie) => {
            const [name, value] = cookie.split('=').map((c) => c.trim());
            cookies[name] = value;
            return cookies;
        }, {});

        const data = {
            fields: dataFields,
            context: {
                hutk: hsCookie.hubspotutk,
                pageUri: `${pageUri}`,
                pageName: `${pageName}`,
                ipAddress: `${IP}`
            }
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((response) => console.log('success', response))
            .catch((error) => console.log('error: ', error));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        submitToHubspot(portalId, roiInputFieldsFormId, [{ name: 'email', value: `${email}` },...inputDataFields]);
        submitToHubspot(portalId,roiOutputMinFieldsFormId,[{ name: 'email', value: `${email}` },...outputMinDataFields]);
        submitToHubspot(portalId,roiOutputAntFieldsFormId,[{ name: 'email', value: `${email}` },...outputAntDataFields]);

        setEmail('');
        setIsFormSubmitted(true);
    };

    return (
        <>
      {isFormSubmitted ? (
        <FormSubmitElement>Thank you for submitting.</FormSubmitElement>
      ) : (
        <OverlayEmailField
          id="ae6cdbfe-1019-4807-b1d5-fa27bbee9d17"
          onSubmit={handleSubmit}
        >
          <OverlayEmailInput
            className="hs-input"
            type="email"
            name="email"
            placeholder="Enter your email"
            bgColor={inputBgColor}
            required
            value={email}
            onChange={handleChange}
          />
          <OverlayEmailSubmit type="submit">
            Get Full Results
          </OverlayEmailSubmit>
        </OverlayEmailField>
      )}
    </>        
    );
};
export default HubspotFormReact;
