import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import WhyConvoMarketingImageOne from 'resources/img/sun.png';
import WhyConvoMarketingImageTwo from 'resources/img/popup.png';
import botTakeover from 'resources/img/botTakeover.png';
import Carousel from 'nuka-carousel';

import Back from 'resources/img/back';
import Next from 'resources/img/next';
const GridCenter = styled(Grid)`
  justify-content: center;
`;

const ScheduleContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.md - 1}px) {
    display: block;
  }
`;

const NavButtonWrapper = styled.div`
  margin: -40px 4rem;
  display: flex;
  position: absolute;
  top: 5rem;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    margin: 0;
    top: 4rem;
  }
`;

const NavButton = styled.div`
  cursor: pointer;
  padding-right: 0.5rem;
  transition: transform 0.1s ease-in-out;
  will-change: transform;

  &:hover {
    transform: scale(1.2);
  }
`;

const ScheduleContainer = styled.div`
  padding: 24px 0 0;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.md - 1}px) {
    display: none;
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
`;

const SectionHeader = styled.h1`
  font-size: ${(p) => p.fontSize};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  margin: 0 auto;
  padding-bottom: 12px;
  max-width: 750px;
  text-align: center;
  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
  }
`;

const ScheduleContentWrapper = styled(animated.div)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 30px #0000000d;
  border-radius: 30px;
  opacity: 1;
  padding: 30px;
  max-width: 440px;
  min-height: 535px;
  margin: 1rem auto 0;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.md}px) {
    margin-right: 1rem;
  }
`;

const ScheduleDesc = styled.h2`
  max-width: 421px;
  font-family: ProductSans;
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  font-weight: normal;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.md - 1}px) {
    text-align: left;
  }
`;

const ScheduleAnimatedImgOne = styled(animated.img)`
  width: 100%;
  max-width: 181px;
  margin: 20px auto 50px;
  height: 99px;
  display: block;
`;

const ScheduleAnimatedImgTwo = styled(animated.img)`
  width: 100%;
  max-width: 182px;
  margin: 30px auto 60px;
  height: 79px;
  display: block;
`;

const WhyConvoMarketingContainer = styled(animated.div)`
  margin: 5rem 5rem 0;
  vertical-align: middle;
  background: ${(p) => p.theme.palette.blue.one};
  padding: 100px 2rem 7rem;
  overflow: hidden;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.md - 1}px) {
    margin: 2rem 2rem 0;
  }
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    margin: 1rem 1rem 0;
    /* padding: 50px 1rem; */
  }
`;

const SectionDescription = styled.p`
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  max-width: 675px;
  text-align: center;
  padding-bottom: 36px;
  margin: auto auto 0;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;

const SectionImage = styled(animated.img)`
  max-width: 541px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  height: 254px;
  width: 350px;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    background-size: cover;
    padding: 2rem 0;
    width: calc(414px - 2rem);
    height: 266px;
  }
`;

const BotTakeoverContainer = styled.div`
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 940px;
  margin: auto;
  padding: 45px 2rem;

  ${SectionHeader}, ${SectionDescription} {
    text-align: left;
  }
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;

    ${SectionHeader}, ${SectionDescription} {
      text-align: center;
    }
  }
`;

const BotTakeoverContent = styled.div`
  opacity: 1;
  max-width: 410px;
`;

const WhyConvoMarketing = (props) => {
  const [slidesToShow, setslidesToShow] = React.useState(1);
  const [isContentVisible, setContentIsVisible] = React.useState(false);

  const cardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
  });
  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });

  const carouselSettings = {
    slidesToShow,
    slidesToScroll: 1,
    cellAlign: 'center',
    cellSpacing: 20,
    slideWidth: 1,
  };

  const renderNavButtons = (carouselProp) => {
    return (
      <NavButtonWrapper>
        <NavButton onClick={carouselProp.previousSlide}>
          <Back height="30" width="30" />
        </NavButton>
        <NavButton onClick={carouselProp.nextSlide}>
          <Next height="30" width="30" />
        </NavButton>
      </NavButtonWrapper>
    );
  };

  return (
    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
      onEnter={() => setContentIsVisible(true)}
      onLeave={() => setContentIsVisible(false)}
    >
      <div>
        <BotTakeoverContainer>
          <SectionImage src={botTakeover} />
          <BotTakeoverContent>
            <SectionHeader fontSize="40px">
              Say no to the <span>bot</span> takeover.
            </SectionHeader>
            <SectionDescription>
              Chatbots were meant to be your second line of defense, supporting
              marketing and sales when your team isn’t available—not pretending
              to be human.{' '}
            </SectionDescription>
          </BotTakeoverContent>
        </BotTakeoverContainer>
        <WhyConvoMarketingContainer style={cardStyleProps}>
          <SectionHeader fontSize="40px">
            What is <span>Conversational</span> Marketing?
          </SectionHeader>
          <SectionDescription>
            The power of leveraging one-to-one engagement between you, and your
            buyer, in the form of real-time conversations.
          </SectionDescription>

          <ScheduleContainer>
            <Container>
              <GridCenter container>
                <Grid item md={6}>
                  <ScheduleContentWrapper style={leftCardStyleProps}>
                    <ScheduleAnimatedImgOne
                      style={leftCardStyleProps}
                      src={WhyConvoMarketingImageOne}
                      alt="schedule"
                    />
                    <SectionHeader fontSize="22px">
                      The problems
                      <br />
                      It's trying to solve
                    </SectionHeader>
                    <ScheduleDesc>
                      The biggest challenge for B2B marketers is that you’re
                      spending a lot of time and money bringing qualified leads
                      to your website, but the conversion rates are getting
                      lower and lower.
                      <br />
                      <br />
                      Your lead acquisition cost is getting higher, meanwhile,
                      you aren’t connecting with your most engaged leads—while
                      they’re on your website
                    </ScheduleDesc>
                  </ScheduleContentWrapper>
                </Grid>
                <Grid item md={6}>
                  <ScheduleContentWrapper style={rightCardStyleProps}>
                    <ScheduleAnimatedImgTwo
                      style={rightCardStyleProps}
                      src={WhyConvoMarketingImageTwo}
                      alt="schedule"
                    />
                    <SectionHeader fontSize="22px">
                      The Evolution of <br />
                      Conversational Language
                    </SectionHeader>
                    <ScheduleDesc>
                      Over the past decade, we’ve witnessed an evolution in how
                      B2B marketers write and sell. Yesteryear’s corporate
                      language and tech jargon is a thing of the past. The
                      digital customer experience feels less like a stuffy
                      conference and more like talking to a friend.
                      <br />
                      <br />
                      We’ve stopped talking like robots, but why are we now
                      acting like them?
                    </ScheduleDesc>
                  </ScheduleContentWrapper>
                </Grid>
              </GridCenter>
            </Container>
          </ScheduleContainer>
          <ScheduleContainerMobile>
            <Carousel
              renderCenterLeftControls={(props) => null}
              renderCenterRightControls={(props) => null}
              renderBottomCenterControls={(props) => null}
              renderBottomLeftControls={(props) => renderNavButtons(props)}
              {...carouselSettings}
            >
              <ScheduleContentWrapper style={leftCardStyleProps}>
                <ScheduleAnimatedImgOne
                  style={leftCardStyleProps}
                  src={WhyConvoMarketingImageOne}
                  alt="schedule"
                />
                <SectionHeader fontSize="22px">
                  The problems
                  <br />
                  It's trying to solve
                </SectionHeader>
                <ScheduleDesc>
                  The biggest challenge for B2B marketers is that you’re
                  spending a lot of time and money bringing qualified leads to
                  your website, but the conversion rates are getting lower and
                  lower.
                  <br />
                  <br />
                  Your lead acquisition cost is getting higher, meanwhile, you
                  aren’t connecting with your most engaged leads—while they’re
                  on your website
                </ScheduleDesc>
              </ScheduleContentWrapper>
              <ScheduleContentWrapper style={rightCardStyleProps}>
                <ScheduleAnimatedImgTwo
                  style={rightCardStyleProps}
                  src={WhyConvoMarketingImageTwo}
                  alt="schedule"
                />
                <SectionHeader fontSize="22px">
                  The Evolution of <br />
                  Conversational Language
                </SectionHeader>
                <ScheduleDesc>
                  Over the past decade, we’ve witnessed an evolution in how B2B
                  marketers write and sell. Yesteryear’s corporate language and
                  tech jargon is a thing of the past. The digital customer
                  experience feels less like a stuffy conference and more like
                  talking to a friend.
                  <br />
                  <br />
                  We’ve stopped talking like robots, but why are we now acting
                  like them?
                </ScheduleDesc>
              </ScheduleContentWrapper>
            </Carousel>
          </ScheduleContainerMobile>
        </WhyConvoMarketingContainer>
      </div>
    </Waypoint>
  );
};

WhyConvoMarketing.propTypes = {};

export default WhyConvoMarketing;
