import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { transparentize } from 'polished';
import Image from 'ui/components/Image';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import marketoPlug2x from 'resources/img/marketoPlug2x.png';
import marketoPlugHeader2x from 'resources/img/marketoPlugHeader2x.png';

const PlugContainer = styled.div`
  overflow: hidden;
  padding: 70px 0 110px;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 60px 0;
    button {
      text-align: center;
    }
  }
`;

const PlugWrppaer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0 20px;
  &&& {
    max-width: 1120px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const PlugContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
  &&& {
    ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
    ${p => p.paddingLeft && `padding-left:${p.paddingLeft}`}
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
      &&& {
        padding: 0
      }
    }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    &&& {
      padding: 0
    }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    &&& {
      padding: 0;
    }

    div > div {
      justify-content: left;
    }
  }
`;

const ColorText = styled.span`
  &&& {
      color: ${p => p.color}
  }
`;

const PlugAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 624px;
`;

const PlugHeader = styled.img`
  display: block;
  max-width: 385px;
  max-height: 47px;
  margin: auto;
  margin-bottom: 60px; 

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    max-width: 300px;
    max-height: 38px;
  }
`;

const Plug = props => {
    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            <PlugContainer>
                <PlugHeader src={marketoPlugHeader2x} />
                <PlugWrppaer>
                    <PlugAnimatedImg src={marketoPlug2x} />
                    <PlugContentWrapper paddingLeft="3rem" >
                        <div>
                            <Text fontSize="30px" fontWeight={700} textAlign="left" smMarginTop="60px" maxWidth="312px" marginBottom="30px">
                                <ColorText color={theme.palette.purple.one}>Sales conversations are 391% higher in the first minute.</ColorText>
                            </Text>
                            <Text fontSize="14px" fontWeight={400} textAlign="left" mdMarginTop="30px" smMarginTop="30px" marginBottom="12px" color="#757575" maxWidth="312px">
                                Instead of web forms and follow-ups, connect your hottest leads on site to your sales team instantly—and do so based on the rules you set up in Marketo.
                            </Text>
                        </div>
                    </PlugContentWrapper>
                </PlugWrppaer>
            </PlugContainer>
        </Waypoint>
    );
};

Plug.propTypes = {};

export default Plug;
