import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Header from './Header';

import styled from 'styled-components';
import Image from 'ui/components/Image';
import Text from 'ui/components/Text';
import theme from 'styles/theme';

import social from 'resources/img/social.png';
import social2x from 'resources/img/social2x.png';
import socialMobile from 'resources/img/socialMobile.png';
import socialMobile2x from 'resources/img/socialMobile2x.png';
import burgerBullet from 'resources/img/burgerBullet.png';
import burgerBullet2x from 'resources/img/burgerBullet2x.png';
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const LeadAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 550px;
  display: block;
  margin: auto;
  
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    max-width: 500px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 2rem 0;
    max-width: 475px;
  }
`;

const LeadContainer = styled.div`
  overflow: hidden;
  padding: 60px 0 ;

  align-items: center;
  display: flex;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
`;

const LeadPageName = styled.p`
  color: #82B5A5;
  margin-bottom: 0;
`;

const LeadContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    height: 100%;
    padding: 0 0 0 0;
    width: 100%;
    max-width: 550px;
    margin: auto;
  }
`;

const LeadHeader = styled.h2`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 12px;  
  max-width: 500px;
  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }
   @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
    max-width: unset;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.xs}px) {
    margin-top: 1rem;
  }
`;

const LeadDesc = styled.h2`
  max-width: 485px;
  font-family: ProductSans;
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  padding-bottom: 36px;  
  font-weight: normal;
`;



const LeadIllustrationContainer = styled.div`
    max-width: 600px;
    margin: auto;
    position: relative;
    height: 450px;
    height:779px;
    width: 402px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        display: none;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        margin: auto;
    }
`;


const LeadIllustrationWrapper = styled.div`
    margin-left: -12rem;
    position: absolute;

    @media only screen and (max-width: 1048px) {
        margin-left: unset;
    }
`;

const LeadIllustrationContainerMobile = styled.div`
    max-width: 400px;
    margin: auto;
    position: relative;

    display: none;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      display: block;
    }
`;

const LeadBulletPointWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      margin-bottom: 15px;
    }
`;

const Lead = props => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);

  const renderDesktopIllustration = () => (
    <LeadIllustrationContainer>
      <Image height="100%" width="100%" hdSrc={social2x} src={social} />
    </LeadIllustrationContainer>
  );

  const renderMobileIllustration = () => (
    <LeadIllustrationContainerMobile>
      <Image height="100%" width="100%" hdSrc={socialMobile2x} src={socialMobile} />
    </LeadIllustrationContainerMobile>
  );
  return (

    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
      onEnter={() => setContentIsVisible(true)}
      onLeave={() => setContentIsVisible(false)}
    >

      <LeadContainer>
        <Container>
          <Grid container>

            <Grid item xs={12} md={6}>
              {renderDesktopIllustration()}
              {renderMobileIllustration()}
            </Grid>
            <Grid item xs={12} md={6}>
              <LeadContentWrapper>
                <div>
                  <Text fontSize="40px" smFontSize="30px" fontWeight={700}>
                    You spend marketing dollars to drive <span>quality leads</span> to your website
                                    </Text>
                  <Text fontSize="20px" marginTop="35px" marginBottom="25px" fontWeight={700}>
                    But when they arrive, are you:
                                    </Text>
                  <LeadBulletPointWrapper>
                    <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                    <Text fontSize="18px" smFontSize="14px" marginLeft="10px">
                      Filtering out the qualified from the not - yet - readys?
                                      </Text>
                  </LeadBulletPointWrapper>
                  <LeadBulletPointWrapper>
                    <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                    <Text fontSize="18px" smFontSize="14px" marginLeft="10px">
                      Using chat support to make unknown visitors known prospects?
                                      </Text>
                  </LeadBulletPointWrapper>
                  <LeadBulletPointWrapper>
                    <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                    <Text fontSize="18px" smFontSize="14px" marginLeft="10px">
                      Giving your reps the tools to chat with the most engaged visitors?
                                      </Text>
                  </LeadBulletPointWrapper>
                  <LeadBulletPointWrapper>
                    <Image height="16px" width="16px" hdSrc={burgerBullet2x} src={burgerBullet} />
                    <Text fontSize="18px" smFontSize="14px" marginLeft="10px">
                      Alerting sales teams when their target accounts arrive?
                                      </Text>
                  </LeadBulletPointWrapper>
                </div>
              </LeadContentWrapper>
            </Grid>
          </Grid>
        </Container>
      </LeadContainer>
    </Waypoint>
  );
};




Lead.propTypes = {};

export default Lead;
