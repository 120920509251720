


import roiTestimonialMob3x from 'resources/img/roiTestimonialMob3x.png';
import roiTestimonialWeb3x from 'resources/img/roiTestimonialWeb3x.png';
import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import hubspotHero from 'resources/img/hubspotHero.png';
import hubspotHero2x from 'resources/img/hubspotHero2x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Button from 'ui/components/Button';
import { Desktop, MobileTablet } from "ui/components/Media";
import allison from './img/allison.png';
import quotes from './img/quotes.png';
import agora2x from './img/agora2x.png';
import aduro2x from './img/aduro2x.png';
import sortable2x from './img/sortable2x.png';
import mixrank2x from './img/mixrank2x.png';
import allplans from './img/allplans.png';
import arrows from './icons/arrows.png';
import message from './icons/message.png';
import user from './icons/user.png';
import tick from './icons/tick.png';
import run from './icons/run.png';


const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;
  padding: 163px 0;
  margin: auto;
  max-width: 1100px;
  position: relative;
  align-items: center;
  display: flex;
  background: ${p => p.theme.palette.background.five};
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 0rem 20px 5rem;
    flex-direction: column;
    button {
      text-align: center;
    }
  }
`;


const HeroContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      padding-left: 14px;
      padding-right: 14px;
    margin-top: 50px;
    justify-content: center;
    align-items: center;

  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.orange.one};
    }
  }
`;

const BackgroundDesktop = styled.div`
    background-color: white;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    width: 83%;
    height: 260px;
    border-radius: 22px;
    max-width: 1182px;
    -webkit-transform: rotate(90deg) skew(20deg,0deg) scale(0.75);
    -ms-transform: rotate(90deg) skew(20deg,0deg) scale(0.75;transform: skew(0deg,-21deg) translateX(-50%););
    transform: skew(0deg,-5deg) translateX(-50%);
    left: 50%;
    top: 280px;
`;

const BackgroundMobile = styled.div`
background-color: white;
background-size: cover;
background-position: top;
position: absolute;
top: 0;
width: 100%;
height: 100%;
border-radius: 22px;
-webkit-transform: rotate(90deg) skew(20deg,0deg) scale(0.75);
-ms-transform: rotate(90deg) skew(20deg,0deg) scale(0.75;transform: skew(0deg,-21deg) translateX(-50%););
transform: skew(0deg,-15deg) translateX(-50%);
left: 50%;
top: -50px

@media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
  top: 0px;
}
`;


const ReviewsContainer = styled.div`
  margin-top: 249px;
  display:flex;
  height: 251px;


  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;
    margin-top: 180px;
    align-items: center;
    margin-bottom: 80px;
  }

  
`;


const ReviewContentContainer = styled.div`
  padding: 56px;
  position: relative;
  background: white;
  box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1);
  border-radius: 22px;
  display: inline-table;
  position: relative;
  left: -42px;
  top: -30px;
  max-width: 472px;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 42px;
    left: 0;
    top: -90px;
    margin: 20px;
    text-align: center;
  }


  img {
    width: 87px;
    height: 63px;
    position: absolute
    right: 41px;
    bottom: 25px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      top: 25px;
      left: 16px;
    }
  }
`;


const ReviewerImage = styled.img`
  width: 158px;
  height: 158px;
  border-radius: 12px;
  margin: auto;
  margin-bottom: 21px;
`;


const ReviewerInfoContainer = styled.div`
  padding: 0 50px 22px;
  background: rgba(23, 27, 53, 0.04);
  border-radius: 22px;
  height: 203px;
  min-width: 314px;
  display: flex;
  align-items: flex-end;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 42px;
    min-width: 248px;
    padding: 42px 15px 84px;
    max-width: 248px;
    min-height: 229px;
  }

`;


const ReviewerInfoWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: -48px;
  margin: -48px auto 0;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 0;
    
}
  }
`;


const SectionHeader = styled.h1`
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 139.3%;
    /* or 50px */
    margin-top: -125px;
  max-width: 354px;
    color: #000000;


    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        margin-top: 0;

        text-align: center;
      }
`;

const CompanyContainer = styled.div`
      position: relative;
      max-width: 1210px;
      margin: auto;



@media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
  margin: 88px 22px 170px;
}
`;

const CompanyContainerBgFull = styled.div`
background-color: white;
background-size: cover;
background-position: center top;
position: absolute;
width: 100%;
height: 260px;
border-radius: 22px;
max-width: 1182px;
transform: skew(
0deg
, 
3deg
) translateX(-50%);
left: 50%;
z-index: 1;



@media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
  transform: skew(
    0deg
    , 
    6deg
    ) translateX(-50%);
}
`;

const CompanyContainerBgHalf = styled.div`
background-color:  #65D0FB1A;
background-size: cover;
background-position: center top;
position: absolute;
width: 50%;
height: 220px;
border-radius: 22px;
max-width: 1182px;
transform: skew(
0deg
, 
-13deg
);
margin: 0 50px;
right:0;
z-index: 0;


@media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
  width: 75%;
  transform: skew( 
  0deg
  , 
  -10deg
  );
  height: 243px;
}


@media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
  width: 80%;
  transform: skew(0deg, -25deg );
  height: 230px;
}


`;

const CompanyContent = styled.div`
position: relative;
z-index: 5;
display: flex;
width: 100%;
padding: 20px;
min-height: 260px;
margin-bottom: 130px;
width: 100%;

justify-content: space-between;


`;


const CompanyLogoContainers = styled.div`
  width: 100%;
  max-width: 683px;
  min-height: 131px;
  display: flex;
  justify-content: center;
`;


const CompanyLogoWrapper = styled.div`
  width: 100%;
  max-width: 388px;
  min-height: 131px;

`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${p => p.margin && `margin: ${p.margin}`}
`;


const AllPlanContainer = styled.div`
  margin: 0 auto;
  margin-top: 120px;
  max-width: 1100px;
  margin-bottom: 140px;
`;


const DesktopAllPlans = styled.div`
  position: relative;

`;


const DesktopAllPlansHero = styled.img`
  max-width: 1100px;
  margin-top: 107px;

`;

const AllPlanCard = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1);
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 90px;
  min-width: 244px;
  max-width: 244px;
  margin: 10px;
  margin-bottom: 58px;
  flex: 1;
  p {
    font-size: 14px;
    line-height: 150.8%;
    /* or 21px */

    text-align: center;

    color: #000000;
    max-width: 161px;
    opacity: 0.8;
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -17.5px;
    height: 35px;
    width: 35px;
  }
`;


const AllPlanPointContainer = styled.div`
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 175px;
  flex-wrap: wrap;
`;

const Hero = props => {
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            <>
            
              <AllPlanContainer>
                <Text textAlign="center"  marginBottom="47px" maxWidth="614px" smMaxWidth="293px" fontWeight={700}   fontSize="36px" smFontSize="24px"lineHeight="139.3%" >
                  Customer Training, Support, and Success Included in Every Plan
                </Text>
                <Desktop>
                  <DesktopAllPlans>
                    <DesktopAllPlansHero src={allplans} />
                    <div style={{position: 'absolute', top: '44px', left: '50%', transform: 'translateX(-50%)'}}>
                      <AllPlanCard>
                        <img src={run} />
                        <p>Quick and Easy Implementation by Insent</p>
                      </AllPlanCard>
                    </div>
                    <div style={{position: 'absolute', top: '100px', left: '12%'}}>
                      <AllPlanCard>
                      <img src={message} />
                      <p>Best Practices, and High- Performing Examples Delivered</p>
                      </AllPlanCard>
                    </div>
                    <div style={{position: 'absolute', top: '230px', left: '0'}}>
                      <AllPlanCard>
                      <img src={arrows} />
                      <p>Migration and Porting Conversations From Former Chat Platforms</p>
                      </AllPlanCard>
                    </div>
                    <div style={{position: 'absolute', top: '100px', right: '12%'}}>
                      <AllPlanCard>
                      <img src={tick} />
                      <p>Min. 60-Day Kick-off and Success Plan Included</p>
                      </AllPlanCard>
                    </div>
                    <div style={{position: 'absolute', top: '230px', right: '0'}}>
                      <AllPlanCard>
                      <img src={user} />
                      <p>Marketing, Sales, SDR Training Provided</p>
                      </AllPlanCard>
                    </div>

                  </DesktopAllPlans>
                  
                </Desktop> 
                <MobileTablet>
                  <AllPlanPointContainer>
                    <AllPlanCard>
                      <img src={run} />
                      <p>Quick and Easy Implementation by Insent</p>
                    </AllPlanCard>
                    <AllPlanCard>
                      <img src={message} />
                      <p>Best Practices, and High- Performing Examples Delivered</p>
                    </AllPlanCard>
                    <AllPlanCard>
                      <img src={arrows} />
                      <p>Migration and Porting Conversations From Former Chat Platforms</p>
                    </AllPlanCard>
                    <AllPlanCard>
                      <img src={tick} />
                      <p>Min. 60-Day Kick-off and Success Plan Included</p>
                    </AllPlanCard>
                    <AllPlanCard>
                      <img src={user} />
                      <p>Marketing, Sales, SDR Training Provided</p>
                    </AllPlanCard>
                  </AllPlanPointContainer>
                </MobileTablet> 
              </AllPlanContainer>
            
            {/* <HeroContainer>
                <SectionHeader>
                A comprehensive plan with no surprises. That’s a promise. 
                </SectionHeader>


                <ReviewsContainer>
                  <ReviewerInfoContainer>
                    <ReviewerInfoWrapper>
                      <ReviewerImage src={allison} />
                      <Text color="#131830" fontSize="18px" fontWeight={400} lineHeight="26px">ALLISON</Text>
                      <Text   color="rgba(0, 0, 0, 0.8)" fontSize="14px" fontWeight={400} lineHeight="176%" opacity="0.7">Director of Revenue at Sortable</Text>
                    </ReviewerInfoWrapper>
                  </ReviewerInfoContainer>
                  <ReviewContentContainer>
                    <Text fontSize="18px" fontWeight={400} lineHeight="170%" opacity="0.8" color="black" letterSpacing="-0.035em">
                      <i>“I am so confident with our plan today. It gives us flexibility to grow, and once we do, there’s no doubt Insent will grow with us.”</i>
                    </Text>

                    <img src={quotes} />
                  </ReviewContentContainer>
                </ReviewsContainer>

            </HeroContainer> */}

            <Text display="none" textAlign="center"  mdDisplay="block" fontSize="24px" fontWeight={700} lineHeight="170%" maxWidth="400px">
                Join these teams who generate more pipeline and convert more buyers on their website today
                </Text>
            <CompanyContainer>
              <CompanyContainerBgFull></CompanyContainerBgFull>
              <CompanyContainerBgHalf />

              <CompanyContent>
                <Text mdDisplay="none" margin="0 0 0 60px" fontSize="24px" fontWeight={700} lineHeight="170%" maxWidth="400px">
                Join these revenue teams who chose Insent to accelerate their website performance
                </Text>

                <CompanyLogoContainers>
                  <CompanyLogoWrapper>
                    <FlexContainer margin="30px 0 45px 0">
                      <Image hdSrc={agora2x} src={agora2x} height="34px" width="99px" />
                      <Image hdSrc={aduro2x} src={aduro2x} height="38px" width="116px" />
                    </FlexContainer>
                    <FlexContainer>
                      <Image hdSrc={sortable2x} src={sortable2x} height="41px" width="145px" />
                      <Image hdSrc={mixrank2x} src={mixrank2x} height="18px" width="107px" />
                    </FlexContainer>
                  </CompanyLogoWrapper>
                  
                </CompanyLogoContainers>
              </CompanyContent>
            </CompanyContainer>
            </>
        </Waypoint>
    );
};

Hero.propTypes = {};

export default Hero;
