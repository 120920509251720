import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
// import RatesRates from 'resources/img/RatesRates.png';
// import RatesRates2x from 'resources/img/RatesRates2x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const RatesContainer = styled.div`
  overflow: hidden;
  padding: 60px;
  align-items: center;
  margin: 80px;
  background: ${p => p.theme.palette.blue.one};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem;
    margin: 40px 1rem;
    button {
      text-align: center;
    }
  }
`;

const RateCard = styled(({ bgColor, ...others }) => <animated.div {...others} />)`
  background-color: ${p => p.bgColor || 'white'};
  padding: ${p => p.padding || '30px'};
  border-radius: 20px;
  position: relative;
  width: 280px;
  z-index: 10;
  box-shadow: 0px 10px 20px #9DCCF71A;

  &:hover {
    box-shadow: 0px 20px 30px #9DCCF74D;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: ${p => p.padding || '20px 15px'};
    margin: 0;
    margin-top: 20px;
    width: 100%;
  }
`;

const RateCardContent = styled(({ bgColor, ...others }) => <animated.div {...others} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 280;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: left;
  }
`;

const RateCardContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  display flex;
  justify-content: space-between;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }
`;


const RatesContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 50px;
    justify-content: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: left;
  }
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  background: #E9EBF4;
  height: 9px;
  border-radius: 50px;
  position: relative;
  margin-top: 10px;
`;

const BarStatus = styled.div`
  width: ${p => p.percentage ? p.percentage : '100%'};
  background: #64CFFB;
  height: 9px;
  border-radius: 50px;
  position: absolute;
`;


const ProgressBar = ({percentage}) => {
  

  return (
    <ProgressBarWrapper>
      <BarStatus percentage={percentage} />
    </ProgressBarWrapper>
  );
}
const Rates = props => {
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <RatesContainer>
                <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center" maxWidth="600px" marginBottom="30px">
                    Your website’s conversion rates could use a big lift with <span>Live Chat</span>
                </Text>
                <Text fontSize="18px" smFontSize="13px" textAlign="center" marginTop="12px" marginBottom="45px" smMarginBottom="36px" maxWidth="676px">
                    Start seeing results by identifying your most engaged buyers on site and starting conversations with them around the clock.
                </Text>
                <RateCardContainer>
                  <RateCard>
                    <RateCardContent>
                    <Text fontSize="60px" smFontSize="30px" fontWeight={700} mdMarginRight="10px" mdMarginLeft="0" textAlign="center" mdTextAlign="left" maxWidth="730px" >
                        <span>40%</span>
                      </Text>
                      <div style={{width: '100%'}}>
                      <Text fontSize="18px" smFontSize="14px" fontWeight={400} textAlign="center" mdTextAlign="left" maxWidth="730px" marginTop="19px" mdMarginTop="0">
                        Increase In
                      </Text>
                      <Text fontSize="24px" smFontSize="18px" fontWeight={700} textAlign="center" mdTextAlign="left" maxWidth="730px" marginTop="5px" smMarginTop="0">
                        Conversion Rate
                      </Text>
                      </div>
                      
                    </RateCardContent>
                    <ProgressBar percentage="40%"/>
                  </RateCard>
                  <RateCard>
                    <RateCardContent>
                      <Text fontSize="60px" smFontSize="30px" fontWeight={700} mdMarginRight="10px" mdMarginLeft="0" textAlign="center" mdTextAlign="left" maxWidth="730px" >
                        <span>32%</span>
                      </Text>
                      <div style={{width: '100%'}}>
                      <Text fontSize="18px" smFontSize="14px" fontWeight={400} textAlign="center" mdTextAlign="left" maxWidth="730px" marginTop="19px" mdMarginTop="0">
                        Increase In Early-stage 
                      </Text>
                      <Text fontSize="24px" smFontSize="18px" fontWeight={700} textAlign="center" mdTextAlign="left" maxWidth="730px" marginTop="5px" smMarginTop="0">
                        Sales Development
                      </Text>
                      </div>
                    </RateCardContent>
                    <ProgressBar percentage="32%"/>
                  </RateCard>
                  <RateCard>
                    <RateCardContent>
                    <Text fontSize="60px" smFontSize="30px" fontWeight={700} mdMarginRight="10px" mdMarginLeft="0" textAlign="center" mdTextAlign="left" maxWidth="730px" >
                        <span>18%</span>
                      </Text>
                      <div style={{width: '100%'}}>
                      <Text fontSize="18px" smFontSize="14px" fontWeight={400} textAlign="center" mdTextAlign="left" maxWidth="730px" marginTop="19px" mdMarginTop="0">
                        Increase In 
                      </Text>
                      <Text fontSize="24px" smFontSize="18px" fontWeight={700} textAlign="center" mdTextAlign="left" maxWidth="730px" marginTop="5px" smMarginTop="0">
                        Closing Deals
                      </Text>
                      </div>
                      

                    </RateCardContent>
                    <ProgressBar percentage="18%"/>
                  </RateCard>
                </RateCardContainer>
                <Text fontSize="28px" smFontSize="18px" fontWeight={700} textAlign="center" maxWidth="730px" marginTop="40px">
                  Your buyers don’t need to wait to talk to you anymore.
                </Text>
            </RatesContainer>
        </Waypoint>
    );
};

Rates.propTypes = {};

export default Rates;
