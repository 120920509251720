import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import carestack from 'resources/img/carestack.svg';
import peoplestrategy from 'resources/img/peoplestrategy.png';
import mixrank from 'resources/img/mixrank.png';
import mixrank2x from 'resources/img/mixrank2x.png';
import sortable from 'resources/img/sortable.png';
import sortable2x from 'resources/img/sortable2x.png';
import symbl from 'resources/img/symbl.png';
import Image from 'ui/components/Image';
import { LOGO } from 'resources/logo';

const CustomerCWrapper = styled.div`
    overflow-y: hidden;
    white-space: nowrap;
`;

const CustomerNameContainer = styled.div`
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-width: 1100px;
`;

const CustomerImageWrapper = styled.div`
  margin: ${p => p.margin};
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: filter 0.2s;
  
  &:hover {
    filter: none;
    -webkit-filter: grayscale(0);
  }
`;

const CustomerContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 0 50px;;
  background-color: white;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 36px 0;
  }
`;

const Customer = props => {

    return (
        <Element name="Customer">
            <CustomerContainer>
                <Waypoint
                    scrollableAncestor={window}
                    topOffset="10%"
                    bottomOffset="35%"
                >
                    <Container>
                        <CustomerCWrapper>
                        <CustomerNameContainer>
                            <CustomerImageWrapper margin="0 62.5px 0 auto">
                                <Image height="27px" width="160px" hdSrc={mixrank2x} src={mixrank} />
                            </CustomerImageWrapper>
                            <CustomerImageWrapper margin="33px 62.5px">
                                <Image height="45px" width="160px" hdSrc={sortable2x} src={sortable} />
                            </CustomerImageWrapper>
                            <CustomerImageWrapper margin="29px 62.5px">
                                <Image height="42px" width="160px" hdSrc={carestack} src={carestack} />
                            </CustomerImageWrapper>
                            <CustomerImageWrapper margin="30.5px auto 30.5px 62.5px">
                            <Image height="unset" width="160px" hdSrc={LOGO.peopleStrategy.src} src={LOGO.peopleStrategy.src} />
                            </CustomerImageWrapper>
                        </CustomerNameContainer>
                        </CustomerCWrapper>
                        
                    </Container>
                </Waypoint>
            </CustomerContainer>
        </Element>
    );
};

Customer.propTypes = {};

export default Customer;
