export default {
  palette: {
    primary: '#1473e6',
    blue: {
      one: '#F7FAFF',
      two: '#008EEF',
      three: '#03A2E0',
    },
    purple: {
      one: '#5744AF',
    },
    orange: {
      one: '#FE7A59'
    },
    white: '#ffffff',
    black: '#000000',
    secondary: '#64D0FF',
    background: {
      primary: '#FFFFFF',
      secondary: '#0E7EF9',
      gray: '#F5F5F5',
      one: ' #ECF1FA',
      two: '#9F97E0',
      three: '#F7FAFF',
      four: '#EDD9D9',
      five: '#F7F8FA'
    },
    text: {
      one: '#191919',
      two: '#707070',
      three: '#82B5A5',
      four: '#757575',
      five: '#64CFFB',
    }
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};
