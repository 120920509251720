import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled, {keyframes} from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import roiHero1x from 'resources/img/roiHero1x.png';
import roiHero3x from 'resources/img/roiHero3x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Header from '../Home/Header';
import Button from 'ui/components/Button';
import roiHeroLead from './icons/roiHeroLead.png';
import roiHeroMeetings from './icons/roiHeroMeetings.png';
import roiHeroRevenue from './icons/roiHeroRevenue.png';
// import AnimatedNumber from 'react-animated-number';

const slowShakeAnimation = keyframes`
 0% {     margin-top: -12px; }
//  25% {     margin-top: 0px; }
 50% { margin-top: 12px; }
//  75% { margin-top: 0px; }
 100% { margin-top: -12px; }
`
const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;

  padding-top: 219px;
  padding-bottom: 237px;
  align-items: flex-end;
  display: flex;
  background: ${p => p.theme.palette.background.five};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 158px 0 0;

    button {
      text-align: center;
    }
  }
`;


const HeroContentWrapper = styled(animated.div)`
  display: flex;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;

  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.orange.one};
    }
  }
`;


const HeroImageContainer = styled.div`
  max-width: 395px;
  margin: 0 auto;
  margin-right: 0;
  position: relative;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-right: auto;
  }
`;


const LeadPicContainer = styled.div`
  padding: 12px 15px 20px;
  background: white;
  box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1);
  border-radius: 11px;
  position: absolute;
  width: 120px;
  height: 82px;
  top: 167px;
  left: -66px;
  animation-name: ${slowShakeAnimation};
 animation-duration: 3s;
 animation-iteration-count: infinite;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 4px 12px 7px;
    width: 73px;
    height: 52px;
    top: 139px;
    left: -8px;
  }


  img {
    width:81px;
    height: 36px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      width:49px;
      height: 22px;
    }
  }
`;

const MeetingPicContainer = styled.div`
  padding: 15px 22px 14px;
  background: white;
  box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1); 
  border-radius: 11px;
  position: absolute;
  width: 177px;
  height: 85px;
  top: 76px;
  right: -115px;
  animation-name: ${slowShakeAnimation};
 animation-duration: 5s;
 animation-iteration-count: infinite;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 9px 13px 11px;
    width: 99px;
    height: 64px;
    top: 36px;
    right: -30px;
  }

  img {
    width: 141px;
    height: 55px;
    position: relative;
    bottom: 16px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      width: 82px;
      height: 31px;
      bottom: 4px;
    }
  }
`;

const RevenuePicContainer = styled.div`
  padding: 26px 18px 16px;
  background: white;
  box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1);
  border-radius: 11px;
  position: absolute;
  width: 165px;
  height: 120px;
  top: 439px;
  left: -11px;
  animation-name: ${slowShakeAnimation};
 animation-duration: 6s;
 animation-iteration-count: infinite;
  margin-top: 6px;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 17px 12px 12px;
    width: 99px;
    height: 81px;
    top: 302px;
    left: 25px;
    
  }

  img {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 32px;
    height: 32px;
    border-radius: 22px;
    border: 3px solid white;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      top: -32px;
    }
  }
`;


const RevenuePicValueContainer = styled.div`
  width: 130px;
  height: 52px;
  padding: 9px 21px 8px 25px;
  margin-top: 6px;
  background: #13183008;
  border-radius: 10px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 3px;
    width: 77.97px;
    height: 31.19px;
    padding: 5px 14px 6px 15px;
  }
`;


const RevenuePicValue = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 144.05%;
  text-align: center;
  background: -webkit-linear-gradient(#65D0FB, #1F88F9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 3px;
    font-size: 14px;
    line-height: 144.05%;
  }
`;

const PicText = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 139.3%;
  margin: 0;
  text-align: ${p => p.textAlign ? p.textAlign : 'left'};
  color: #000000;

  opacity: 0.7;
`;

const Hero = props => {

  // const [revenueValue, setRevenueValue] = React.useState(10000);

  // React.useEffect(() => {
  //   setInterval(() => {
  //     setRevenueValue(revenueValue + parseInt(Math.floor(Math.random() * 100) + 1 ) );
  //     console.log("revenueValue",revenueValue)
  //   },10000)
  // },[revenueValue])
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <HeroContainer>
                <Header />
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <HeroContentWrapper>
                                  <Text fontSize="48px" smFontSize="23px" fontWeight={700} mdTextAlign="center" lineHeight="144%">
                                    Calculate the Impact of Chat On Your Website 
                                  </Text>
                                  <Text fontSize="18px" smFontSize="13px" mdTextAlign="center" marginLeft="0" mdMarginLeft="auto" marginTop="25px" marginBottom="30px" smMarginBottom="30px" maxWidth="530px" opacity="0.8" lineHeight="170.3%">
                                    78% of customers buy from the first responder. And once you respond, sales conversions are 391% higher in the first minute.  Those are incredible results, and it’s why B2B marketing and sales teams are adopting conversational marketing on their website more than ever before.
                                  </Text>
                                  <Button onClick={props.scrollToCalculator} height="50px" fontWeight="600" fontSize="14px" padding="15px 40px">Calculate Now</Button>
                            </HeroContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <HeroImageContainer>
                            <LeadPicContainer>
                              <PicText>LEAD</PicText>
                              <img src={roiHeroLead} />
                            </LeadPicContainer>
                            <MeetingPicContainer>
                              <PicText>MEETINGS</PicText>
                              <img src={roiHeroMeetings} />
                            </MeetingPicContainer>
                            <RevenuePicContainer>
                                <div style={{position: 'relative'}}>
                                  <PicText textAlign="center">REVENUE</PicText>
                                  <img src={roiHeroRevenue} />
                                  <RevenuePicValueContainer>
                                    <RevenuePicValue>
                                      $34354
                                    {/* $<AnimatedNumber
                                      style={{
                                          transition: '0.8s ease-out',
                                          fontSize: 24,
                                          // transitionProperty:
                                          //     'background-color, color, opacity'
                                      }}
                                      // frameStyle={perc => (
                                      //     perc === 100 ? {} : {opacity: 0.25}
                                      // )}
                                      duration={10000}
                                      value={revenueValue}
                                      stepPrecision={10}
                                      component="text"/> */}
                                    </RevenuePicValue>
                                  </RevenuePicValueContainer>
                                </div>
                            </RevenuePicContainer>
                              <Image height="100%" width="100%" maxWidth="395px" margin="0 auto" mdMarginRight="auto" marginRight="0"  display="flex" hdSrc={roiHero3x} src={roiHero1x} />
                          </HeroImageContainer>
                        </Grid>
                    </Grid>
                </Container>
            </HeroContainer>
        </Waypoint>
    );
};

Hero.propTypes = {};

export default Hero;
