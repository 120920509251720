import React from 'react';
import Hero from './Hero';
import Footer from '../Home/Footer';
import { Helmet } from "react-helmet";



const Home = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Make your customer acquisition easier when you add the ZoomInfo Chat to your sales acceleration software. Book a demo today to learn more. " />
        <meta name="keywords" content="customer acquisition, Sales enablement, Sales acceleration, meeting scheduler" />
        <title>Better Sales Enablement with an AI Chatbot | ZoomInfo Chat</title>
      </Helmet>
      <Hero />
      <Footer />
    </>
  );
};

Home.propTypes = {};

export default Home;
