import React, { useEffect, useState } from 'react';


import styled from 'styled-components';

import UseCase from './UseCase';
import DemandGeneration from './icons/DemandGeneration.png';
import SalesAcceleration from './icons/SalesAcceleration.png';
import Marketing from './icons/Marketing.png';
import ABM from './icons/ABM.png';
import CustomerSuccess from './icons/CustomerSuccess.png';
import LeftArrow from './icons/LeftArrow.png';
import RightArrow from './icons/RightArrow.png';
import StickyBox from "react-sticky-box";
import { Container, Grid } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import { Desktop, MobileTablet } from "ui/components/Media";

import useCase13x from 'resources/img/useCase13x.png';
import useCase23x from 'resources/img/useCase23x.png';
import useCase33x from 'resources/img/useCase33x.png';
import useCase43x from 'resources/img/useCase43x.png';
import useCase53x from 'resources/img/useCase53x.png';
import useCase63x from 'resources/img/useCase63x.png';
import useCase73x from 'resources/img/useCase73x.png';
import useCase83x from 'resources/img/useCase83x.png';
import useCase93x from 'resources/img/useCase93x.png';
import useCase103x from 'resources/img/useCase103x.png';
import useCase113x from 'resources/img/useCase113x.png';
import { menuList } from './constants';

const CasesContainer = styled.div`
    padding: 0;
    margin: 0;
    position: relative;
    top: -105px;
    max-width: 1440px;
    margin: auto auto -105px;
`;

const SideMenuContainer = styled.div`
    margin-top: 105px;
`;


const SideMenuContainerMobileContainer = styled.div`
    padding: 120px 18px 0;
    overflow-y: hidden;
    white-space: nowrap;
    display: inline-grid;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }


        background: #F7FAFF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);

`;

const SideMenuContainerMobile = styled.div`
    margin-top: 105px;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin: auto;
`;

const SideMenuItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 20px 0 20px 20px;
    width: 290px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        border-bottom: 3px solid ${p => p.active ? '#0A7EF9' : 'transparent'};
        background: ${p => p.active ? '#E7F2FE' : 'transparent'};
        padding: 10px 14px;
        margin-left: 0px;
        margin: 0;
        width: 100%;
        min-width: unset;
      }

    

`;

const SideMenuItemText = styled.p`
     
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.6px;
    min-width: 250px;
    color: ${p => p.active ? '#0A7EF9' : 'black'};
    opacity: ${p => p.active ? '1' : '0.5'};
    padding-left: 8px; 
    padding-right: 0;
    margin: 0;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        min-width: unset;
    }
    @media only screen and (min-width: ${p => p.theme.breakpoints.md}px) {
        border-right: 3px solid ${p => p.active ? '#0A7EF9' : 'transparent'};
        width: 100%;
        padding-left: 30px; 
        padding-right: 42px;
    }
    
`;

const UseCaseListContainer = styled.div`
    background: rgba(247, 250, 255, 0.6);
    
    position: relative;
    padding: 105px 0 16px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        padding: 0px 0 16px;
      }
`;

const PaginationContainer = styled.div`
    display: flex;
    padding: 40px 50px 58px;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        justify-content: flex-start;
      }
    img {
        margin: 10px 17px;
        width: 8px;
        height: 14px;
        cursor: pointer;
    }
`;

const PaginatedNumber = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.6px;
    color: ${p => p.active ? 'white' : '#00000070'};
    background-color: ${p => p.active ? '#0A7EF9' : 'rgba(247, 250, 255, 0.6);'};
    opacity: 1;
    padding: 10px 14px;
    border-radius: 2px;
    margin: 0;
    cursor: pointer;
`;

const SideMenuIconContainer = styled.div`
    width: 22px;
    display: flex;
    justify-content: center;
`;

const LIST = [{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    },{
        icon: SalesAcceleration,
        text: 'Sales Acceleration'
    }],
    tagsText: ['Demand Generation','Sales Acceleration'],
    title: <>Book Meetings for Your <span>Sales Team</span></>,
    webImage3x: useCase103x,
    desc: <>Give your highly engaged prospects the ability to easily book a meeting directly into their appropriate account owner's calendar.
    <br />
    <br />
    That can happen on your website in a matter of seconds. No more
    contact pages, form submits, and back-and-forth emails to
    schedule one meeting.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    },{
        icon: ABM,
        text: 'ABM'
    }],
    tagsText: ['Demand Generation','ABM'],
    title: <>Roll out the Red Carpet for <span>Your Target Accounts</span></>,
    webImage3x: useCase63x,
    desc: <>Once a visitor lands on your website, Insent will
    automatically identify their company and match that
     against your target account list in your marketing
     automation platform.<br /><br />
    
    An alert will be then sent to the account owner that
     their target prospect is on site and can open a
     conversation in real-time.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    },{
        icon: Marketing,
        text: 'Marketing'
    }],
    tagsText: ['Demand Generation','Marketing'],
    title: <>Connect Your <span>Really Hot Prospects</span> With Sales</>,
    webImage3x: useCase83x,
    desc: <>Leverage the engagement data in your marketing
    automation platform to automatically qualify an
     inbound visitor and connect them to your sales
     team while they're on your website.<br /><br />
    
    Say goodbye to the MQL that sales needs to reach
     out to via email and phone calls. You can now
     spark a conversation with your hottest prospects
     on your site.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    }],
    tagsText: ['Demand Generation'],
    title: <><span>Discover & Engage </span>Unknown Visitors</>,
    webImage3x: useCase113x,
    desc: <>Today, you engage a ton with your known prospects
    (those that are identified in your marketing automation
     platform). You treat them to special content tracks and
     nurture programs until they hit a threshold for sales to
     likely take over.
    <br /><br />
    But now you can discover and engage your unknown visitors with Insent's IP Resolution Data to learn who each
    visitor is and personalize your chatbot for their arrival.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    },{
        icon: SalesAcceleration,
        text: 'Sales Acceleration'
    }],
    tagsText: ['Demand Generation','Sales Acceleration'],
    webImage3x: useCase13x,
    title: <>Accelerate Your <span>Open Opportunities</span></>,
    desc: <>Recognize immediately when a prospect in your active pipeline
    visits your website.
    <br /><br />
    If Salesforce has this account mapped as an open opportunity,
    you may greet them by their name and fast track them to the
    sales rep who's working the deal.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    }],
    tagsText: ['Demand Generation'],
    title: <><span>Conversational</span> Nurtures</>,
    webImage3x: useCase53x,
    desc: <>You've built out nurture programs that will lead your
    prospect down the buyer journey—from an email to
    landing page or content destination.
    <br /><br />
    But what happens while they’re on your content?
    <br /><br />
    Build a conversational nurture while your prospect is
     engaging with your content after a reasonable amount
     of time to suggest recommended content, or at an ABM
     level, encourage real human interaction post-
    engagement.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    },{
        icon: ABM,
        text: 'ABM'
    }],
    tagsText: ['Demand Generation','ABM'],
    title: <>Convert Paid Traffic into <span>Sales Leads</span></>,
    webImage3x: useCase43x,
    desc: <>Paid traffic is too expensive to ignore.
    <br /><br />
    You spend a lot of budget advertising to your target customers on
     paid social, paid display, and even on expensive platforms like
     RollWorks, Terminus, or Demandbase.
     <br /><br />
    But what happens after they click-through and land on your
    website? Fast track them to sales in real-time and get them what
     they came looking for.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    }],
    tagsText: ['Demand Generation'],
    title: <><span>Product or Pricing</span> Page Concierge</>,
    webImage3x: useCase23x,
    desc: <>If a prospect visits multiple product pages, or navigates to your
    pricing page, chances are they are further down the funnel.
    <br /><br />
   Configure a chat that will have your sales team on standby
    once prospects visit to proactively provide some context.</>
},{
    tags: [{
        icon: CustomerSuccess,
        text: 'Customer Success'
    },{
        icon: SalesAcceleration,
        text: 'Sales Acceleration'
    }],
    tagsText: ['Customer Success','Sales Acceleration'],
    title: <><span>Expand and Upsell With</span> Existing Customers</>,
    webImage3x: useCase33x,
    desc: <>Leverage an integration with your CRM or marketing automation platform to
    automatically identify existing customers who arrive on your website and invite them to
    learn about the latest products, or ones they have not yet tried.
    <br /><br />
   If they engage, connect them to the appropriate rep to expand your stickiness within
    that account.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    }],
    tagsText: ['Demand Generation'],
    title: <>Grow Your <span>Subscriber Audience</span></>,
    webImage3x: useCase73x,
    desc: <>Every blog and newsletter is designed for
    maximize reach and content loyalty among your
    audience.
    <br /><br />
    Based on your prospects' reading intent, offer the ability to sign up for "even more great content" and convert your blog regulars into raving fans.</>
},{
    tags: [{
        icon: DemandGeneration,
        text: 'Demand Generation'
    },{
        icon: SalesAcceleration,
        text: 'Sales Acceleration'
    }],
    tagsText: ['Demand Generation','Sales Acceleration'],
    title: <><span>Event Sign Ups</span> Made Easy</>,
    webImage3x: useCase93x,
    desc: <>Planning an event (virtual or in-person) & driving registration
    is hard.
    <br /><br />
   Leverage Insent's integration with your marketing automation
    platform to identify your target attendee criteria (i.e.
    geography, persona, industry) for each event, & for every
   time they visit your website, a chat can invite them to the 
   event.
   <br /><br />
   Answer key questions & remove barriers to registration &
    attendance.</>
}];



const Cases = (props) => {
    let [activeUseCase, setActiveUseCase] = React.useState('Demand Generation')
    let [pageNo, setPageNo] = React.useState(1);
    let [useCasesList, setUseCasesList] = useState([...LIST]);


    const SectionTopRef = React.useRef(null);

    const scrollToTop = () => {
        if (SectionTopRef && SectionTopRef.current) {
        SectionTopRef.current.scrollIntoView();
        }
    };
    
    useEffect(() => {
        let contains = LIST.filter(list => list.tagsText.includes(activeUseCase));
        let sortedUseCase = [...contains, ...LIST.filter(list => !list.tagsText.includes(activeUseCase))];
        if (pageNo === 1) {
            sortedUseCase.length = 5
        } else {
            sortedUseCase.splice(0, 5);
        }

        console.log("sortedUseCase",sortedUseCase)
        setUseCasesList([...sortedUseCase]);

    },[activeUseCase, pageNo]);

    const onMenuClick = (text) => {
        scrollToTop();
        setPageNo(1);
        setActiveUseCase(text);
    }

    const onPageNoClick = (paginatedNo) => {
        scrollToTop();
        setPageNo(paginatedNo);
    }


const CasesDesktopView =  (
      <Desktop>
        <StickyBox>
                <SideMenuContainer marginTop={props.marginTop}>
                    {menuList.map((menu, menuId) => (
                        <SideMenuItem key={menuId} onClick={() => onMenuClick(menu.text)}>
                            <SideMenuIconContainer>
                                <img src={menu.text === activeUseCase ? menu.activeIcon : menu.defaultIcon} />
                            </SideMenuIconContainer>
                            <SideMenuItemText active={menu.text === activeUseCase}>{menu.text}</SideMenuItemText>
                            
                        </SideMenuItem>
                    ))}
                </SideMenuContainer>
            </StickyBox>
            <UseCaseListContainer>
                <div  ref={SectionTopRef} style={{position: 'absolute', top: '105px', marginTop: '-105px'}}/>
                <Container >
                    <Grid container>
                        {useCasesList.map((useCase, useCaseId) => (
                            <Grid item xs={12} md={12} lg={((useCaseId === 0) && (pageNo === 1) ? 12 : 6)}>
                                <UseCase {...useCase} key={useCaseId} isFullWidth={(useCaseId === 0) && (pageNo === 1)}/>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <PaginationContainer>
                    <img src={LeftArrow} onClick={() => onPageNoClick(1)}/>
                    <PaginatedNumber onClick={() => onPageNoClick(1)} active={pageNo === 1}>1</PaginatedNumber>
                    <PaginatedNumber onClick={() => onPageNoClick(2)} active={pageNo === 2}>2</PaginatedNumber>
                    <img src={RightArrow} onClick={() => onPageNoClick(2)}/>
                </PaginationContainer>
            </UseCaseListContainer>
      </Desktop>
    );


  const CasesMobileView = (
      <MobileTablet>
          <UseCaseListContainer>
                <StickyBox>
                    <SideMenuContainerMobileContainer>
                        <SideMenuContainerMobile>
                            {menuList.map((menu, menuId) => (
                                <SideMenuItem active={menu.text === activeUseCase} key={menuId} onClick={() => onMenuClick(menu.text)}>
                                    <img src={menu.text === activeUseCase ? menu.activeIcon : menu.defaultIcon} />
                                    <SideMenuItemText active={menu.text === activeUseCase}>{menu.text}</SideMenuItemText>
                                    
                                </SideMenuItem>
                            ))}
                        </SideMenuContainerMobile>
                    </SideMenuContainerMobileContainer>
                    
                </StickyBox>
                <div  ref={SectionTopRef} style={{position: 'absolute', top: '105px', marginTop: '-105px'}}/>
                <Container >
                    <Grid container>
                        {useCasesList.map((useCase, useCaseId) => (
                            <Grid item xs={12} md={12} lg={((useCaseId === 0) && (pageNo === 1) ? 12 : 6)}>
                                <UseCase {...useCase} key={useCaseId} isFullWidth={(useCaseId === 0) && (pageNo === 1)}/>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <PaginationContainer>
                    <img src={LeftArrow} onClick={() => onPageNoClick(1)}/>
                    <PaginatedNumber onClick={() => onPageNoClick(1)} active={pageNo === 1}>1</PaginatedNumber>
                    <PaginatedNumber onClick={() => onPageNoClick(2)} active={pageNo === 2}>2</PaginatedNumber>
                    <img src={RightArrow} onClick={() => onPageNoClick(2)}/>
                </PaginationContainer>
          </UseCaseListContainer>
          
      </MobileTablet>
    );

    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >

            <CasesContainer  style={{display: "flex", alignItems: "flex-start"}}>
                {CasesDesktopView}
                {CasesMobileView}
                
                
                
                
            </CasesContainer>
        </Waypoint>
        
    );
};


export default Cases;