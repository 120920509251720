import React from 'react';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import Header from '../Home/Header';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';

const HeroContainer = styled.div`
  overflow: hidden;
  position: relative;
  //   border:1px solid red;
`;

const MarketingDiv = styled.div`
  margin-top: 100px;
`;

const renderMarketingPageMob = () => (
  <MarketingDiv>
    <div class="marketing">
      <section class="marketing__banner">
        <div class="container">
          <div class="text-col">
            <h1>
              Turn every click into a
              <br />
              worth while <span>conversation ☝</span>
            </h1>
            <p>
              Insent’s live chat software instantly connects <br />
              you to the most interested buyers.
            </p>
            <CallToAction />
          </div>
          <div class="img-col img-revel">
            <img src="./assets/img/InsentXPD.png" alt="banner-img" />
          </div>
        </div>
      </section>
      <div class="lines-set__1">
        <section class="marketing__client">
          <div class="container">
            <div class="testimonal testimonal---marketing">
              <div class="img-wrapper">
                <img src="./assets/img/Karthik-Sundaram.jpg" alt="banner-img" />
              </div>
              <div class="content">
                <div class="logo">
                  <img
                    src="./assets/img/purplepatch-services_20170804230450.jpeg"
                    alt="banner-img"
                  />
                </div>
                <p>
                  Nobody wants to talk to a bot when there’s a real person who
                  can help my prospects buy.
                </p>
                <h6>Karthik Sundaram</h6>
                <h5>Purplepatch Services</h5>
              </div>
            </div>
            <div class="list">
              <h2>
                Win more of your dream <span>customers</span> with <br />
                real conversations
              </h2>
              <p>
                Our live chat software enhances - not replaces - real
                conversations.
              </p>
              <a href="#" class="ghost-btn">
                Meet our customers
              </a>
              <div class="client-list---mob">
                <img
                  src="./assets/img/TouchPointsLogo.jpg"
                  alt="banner-img"
                  style={{ width: '128px' }}
                  aria-hidden="false"
                  tabindex="0"
                />
                <img
                  src="./assets/img/purplepatch-services_20170804230450.jpeg"
                  alt="banner-img"
                  style={{ width: '128px' }}
                  aria-hidden="false"
                  tabindex="0"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="para-section">
          <p>
            The shortcut to meeting your <span>pipeline goals</span> is by
            connecting your ideal prospects to your sales team while they’re
            visiting your website.
          </p>
          <p>
            And communicate with them the way <span>you</span> want to be
            communicated to.
          </p>
        </section>
        <section class="two-col-block two-col-block---img-left">
          <div class="container">
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s.png" alt="banner-img" />
            </div>
            <div class="text-wrapper">
              <h2>Start real-time conversations</h2>
              <p>
                Your buyers crave real connections. Don’t want to wait a day or
                two before you—or worse—your competitor, gets in touch. Chatbot
                marketing enables immediate responses and puts your customers
                first.
              </p>
              <p>
                And live chat software does away with back-and-forth emails
                needed to schedule just one meeting.{' '}
              </p>
            </div>
          </div>
        </section>
        <section class="two-col-block two-col-block---img-right">
          <div class="container">
            <div class="text-wrapper">
              <h2>Automate marketing - not connections.</h2>
              <p>
                Human-first marketing automation means helping the real faces
                behind your business— not chatbots—close deals. Insent will
                notify your SDRs/AEs when a prospect or target account is on
                your website, triggering 1-on-1 connection.
              </p>
              <p>
                Treat our live chat software like your back-up plan. It helps
                make sure you don’t lose leads when your team is busy or out of
                the office.
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s2.png" alt="banner-img" />
            </div>
          </div>
        </section>
      </div>
      <section class="two-col-block two-col-block---img-left">
        <div class="container">
          <div class="img-wrapper img-revel">
            <img src="./assets/img/s3.png" alt="banner-img" />
          </div>
          <div class="text-wrapper">
            <h2>Fuel sales conversations with intel</h2>
            <p>
              Demand generation, made easy. Your sales team can access
              engagement metrics, campaign membership data, and account-level
              insights from your CRM in an account view alongside Insent’s live
              chat software.{' '}
            </p>
            <p>
              No more generic conversation starters and switching between
              screens to gather intel on your prospect.
            </p>
          </div>
        </div>
      </section>
      <div class="lines-set__2">
        <section class="two-col-block two-col-block---last-row">
          <div class="container">
            <div class="text-wrapper">
              <h2>
                Insent was built to make your marketing <br />
                team <span> work effortlessly</span> with sales
              </h2>
              <p>
                No more marketing to build engagement. <br />
                Start marketing to build relationships{' '}
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s4.png" alt="banner-img" />
            </div>
          </div>
        </section>
        <section class="feature-grid---mob">
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon4.png" alt="banner-img" />
            </div>
            <h2>Prioritize high value accounts</h2>
            <p>
              Your target customers who are actually engaging deserve some
              prioritized, red-carpet service.
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon2.png" alt="banner-img" />
            </div>
            <h2>Convert the anonymous</h2>
            <p>
              Generate more pipeline by converting your anonymous website
              visitors into known leads, and sales-ready opportunities.
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon3.png" alt="banner-img" />
            </div>
            <h2>Continue the conversation in real-time</h2>
            <p>
              Once you’ve sparked a conversation, you can route it to the
              appropriate SDR or AE in real-time by moving them onto any
              messenger platform you use.{' '}
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon1.png" alt="banner-img" />
            </div>
            <h2>Book qualified meetings</h2>
            <p>
              Give your prospects quick access to your sales team by skipping
              the forms and instantly qualifying and booking meetings right from
              your website.
            </p>
          </div>
        </section>
        <section class="para-section-bootom">
          <h6>
            Let the <span>pipeline generation</span> begin!{' '}
          </h6>
          <p>
            Meet your pipeline goals by instantly opening the door for your
            dream prospects to meet with sales, from the moment they land on
            your website.
          </p>
        </section>
      </div>
      <section class="get-started-footer">
        <div class="container">
          <div class="text-box">
            <h2>Let the pipeline generation begin! </h2>
            <p>
              Meet your pipeline goals by instantly opening the door for your
              dream prospects to meet with sales, the moment they land on your
              website.
            </p>
            <CallToAction />
          </div>
          <div class="img img-revel">
            <img src="./assets/img/wrong.png" alt="banner-img" />
          </div>
        </div>
      </section>
    </div>
  </MarketingDiv>
);

const renderMarketingPage = () => (
  <MarketingDiv>
    <div class="marketing">
      <section class="marketing__banner">
        <div class="container">
          <div class="text-col">
            <h1>
              Turn every click into a
              <br />
              worth while <span>conversation ☝</span>
            </h1>
            <p>
              Insent’s live chat software instantly connects <br />
              you to the most interested buyers.
            </p>
            <CallToAction />
          </div>
          <div class="img-col img-revel">
            <img src="./assets/img/InsentXPD.png" alt="banner-img" />
          </div>
        </div>
      </section>
      <div class="lines-set__1">
        <section class="marketing__client">
          <div class="container">
            <div class="testimonal testimonal---marketing">
              <div class="img-wrapper">
                <img src="./assets/img/Karthik-Sundaram.jpg" alt="banner-img" />
              </div>
              <div class="content">
                <div class="logo">
                  <img
                    src="./assets/img/purplepatch-services_20170804230450.jpeg"
                    alt="banner-img"
                  />
                </div>
                <p>
                  Nobody wants to talk to a bot when there’s a real person who
                  can help my prospects buy.
                </p>
                <h6>Karthik Sundaram</h6>
                <h5>Purplepatch Services</h5>
              </div>
            </div>
            <div class="list">
              <h2>
                Win more of your dream <span>customers</span> with <br />
                smarter marketing automation
              </h2>
              <p>
                Our live chat software enhances - not replaces - real
                conversations.
              </p>
              <a href="#" class="ghost-btn">
                Meet our customers
              </a>
              <div class="client-list center">
                <img src="./assets/img/TouchPointsLogo.jpg" alt="banner-img" />
                <img
                  src="./assets/img/purplepatch-services_20170804230450.jpeg"
                  alt="banner-img"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="para-section">
          <p>
            The shortcut to successful demand generation is connecting your
            ideal prospects to your sales team - while they’re still on your
            website.
          </p>
          <p>
            Communicate with them the way <span>you</span> want to be
            communicated to.{' '}
          </p>
        </section>
        <section class="two-col-block two-col-block---img-left">
          <div class="container">
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s.png" alt="banner-img" />
            </div>
            <div class="text-wrapper">
              <h2>Start real-time conversations</h2>
              <p>
                Your buyers crave real connections. Don’t want to wait a day or
                two before you—or worse—your competitor, gets in touch. Chatbot
                marketing enables immediate responses and puts your customers
                first.
              </p>
              <p>
                And live chat software does away with back-and-forth emails
                needed to schedule just one meeting.{' '}
              </p>
            </div>
          </div>
        </section>
        <section class="two-col-block two-col-block---img-right">
          <div class="container">
            <div class="text-wrapper">
              <h2>Automate marketing - not connections.</h2>
              <p>
                Human-first marketing automation means helping the real faces
                behind your business— not chatbots—close deals. Insent will
                notify your SDRs/AEs when a prospect or target account is on
                your website, triggering 1-on-1 connection.
              </p>
              <p>
                Treat our live chat software like your back-up plan. It helps
                make sure you don’t lose leads when your team is busy or out of
                the office.
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s2.png" alt="banner-img" />
            </div>
          </div>
        </section>
      </div>
      <section class="two-col-block two-col-block---img-left">
        <div class="container">
          <div class="img-wrapper img-revel">
            <img src="./assets/img/s3.png" alt="banner-img" />
          </div>
          <div class="text-wrapper">
            <h2>Fuel sales conversations with intel</h2>
            <p>
              Demand generation, made easy. Your sales team can access
              engagement metrics, campaign membership data, and account-level
              insights from your CRM in an account view alongside Insent’s live
              chat software.{' '}
            </p>
            <p>
              No more generic conversation starters and switching between
              screens to gather intel on your prospect.
            </p>
          </div>
        </div>
      </section>
      <div class="lines-set__2">
        <section class="two-col-block two-col-block---last-row">
          <div class="container">
            <div class="text-wrapper">
              <h2>
                Insent was built to make your marketing <br />
                team <span> work effortlessly</span> with sales
              </h2>
              <p>
                No more marketing to build engagement. <br />
                Start marketing to build relationships{' '}
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s4.png" alt="banner-img" />
            </div>
          </div>
        </section>
        <section class="feature-grid">
          <div class="container">
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon4.png" alt="banner-img" />
              </div>
              <h2>Prioritize high value accounts</h2>
              <p>
                Your target customers who are actually engaging deserve some
                prioritized, red-carpet service.
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon2.png" alt="banner-img" />
              </div>
              <h2>Convert the anonymous</h2>
              <p>
                Generate more pipeline by converting your anonymous website
                visitors into known leads, and sales-ready opportunities.
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon3.png" alt="banner-img" />
              </div>
              <h2>Continue the conversation in real-time</h2>
              <p>
                Once you’ve sparked a conversation, you can route it to the
                appropriate SDR or AE in real-time by moving them onto any
                messenger platform you use.{' '}
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon1.png" alt="banner-img" />
              </div>
              <h2>Book qualified meetings</h2>
              <p>
                Give your prospects quick access to your sales team by skipping
                the forms and instantly qualifying and booking meetings right
                from your website.
              </p>
            </div>
          </div>
        </section>
        <section class="para-section-bootom">
          <h6>
            Let the <span>pipeline generation</span> begin!{' '}
          </h6>
          <p>
            Meet your pipeline goals by instantly opening the door for your
            dream prospects to meet with sales, from the moment they land on
            your website.
          </p>
        </section>
      </div>
      <section class="get-started-footer">
        <div class="container">
          <div class="text-box">
            <h2>Let the pipeline generation begin! </h2>
            <p>
              Meet your pipeline goals by instantly opening the door for your
              dream prospects to meet with sales, the moment they land on your
              website.
            </p>
            <CallToAction />
          </div>
          <div class="img img-revel">
            <img src="./assets/img/wrong.png" alt="banner-img" />
          </div>
        </div>
      </section>
    </div>
  </MarketingDiv>
);

const HeroTabletDesktopView = (props) => {
  return (
    <TabletDesktop>
      <HeroContainer>
        <Header />
        {/* <br /> */}
        {renderMarketingPage()}
      </HeroContainer>
    </TabletDesktop>
  );
};

const HeroMobileView = (props) => {
  return (
    <Mobile>
      <HeroContainer>
        <Header />
        {renderMarketingPageMob()}
      </HeroContainer>
    </Mobile>
  );
};

const Hero = () => {
  React.useEffect(() => {
    let script1 = document.createElement('script');
    let inlineScript1 = document.createTextNode(`$('.client-list---mob').slick({
         centerMode: false,
         // centerPadding: '60px',
         slidesToShow: 2,
         arrows: false,
         dots: false,
         responsive: [
            {
               breakpoint: 768,
               settings: {
                  centerMode: false,
                  slidesToShow: 2,
                  autoplay: true,
                  autoplaySpeed: 1500,
                  // centerPadding: '40px',
               }
            },
            {
               breakpoint: 480,
               settings: {
                  autoplay: true,
                  slidesToShow: 2,
                  autoplaySpeed: 1500,
                  centerMode: false,
               }
            }
         ]
      });`);

    script1.appendChild(inlineScript1);
    document.body.appendChild(script1);

    let script2 = document.createElement('script');
    let inlineScript2 = document.createTextNode(`$('.feature-grid---mob').slick({
         slidesToShow: 1,
         arrows: true,
         dots: false,
         responsive: [
            {
               breakpoint: 480,
               settings: {
               }
            }
         ]
      });`);

    script2.appendChild(inlineScript2);
    document.body.appendChild(script2);

    let script3 = document.createElement('script');
    let inlineScript3 = document.createTextNode(`window.sr = ScrollReveal();
      sr.reveal('.img-revel', {
         duration: 1000,
         origin: 'bottom',
         distance: '40px',
         delay: 0,
      });`);

    script3.appendChild(inlineScript3);
    document.body.appendChild(script3);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeroTabletDesktopView />
      <HeroMobileView />
    </>
  );
};

Hero.propTypes = {};

export default Hero;
