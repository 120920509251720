import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import slack from 'resources/img/slack.png';
import slack2x from 'resources/img/slack2x.png';
import salesforce from 'resources/img/salesforce.png';
import salesforce2x from 'resources/img/salesforce2x.png';
import marketo from 'resources/img/marketo.png';
import marketo2x from 'resources/img/marketo2x.png';
import hubspot from 'resources/img/hubspot.png';
import hubspot2x from 'resources/img/hubspot2x.png';



const IntegrationsWrapper = styled.div`
    overflow-y: hidden;
    white-space: nowrap;
`;

const IntegrationAppContainer = styled.div`
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-width: 1000px;
`;

const IntegrationContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 100px 0;
  background-color: white;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 40px 0;
  }
`;
const IntegrationImageWrapper = styled.div`
  margin: ${p => p.margin};
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: filter 0.2s;
  
  &:hover {
    filter: none;
    -webkit-filter: grayscale(0);
  }
`;

const Integration = props => {


    return (
        <Element name="Integration">
            <IntegrationContainer>
                <Waypoint
                    scrollableAncestor={window}
                    topOffset="10%"
                    bottomOffset="35%"
                >
                    <Container>
                      <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center" maxWidth="620px" marginBottom="61px">Smart <span>integrations</span> with the tech you use everyday</Text>
                        <IntegrationsWrapper>
                        <IntegrationAppContainer>
                          <IntegrationImageWrapper margin="0 62.5px 0 auto">
                            <Image height="101px" width="160px" hdSrc={salesforce2x} src={salesforce} />
                          </IntegrationImageWrapper>
                          <IntegrationImageWrapper margin="33px 62.5px">
                            <Image height="35px" width="160px" hdSrc={marketo2x} src={marketo} />
                          </IntegrationImageWrapper>
                          <IntegrationImageWrapper margin="29px 62.5px">
                            <Image height="43px" width="150px" hdSrc={hubspot2x} src={hubspot} />
                          </IntegrationImageWrapper>
                          <IntegrationImageWrapper margin="30.5px auto 30.5px 62.5px">
                            <Image height="40px" width="160px" hdSrc={slack2x} src={slack} />
                          </IntegrationImageWrapper>
                        </IntegrationAppContainer>

                        </IntegrationsWrapper>
                        
                    </Container>
                </Waypoint>
            </IntegrationContainer>
        </Element>
    );
};

Integration.propTypes = {};

export default Integration;
