import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import theme from 'styles/theme';
import insentBotIcon from 'resources/img/insentBotIcon.png';
import insentBotIcon2x from 'resources/img/insentBotIcon2x.png';

const SolutionContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 100px 0;
  margin: auto;
  &&& {
    max-width: 1120px;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 36px 0;
  }
`;

const SolutionLogoContainer = styled.div`
  position: absolute;
  right: 0;
  top: -45px;
`;

const SolutionCardWrapper = styled.div`
  padding: 60px 40px;
  background: ${p => p.theme.palette.background.two + '1A'};
  border-radius: 5px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
     padding: 30px 20px;
  }
`;

const SolutionCard = styled(({ bgColor, ...others }) => <animated.div {...others} />)`
  background-color: ${p => p.bgColor || 'white'};
  padding: ${p => p.padding || '60px 30px 30px'};
  border-radius: 20px;
  position: relative;


  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: ${p => p.padding || '30px 20px'};
  }
`;

const SolutionDivider = styled.div`
  border-bottom: 1px solid ${p => p.theme.palette.text.five};
`;

const Solution = props => {


  return (
    <Element name="Why Insent">
      <SolutionContainer>
        <Waypoint
          scrollableAncestor={window}
          topOffset="10%"
          bottomOffset="35%"
        >
          <Container>
            <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center" maxWidth="700px" marginBottom="61px">
            What makes Insent’s <span>Live Chat</span> the top choice for you?
            </Text>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <SolutionCardWrapper>
                  <SolutionCard >
                    <Text fontSize="24px" fontWeight={700} marginBottom="20px">
                      Regular Live Chat 
                    </Text>
                    <Text fontSize="18px" fontWeight={400} marginBottom="31px" smMarginBottom="10px">
                      Restricted hours of the day 
                    </Text>
                    <SolutionDivider />
                    <Text fontSize="18px" fontWeight={400} marginBottom="31px" smMarginBottom="10px" marginTop="10px">
                      Mostly used for customer support 
                    </Text>
                    <SolutionDivider />
                    <Text fontSize="18px" fontWeight={400} marginTop="10px">
                    Reactive approach to connecting with customers
                    </Text>
                </SolutionCard>
                </SolutionCardWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SolutionCardWrapper>
                <SolutionCard >
                    <SolutionLogoContainer>
                      <Image height="110px" width="110px" hdSrc={insentBotIcon2x} src={insentBotIcon} />
                    </SolutionLogoContainer>
                    <Text fontSize="24px" fontWeight={700} marginBottom="20px">
                      Insent’s Live Chat 
                    </Text>
                    <Text fontSize="18px" fontWeight={400} marginBottom="10px">
                      On-demand, and when the team is out of office, chatbots can book meetings for them.
                    </Text>
                    <SolutionDivider />
                    <Text fontSize="18px" fontWeight={400} marginBottom="10px" marginTop="10px">
                      Used for marketing, sales, and revenue generation 
                    </Text>
                    <SolutionDivider />
                    <Text fontSize="18px" fontWeight={400} marginTop="10px">
                      Proactive approach to connecting with prospects and customers while they’re engaged.
                    </Text>
                </SolutionCard>
                </SolutionCardWrapper>
              </Grid>
            </Grid>
          </Container>
        </Waypoint>
      </SolutionContainer>
    </Element>
  );
};

Solution.propTypes = {};

export default Solution;
