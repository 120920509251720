import React, { useEffect } from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import hubspotHero from 'resources/img/hubspotHero.png';
import hubspotHero2x from 'resources/img/hubspotHero2x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import MWT from './icons/MWT.png';
import VTLCR from './icons/VTLCR.png';
import LTO from './icons/LTO.png';
import WR from './icons/WR.png';
import LOSC from './icons/LOSC.png';
import ACV from './icons/ACV.png';
import Trapezoid from './icons/trapezoid.png';
import './styles.css';
import HubspotReactForm from './HubspotReactForm';


const CalculatorContainer = styled.div`

    max-width: 1294px;
    background: white;
    box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1);
    border-radius: 20px;
    padding: 78px 75px;
    position: relative;
    margin: 0 auto 5rem;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 82px 22px;
    border-radius: 0px;
  }
`;

const CalculatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }
`;

const CalculatorResultContainer = styled.div`
  position: relative;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OverlayEmailContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  background: black;
  z-index: 100;
  top: 46px;
  background: rgba(255, 255, 255, 0.01);
  // backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  padding-top: 134px
  -webkit-animation: blur 1.0s forwards; /* for less modern browsers */
        animation: blur 1.0s forwards;
`;


const OverlayEmailHeader = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 139.3%;
  letter-spacing: -0.035em;
  text-transform: capitalize;
  tex-align: center;
  color: #FFFFFF;
  text-align: center;
`;

const OverlayEmailDesc = styled.p`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 38px;
  max-width: 306px;
  
`;


const CalculatorContentWrapper = styled(animated.div)`
  min-height: 650px;
  display: flex;
  align-items: flex-start;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;
  flex-direction: column;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    justify-content: center;
    margin-bottom: 75px;
    align-items: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.orange.one};
    }
  }
`;


const CalculatorField = styled.div`
  width: 100%;
  max-width: 360px;
  margin-bottom: 41px;
`;

const CalculatorFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
`;

const CalculatorFieldFooter = styled.div`
    display: flex;
    align-items: center;
`;

const CalculatorFieldLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170.3%;
    /* identical to box height, or 24px */

    margin: 0;
    color: #000000;
`;

const CalculatorFieldValue = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170.3%;
    /* identical to box height, or 24px */
    margin: 0;

    color: #000000;
`;

const CalculatorFieldIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 19px;
`;

const CalculatorFieldSlider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;

  background: #171B350A;
    border-radius: 10px;


  &::hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 21px;
    height: 21px;
    border-radius: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:active,&:focus,&:hover {
        background: #0A7EF9;
        border: 1px solid rgba(0, 0, 0, 0.13);
        box-sizing: border-box;
        box-shadow: 0px 0px 9px #0A7EF9;
    }
  }
  
  &::-moz-range-thumb {
    appearance: none;
    width: 21px;
    height: 21px;
    border-radius: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:active,&:focus,&:hover {
        background: #0A7EF9;
        border: 1px solid rgba(0, 0, 0, 0.13);
        box-sizing: border-box;
        box-shadow: 0px 0px 9px #0A7EF9;
    }
  }


`;


const TrapezoidImage = styled.img`
  width: 100%;
  height: 494px;
  max-width: 546px;
  margin: auto;
  display: block;
`;


const FormContainer = styled.form`
  position: relative;
  height: 37px;
`;


const TrapezoidContainer = styled.div`
  position: relative;
`;

const TrapezoidContent = styled.div`
  position: absolute;
  height:68px;
  left: 50%;
  transform: translateX(-50%);
  top: ${p => p.top};
`;

const TrapezoidLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  margin: 0 0 10px;
  min-width: 200px;
`;

const TrapezoidValue = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
`;


function numberWithCommas(number) {

  let decimals = 0
let decpoint = '.' // Or Number(0.1).toLocaleString().substring(1, 2)
let thousand = ',' // Or Number(10000).toLocaleString().substring(2, 3)

let n = Math.abs(number).toFixed(decimals).split('.')
n[0] = n[0].split('').reverse().map((c, i, a) =>
  i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
).reverse().join('')
let final = (Math.sign(number) < 0 ? '-' : '') + n.join(decpoint)
    return final;
}
const Calculator = props => {


    const [MWTValue, setMWTValue] = React.useState(0);
    const [VTLCRValue, setVTLCRValue] = React.useState(0);
    const [LTOValue, setLTOValue] = React.useState(0);
    const [WRValue, setWRValue] = React.useState(0);
    const [LOSCValue, setLOSCValue] = React.useState(0);
    const [ACVValue, setACVValue] = React.useState(0);

    let [shouldShowShimmerEffect, setShouldShowShimmerEffect] = React.useState( "shimmer");

    const [calculatorTabActive, setCalculatorTabActive] = React.useState('minimumROI')

    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

    const [results, setResults] = React.useState({
      minimumROI: {
        leadsCreated: 0,
        pipelineGenerated: 0,
        opportunitiesAccelerated: 0,
        customers: 0,
        missedRevenue: 0,
        salesCycle: 0,
      },
      anticipatedROI: {
        leadsCreated: 1,
        pipelineGenerated: 0,
        opportunitiesAccelerated: 0,
        customers: 0,
        missedRevenue: 0,
        salesCycle: 0,
      },
    })


    useEffect(() => {
      let monthlyVTLCR = MWTValue * VTLCRValue * 0.01;
      let monthlyLTO = monthlyVTLCR * LTOValue * 0.01;
      let monthlyWR = monthlyLTO * WRValue * 0.01;
      let monthlyACV = ACVValue * monthlyWR;
      
      setResults({
        minimumROI: {
          leadsCreated: parseInt((monthlyVTLCR * 1.06) - monthlyVTLCR),
          pipelineGenerated: parseInt(ACVValue * parseInt((monthlyLTO * 1.1) - monthlyLTO)),
          opportunitiesAccelerated: parseInt((monthlyLTO * 1.1) - monthlyLTO),
          customers: ((monthlyWR * 1.05) - monthlyWR).toFixed(2),
          missedRevenue: parseInt(((ACVValue * (monthlyWR * 1.05)) - monthlyACV)),
          salesCycle: parseFloat(LOSCValue * 0.1),
        },
        anticipatedROI: {
          leadsCreated: parseInt((monthlyVTLCR * 1.15) - monthlyVTLCR),
          pipelineGenerated: parseInt(ACVValue * parseInt((monthlyLTO * 1.4) - monthlyLTO)),
          opportunitiesAccelerated: parseInt((monthlyLTO * 1.4) - monthlyLTO),
          customers: ((monthlyWR * 1.15) - monthlyWR).toFixed(2),
          missedRevenue: parseInt(((ACVValue * (monthlyWR * 1.15)) - monthlyACV)),
          salesCycle: parseFloat(LOSCValue * 0.15),
        },
      })
    },[MWTValue,VTLCRValue,LTOValue, WRValue,LOSCValue,ACVValue])

    const renderCalculatorField = ({label, value, icon, min, max, step, formattedValue, setValue}) => {

        return (
            <CalculatorField>
                <CalculatorFieldHeader>
                    <CalculatorFieldLabel>{label}</CalculatorFieldLabel>
                    <CalculatorFieldValue>{formattedValue}</CalculatorFieldValue>
                </CalculatorFieldHeader>
                <CalculatorFieldFooter>
                    <CalculatorFieldIcon src={icon} />
                    <CalculatorFieldSlider type="range" min={min} max={max} step={step} value={value} onChange={(event) => setValue(event.target.value)}/>
                </CalculatorFieldFooter>
            </CalculatorField>
        );
    }


    const roiInputFields=[   
      {
        name: "monthly_web_traffic",   
        value: MWTValue,   
      },
      {
        name: "visitor_to_lead_conversion_rate",   
        value: VTLCRValue,         
      },
      {
        name: "lead_to_opportunity_rate",   
        value: LTOValue,   
      },
      {
        name: "win_rate",   
        value: WRValue,         
      },
      {
        name: "average_customer_value",   
        value: ACVValue,   
      },
    ];

    const renderROIForm = React.useMemo(() => {


    let roiOutputMinFields = [{
      name: "leads_created",   
      value: results.minimumROI.leadsCreated,
    },{
      name: "pipeline_generated",   
      value: results.minimumROI.pipelineGenerated,
    },{
      name: "opportunities_accelerated",   
      value: results.minimumROI.opportunitiesAccelerated,
    },{
      name: "customers",   
      value: results.minimumROI.customers,
    },{
      name: "missed_revenue",   
      value: results.minimumROI.missedRevenue,
    }];

    let roiOutputAntFields = [{
      name: "leads_created",   
      value: results.minimumROI.leadsCreated,
    },{
      name: "pipeline_generated",   
      value: results.minimumROI.pipelineGenerated,
    },{
      name: "opportunities_accelerated",   
      value: results.minimumROI.opportunitiesAccelerated,
    },{
      name: "customers",   
      value: results.minimumROI.customers,
    },{
      name: "missed_revenue",   
      value: results.minimumROI.missedRevenue,
    }];
    

      return (
        <HubspotReactForm
          portalId={5712917}
          roiInputFieldsFormId="ae6cdbfe-1019-4807-b1d5-fa27bbee9d17"  
          roiOutputMinFieldsFormId="ae1093cf-978d-45e6-8bf6-0d8e9b1d8fe5"  
          roiOutputAntFieldsFormId="94a3d9b7-5f67-4836-9e54-7cabddd86d24"  
          isFormSubmitted={isFormSubmitted}
          setIsFormSubmitted={setIsFormSubmitted}
          pageName=""
          pageUri=""
          roiInputFields={roiInputFields}
          roiOutputMinFields={roiOutputMinFields}
          roiOutputAntFields={roiOutputAntFields}
        />
      );
    },[MWTValue,VTLCRValue,LTOValue, WRValue,LOSCValue,ACVValue,results,isFormSubmitted]);

    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <CalculatorContainer>
                <div  ref={props.CalculatorTopRef} style={{position: 'absolute', top: '105px', marginTop: '-105px'}}/>

                <Text maxWidth="600px" fontSize="36px" smFontSize="24px" fontWeight={700} textAlign="center" lineHeight="139.3%">We’ll let the numbers convince you.</Text>
                <Text maxWidth="600px" fontSize="18px" smFontSize="14px" fontWeight={400} textAlign="center" lineHeight="32px" opacity="0.8" marginTop="11px" mdMarginTop="43px" marginBottom="18px">Input your average conversion metrics, and we’ll unveil the pipeline that’s waiting to be generated—instantly.</Text>
                <Text maxWidth="600px" fontSize="12px" marginBottom="52px" mdMarginBottom="62px" fontWeight={400} textAlign="center" lineHeight="131%" opacity="0.5">
                <i>This isn’t a generic ROI calculator, it’s a proposed return-on-investment based on the adoption of chat across your entire revenue organization.</i>
                </Text>

                <CalculatorWrapper>
                <CalculatorContentWrapper>
                                {renderCalculatorField({
                                    label: 'Monthly Web Traffic',
                                    formattedValue: numberWithCommas(MWTValue),
                                    setValue: setMWTValue,
                                    value: MWTValue,
                                    icon: MWT,
                                    min: 0,
                                    max: 1000000,
                                    step: 500
                                })}
                                {renderCalculatorField({
                                    label: 'Visitor to Lead Conversion Rate (%)',
                                    formattedValue: numberWithCommas(VTLCRValue) + '%',
                                    setValue: setVTLCRValue,
                                    value: VTLCRValue,
                                    icon: VTLCR,
                                    min: 0,
                                    max: 100,
                                    step: 1
                                })}
                                {renderCalculatorField({
                                    label: 'Lead to Opportunity (%)',
                                    formattedValue: numberWithCommas(LTOValue) + '%',
                                    setValue: setLTOValue,
                                    value: LTOValue,
                                    icon: LTO,
                                    min: 0,
                                    max: 100,
                                    step: 1
                                })}
                                {renderCalculatorField({
                                    label: 'Win Rate (%)',
                                    formattedValue: numberWithCommas(WRValue) + '%',
                                    setValue: setWRValue,
                                    value: WRValue,
                                    icon: WR,
                                    min: 0,
                                    max: 100,
                                    step: 1
                                })}
                                {renderCalculatorField({
                                    label: 'Length of Sales Cycle (days)',
                                    formattedValue: `${LOSCValue} days`,
                                    setValue: setLOSCValue,
                                    value: LOSCValue,
                                    icon: LOSC,
                                    min: 0,
                                    max: 1000,
                                    step: 5
                                })}
                                {renderCalculatorField({
                                    label: 'Average Customer Value',
                                    formattedValue: `$${numberWithCommas(ACVValue)}`,
                                    setValue: setACVValue,
                                    value: ACVValue,
                                    icon: ACV,
                                    min: 0,
                                    max: 500000,
                                    step: 100
                                })}
                            </CalculatorContentWrapper>
                  <CalculatorResultContainer>
                    {!isFormSubmitted && calculatorTabActive === 'anticipatedROI' && (
                      <OverlayEmailContainer>
                            <div>
                              <OverlayEmailHeader>
                                Please share your email
                              </OverlayEmailHeader>
                              <OverlayEmailDesc>
                                Share your email to view the Anticipated ROI. Also we will share a detailed report as an email.
                              </OverlayEmailDesc>                     
                                {renderROIForm}
                            </div>
                      </OverlayEmailContainer>
                    )}
                              
                  <div class="wrapper">
                                <nav>
                                  <input type="radio" name="tab" id="home" checked={calculatorTabActive === 'minimumROI'} />
                                  <input type="radio" name="tab" id="inbox" checked={calculatorTabActive === 'anticipatedROI'}/>
                                  <label for="home" class="home" onClick={() => setCalculatorTabActive('minimumROI')}>Minimum ROI</label>
                                  <label for="inbox" class={`inbox ${shouldShowShimmerEffect}`} onClick={() => {
                                    setCalculatorTabActive('anticipatedROI');
                                    setShouldShowShimmerEffect("");
                                  }}>Anticipated ROI</label>
                                  <div class="tab">
                                  </div>
                                </nav>
                              </div>
                            <TrapezoidContainer>
                                <TrapezoidContent top="17px">
                                  <TrapezoidLabel>LEADS CREATED</TrapezoidLabel>
                                  <TrapezoidValue>+{results[calculatorTabActive].leadsCreated}</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidContent top="120px">
                                  <TrapezoidLabel>PIPELINE GENERATED</TrapezoidLabel>
                                  <TrapezoidValue>+${numberWithCommas(results[calculatorTabActive].pipelineGenerated)}</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidContent top="238px">
                                  <TrapezoidLabel>OPPORTUNITIES ACCELERATED</TrapezoidLabel>
                                  <TrapezoidValue>+{results[calculatorTabActive].opportunitiesAccelerated}</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidContent top="358px">
                                  <TrapezoidLabel>CUSTOMERS</TrapezoidLabel>
                                  <TrapezoidValue>+{results[calculatorTabActive].customers}</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidImage src={Trapezoid} />
                            </TrapezoidContainer>
                              <Text fontSize="24px" marginBottom="7px"  fontWeight={400} textAlign="center" lineHeight="29px" opacity="0.45">
                                MISSED REVENUE
                              </Text>
                              <Text fontSize="35px" marginBottom="15px"  fontWeight={400} textAlign="center" lineHeight="42px">
                                +${numberWithCommas(results[calculatorTabActive].missedRevenue)}
                              </Text>
                              <Text fontSize="14px" marginBottom="31px"  fontWeight={400} textAlign="center" lineHeight="170.3%" opacity="0.8">
                                Accelerate Sales Cycle by <b>{results[calculatorTabActive].salesCycle} Days</b>
                              </Text>                              
                              {calculatorTabActive === 'minimumROI'  && (
                                renderROIForm
                              )}
                              {calculatorTabActive !== 'minimumROI' && isFormSubmitted  && (
                                renderROIForm
                              )}                                                                                    
                  </CalculatorResultContainer>
                </CalculatorWrapper>
            </CalculatorContainer>
        </Waypoint>
    );
};

Calculator.propTypes = {};

export default Calculator;
