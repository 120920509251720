
import SalesAccelerationDefault from './icons/SalesAccelerationDefault.png';
import MarketingDefault from './icons/MarketingDefault.png';
import ABMDefault from './icons/ABMDefault.png';
import CustomerSuccessDefault from './icons/CustomerSuccessDefault.png';
import DemandGeneration from './icons/DemandGeneration.png';
import DemandGenerationDefault from './icons/DemandGenerationDefault.png';
import SalesAcceleration from './icons/SalesAcceleration.png';
import Marketing from './icons/Marketing.png';
import ABM from './icons/ABM.png';
import CustomerSuccess from './icons/CustomerSuccess.png';

export const menuList = [{
    activeIcon: DemandGeneration,
    defaultIcon: DemandGenerationDefault,
    text: "Demand Generation"
},{
    activeIcon: ABM,
    defaultIcon: ABMDefault,
    text: "ABM"
},{
    activeIcon: Marketing,
    defaultIcon: MarketingDefault,
    text: "Marketing"
},{
    activeIcon: SalesAcceleration,
    defaultIcon: SalesAccelerationDefault,
    text: "Sales Acceleration"
},{
    activeIcon: CustomerSuccess,
    defaultIcon: CustomerSuccessDefault,
    text: "Customer Success"
},];