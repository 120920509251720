


import roiTestimonialMob3x from 'resources/img/roiTestimonialMob3x.png';
import roiTestimonialWeb3x from 'resources/img/roiTestimonialWeb3x.png';
import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import hubspotHero from 'resources/img/hubspotHero.png';
import hubspotHero2x from 'resources/img/hubspotHero2x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Button from 'ui/components/Button';
import { Desktop, MobileTablet } from "ui/components/Media";
import allison from './icons/allison.png';
import quotes from './icons/quotes.png';


const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;
  padding: 8rem 5rem;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column
  background: ${p => p.theme.palette.background.five};
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 0rem 20px 5rem;
    button {
      text-align: center;
    }
  }
`;


const HeroContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      padding-left: 14px;
      padding-right: 14px;
    margin-top: 50px;
    justify-content: center;
    align-items: center;

  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.orange.one};
    }
  }
`;

const BackgroundDesktop = styled.div`
    background-color: white;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    width: 83%;
    height: 260px;
    border-radius: 22px;
    max-width: 1182px;
    -webkit-transform: rotate(90deg) skew(20deg,0deg) scale(0.75);
    -ms-transform: rotate(90deg) skew(20deg,0deg) scale(0.75;transform: skew(0deg,-21deg) translateX(-50%););
    transform: skew(0deg,-5deg) translateX(-50%);
    left: 50%;
    top: 280px;
`;

const BackgroundMobile = styled.div`
background-color: white;
background-size: cover;
background-position: top;
position: absolute;
top: 0;
width: 100%;
height: 100%;
border-radius: 22px;
-webkit-transform: rotate(90deg) skew(20deg,0deg) scale(0.75);
-ms-transform: rotate(90deg) skew(20deg,0deg) scale(0.75;transform: skew(0deg,-21deg) translateX(-50%););
transform: skew(0deg,-15deg) translateX(-50%);
left: 50%;
top: -50px

@media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
  top: 0px;
}
`;


const ReviewsContainer = styled.div`
  margin-top: 249px;
  display:flex;
  height: 251px;


  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;
    margin-top: 210px;
    align-items: center;
    margin-bottom: 80px;
  }

  
`;


const ReviewContentContainer = styled.div`
  padding: 56px;
  position: relative;
  background: white;
  box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1);
  border-radius: 22px;
  display: inline-table;
  position: relative;
  left: -42px;
  top: -30px;
  max-width: 472px;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 42px;
    left: 0;
    top: -90px;
    margin: 20px;
    text-align: center;
  }


  img {
    width: 87px;
    height: 63px;
    position: absolute
    right: 41px;
    bottom: 25px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      top: 25px;
      left: 16px;
    }
  }
`;


const ReviewerImage = styled.img`
  width: 158px;
  height: 158px;
  border-radius: 12px;
  margin: auto;
  margin-bottom: 21px;
`;


const ReviewerInfoContainer = styled.div`
  padding: 0 50px 22px;
  background: rgba(23, 27, 53, 0.04);
  border-radius: 22px;
  height: 203px;
  min-width: 314px;
  display: flex;
  align-items: flex-end;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 42px;
    min-width: 248px;
    padding: 42px 15px 84px;
    max-width: 248px;
    min-height: 229px;
  }

`;


const ReviewerInfoWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: -48px;
  margin: -48px auto 0;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 0;
    
}
  }
`;

const Hero = props => {
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <HeroContainer>
                <Desktop>
                    <BackgroundDesktop />
                </Desktop>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <HeroContentWrapper>
                                <MobileTablet>
                                    <BackgroundMobile/>
                                </MobileTablet>
                                <Text maxWidth="366PX" fontSize="36px" smFontSize="24px" fontWeight={700} mdTextAlign="center" lineHeight="139.3%" marginLeft="0" mdMarginLeft="auto">
                                    <span>Speak to the buyers</span> on your site now, or your competitors will.
                                  </Text>
                                  <Text fontSize="18px" smFontSize="13px" mdTextAlign="center" marginTop="120px" mdMarginTop="43px" marginBottom="32px" smMarginBottom="22px" maxWidth="439px" opacity="0.8" lineHeight="170.3%" marginLeft="66px" mdMarginLeft="auto">
                                    It's easier to create opportunities with your prospects when your sales team is connecting and responding to them faster than the competition.
                                  </Text>
                                  <Button onClick={props.scrollToCalculator} height="50px" fontWeight="600" fontSize="14px" padding="15px 40px" marginLeft="66px" mdMarginLeft="0" mdMarginBottom="64px">Calculate Your ROI</Button>
                            </HeroContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Desktop>
                                <Image position="relative" height="100%" width="100%" maxWidth="504px" maxHeight="503px" marginLeft="auto" marginRight="auto" marginTop="60px" mdPaddingBottom="60px" display="flex" hdSrc={roiTestimonialWeb3x} src={roiTestimonialWeb3x} />
                            </Desktop>
                          
                          <MobileTablet>
                            <Image position="relative" height="100%" width="100%" maxWidth="400px" maxHeight="403px" marginLeft="auto" marginRight="auto" display="flex" hdSrc={roiTestimonialMob3x} src={roiTestimonialMob3x} />
                          </MobileTablet>
                          
                        </Grid>
                    </Grid>
                </Container>


                <ReviewsContainer>
                  <ReviewerInfoContainer>
                    <ReviewerInfoWrapper>
                      <ReviewerImage src={allison} />
                      <Text color="#131830" fontSize="18px" fontWeight={400} lineHeight="26px">ALLISON</Text>
                      <Text   color="rgba(0, 0, 0, 0.8)" fontSize="14px" fontWeight={400} lineHeight="176%" opacity="0.7">Director of Revenue at Sortable</Text>
                    </ReviewerInfoWrapper>
                  </ReviewerInfoContainer>
                  <ReviewContentContainer>
                    <Text fontSize="18px" fontWeight={400} lineHeight="176%" opacity="0.8">
                      <i>Insent is impacting 50% of our opportunties in a month since implementation</i>
                    </Text>

                    <img src={quotes} />
                  </ReviewContentContainer>
                </ReviewsContainer>

            </HeroContainer>
        </Waypoint>
    );
};

Hero.propTypes = {};

export default Hero;
