import React from 'react';
import Hero from './Hero';
import Action from './Action';
import Testimonial from './Testimonial';
import Calculator from './Calculator';
import Footer from '../Home/Footer';
import styled from 'styled-components';
import heroCurve from './icons/heroCurve.png';
import actionCurve from './icons/actionCurve.png';

const Container = styled.div`
  background: ${p => p.theme.palette.background.five};
  position: relative;
`;

const HeroSectionCurve = styled.img`
  position: absolute;
  top: 0;
  left:0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    left: -15%;
    height: 130px;
  }
  
`;

const ActionSectionCurve = styled.img`
    position: absolute;
    top: 2700px;
    left: 0;
    opacity: 1;
    z-index: 10;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    display: none;
  }
  
`;



const ROI = props => {

  const CalculatorTopRef = React.useRef(null);

  const scrollToCalculator = () => {
    console.log("")
    if (CalculatorTopRef && CalculatorTopRef.current) {
      CalculatorTopRef.current.scrollIntoView();
    }
  };

  return (
      <>
    <Container>
      <HeroSectionCurve src={heroCurve} />
      <ActionSectionCurve src={actionCurve} />
      <Hero scrollToCalculator={scrollToCalculator}/>
      <Calculator CalculatorTopRef={CalculatorTopRef}/>
      <Testimonial scrollToCalculator={scrollToCalculator}/>
      <Action />
      
    </Container>
    <Footer />
    </>
  );
};

ROI.propTypes = {};

export default ROI;
