import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { transparentize } from 'polished';
import Image from 'ui/components/Image';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import sfFormula from 'resources/img/sfFormula.png';
import sfTimeline from 'resources/img/sfTimeline.png';
import sfWidget from 'resources/img/sfWidget.png';
import salesforceEngageHeader from 'resources/img/salesforceEngageHeader.png';

const EngageContainer = styled.div`
  overflow: hidden;
  padding: 70px 0 110px;
  background: ${p => p.theme.palette.blue.one};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 60px 0;
    button {
      text-align: center;
    }
  }
`;

const EngageWrppaer = styled.div`
  margin: auto;
  align-items: center;
  padding: 0 20px;
  position: relative;
  &&& {
    max-width: 932px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    justify-content: center;
    flex-direction: column;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {

    padding-bottom: 180px;
  }
`;

const EngageContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 2rem;
  &&& {
    ${p => p.paddingRight && `padding-right:${p.paddingRight}`}
    ${p => p.paddingLeft && `padding-left:${p.paddingLeft}`}
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
      &&& {
        padding: 0
      }
    }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    &&& {
      padding: 0
    }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    &&& {
      padding: 0;
    }

    div > div {
      justify-content: left;
    }
  }
`;

const ColorText = styled.span`
  &&& {
      color: ${p => p.color}
  }
`;

const EngageAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 624px;
`;

const EngageHeader = styled.img`
  display: block;
  max-width: 270px;
  max-height: 83px;
  margin: auto;
  margin-bottom: 60px; 

`;


const EngagePopup = styled.img`
  max-width: 418px;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    bottom: -40px;

  }

@media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    bottom: -60px;

}

@media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
`;

const Engage = props => {
    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            <EngageContainer>
                
                <EngageWrppaer>
                    <EngageHeader src={salesforceEngageHeader} />
                    <Text fontSize="40px" fontWeight={700} maxWidth="706px" textAlign="center" marginTop="20px" smMarginTop="30px" marginBottom="20px">
                        No more generic conversation starters to <span>engage buyers</span> on site
                    </Text>
                    <Text fontSize="18px" color="#757575" maxW textAlign="center" marginBottom="32px" smMarginBottom="10px">
                        You can leverage the power of Insent’s integration with Salesforce to meet your most engaged accounts…
                    </Text>
                    <Image src={sfFormula} hdSrc={sfFormula} width="100%" maxWidth="932px" height="100%" />
                    <Text fontSize="18px" fontWeight={7000} textAlign="center" margintTop="30px" marginBottom="30px">
                        And unlock relevant conversations...
                    </Text>
                    <Image src={sfTimeline} hdSrc={sfTimeline} width="100%" maxWidth="932px" height="100%" />
                    <EngagePopup src={sfWidget} />

                </EngageWrppaer>
                
            </EngageContainer>
        </Waypoint>
    );
};

Engage.propTypes = {};

export default Engage;
