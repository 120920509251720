import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Header from '../Home/Header';

import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import useCaseHero3x from 'resources/img/useCaseHero3x.png';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 550px;
  display: block;
  margin: auto;
  
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    max-width: 500px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 2rem 0;
    max-width: 475px;
  }
`;
const HeroBg = styled.img`
  position: absolute;
      right: -75%;
    top: -25%;
    width: 200%;
    height: 125%;
    z-index: -1;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    right: -100%;
    top: -50%;
    width: 200%;
    height: 150%;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;
  padding: 0 ;
  position: relative;
  min-height: 100vh;
  align-items: center;
  display: flex;
  background: ${p => p.theme.palette.blue.one};
  z-index: 10;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    /* padding: 1rem 0; */

    button {
      text-align: center;
    }
  }
`;

const HeroPageName = styled.p`
  color: #82B5A5;
  margin-bottom: 0;
`;

const HeroContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    height: 100%;
    padding: 0 0 0 0;
    
    width: 100%;
    max-width: 550px;
    margin: auto;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 10rem 0 0;

  }
`;

const HeroHeader = styled.h2`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 12px;  
  max-width: 500px;
  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }
   @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
    max-width: unset;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.xs}px) {
    margin-top: 1rem;
  }
`;

const HeroDesc = styled.h2`
  max-width: 485px;
  font-family: ProductSans;
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  padding-bottom: 36px;  
  font-weight: normal;
`;

const HeroIllustration = styled.div`
  position: relative;
  margin-top: 24px;
  z-index: 1;
  display: flex;
  justify-content: center;
`;

const HeroBurgerImage = styled.img`
  position: absolute;
  right: -50%;
  bottom: -50%;
  z-index: 1;
  max-height: 284px;
  max-width: 223px; 
`;

const Background = styled.div`
  clip-path: polygon(75vw 0, 100% 0, 100% 100%, 50vw 100%);
  height: 95vh;
  background-image: linear-gradient(to right bottom, rgba(10, 126, 249, 0.12), rgba(10, 126, 249, 0.12)),url(../img/hero-small.jpg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Hero = () => {



  const calcMousePointer = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`


  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }));
  return (

    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
    >

      <HeroContainer onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calcMousePointer(x, y) })}>
        <Background />
        <Header />
        <Container >
          <Grid container>
            <Grid item xs={12} md={6}>
              <HeroContentWrapper >
                <div>
                  <Text fontSize="40px" smFontSize="23px" fontWeight={700} mdTextAlign="center" marginBottom="36px" marginLeft="0px" maxWidth="538px">
                    All the Ways You Can Use Insent to Power Your <br/>
                    <span>Demand Gen, ABM, and Sales Acceleration Strategies</span>
                  </Text>
                  <CallToAction justify="left" mdJustify="center" />
                </div>
              </HeroContentWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <HeroIllustration style={{ transform: props.xy.interpolate(trans1) }} >
                
                <Image src={useCaseHero3x} hdSrc={useCaseHero3x} height="100%" width="100%" zIndex="2" maxWidth="486px" smWidth="300px"/>

              </HeroIllustration>
            </Grid>
          </Grid>
        </Container>

      </HeroContainer>
    </Waypoint>
  );
};

Hero.propTypes = {};

export default Hero;
