import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import SalesPitchImageOne from 'resources/img/whySales1.png';
import SalesPitchImageTwo from 'resources/img/whySales2.png';
import Text from 'ui/components/Text';


const GridCenter = styled(Grid)`
  justify-content: center;
`;


const ScheduleContainer = styled.div`
  overflow: hidden;
  padding: 40px 0;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 0 0;

    button {
      text-align: center;
    }
  }
`;

const ScheduleContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 319px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    height: 100%;
    padding: 0 0 0 0;

  }
`;

const ScheduleHeader = styled.h2`
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 1rem;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    text-align: center;
    max-width: unset;
    padding-top: 1rem;
  }
`;

const ScheduleDesc = styled.h2`
  max-width: 421px;
  font-family: ProductSans;
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  padding-bottom: 31px;
  font-weight: normal;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    text-align: center;
  }
`;

const ScheduleAnimatedImgOne = styled(animated.img)`
    width: 100%;
    max-width: 212px;
    margin: 1.2rem 0 ;
`;

const ScheduleAnimatedImgTwo = styled(animated.img)`
    width: 100%;
    max-width: 212px;
    margin: 1.7rem 0 ;
`;

const SalesPitchContainer = styled(animated.div)`
    margin: 5rem;
    text-align: center;
    vertical-align: middle;
    background: ${p => p.theme.palette.blue.one};
    padding: 2rem;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      margin: 2rem;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      margin: 0;
      padding: 2rem 2rem 100px;
    }
`;

const SectionHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  margin: 0 auto;
  padding-bottom: 12px;
  max-width: 750px;
  text-align: center;

  span {
    color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 24px;
    padding-bottom: 8px;
  }
`;

const SectionDescription = styled.p`
  font-size: 18px;
  line-height: 1.22;
  color: #757575;
  max-width: 675px;
  text-align: center; 
  padding-bottom: 36px;
  margin: auto auto 0;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;

const SectionImage = styled(animated.img)`
  max-width: 541px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 425px;

  height: 319px;
  width: 565px;
  

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    background-size: cover;
    padding: 2rem 0;
    width: calc(414px - 2rem);
    height: 266px;
  }
`;

const AlignCenter = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
`;

const SalesPitch = props => {

  return (
    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
    >

      <SalesPitchContainer >
        <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center">
          Give your sales team what they want most:
        </Text>

        <ScheduleContainer>
          <Container>
            <GridCenter container>
              <Grid item sm={6}>
                <ScheduleContentWrapper>
                  <AlignCenter>
                    <ScheduleAnimatedImgOne src={SalesPitchImageOne} alt="schedule" />
                    <Text fontSize="18px" textAlign="center">
                      A way to prioritize and connect with their most sales-ready prospects
                    </Text>
                  </AlignCenter>
                </ScheduleContentWrapper>
              </Grid>
              <Grid item sm={6}>
                <ScheduleContentWrapper>
                  <AlignCenter>
                    <ScheduleAnimatedImgTwo src={SalesPitchImageTwo} alt="schedule" />
                    <Text fontSize="18px" textAlign="center">
                      The chance to book meetings and talk to those exact people
                    </Text>
                  </AlignCenter>
                </ScheduleContentWrapper>
              </Grid>
            </GridCenter>
          </Container>
        </ScheduleContainer>
      </SalesPitchContainer>
    </Waypoint>
  );
};

SalesPitch.propTypes = {};

export default SalesPitch;