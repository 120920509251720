import { createGlobalStyle } from "styled-components";
import ProductSansRegular from "resources/font/ProductSansRegular.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
  src: url(${ProductSansRegular}); /* IE9 Compat Modes */
  font-family: 'ProductSans';
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('resources/font/SharpSans-medium.otf');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('resources/font/SharpSans-semi-bold.otf');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sharp Sans';
  src: url('resources/font/SharpSans-bold.otf');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/* 
* {
  border: 1px solid #f00 !important;
} */
  html,
  body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'ProductSans', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
  }

  .hs_error_rollup {
    display: none !important;
  }
`;

export default GlobalStyle;
