import React from 'react';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import Header from '../Home/Header';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';

const HeroContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const MarketingDiv = styled.div`
  margin-top: 0px;

  .marketing__banner {
    padding-top: 200px;
    background: #f7faff;
  }
`;

const renderABMPageMob = () => (
  <MarketingDiv>
    <div class="marketing">
      <section class="marketing__banner">
        <div class="container">
          <div class="text-col">
            <h1>Your target account is on your website right now </h1>
            <p>
              Insent sends you instant alerts that help you
              <br /> connect with VIP prospects the moment they land on your
              website.
            </p>
            <CallToAction />
          </div>
          <div class="img-col img-revel">
            <img src="./assets/img/abmHero.png" alt="banner-img" />
          </div>
        </div>
      </section>
      <div class="lines-set__1">
        <section class="marketing__client">
          <div class="container">
            <div class="testimonal testimonal---marketing">
              <div class="img-wrapper">
                <img src="./assets/img/p1.jpeg" alt="banner-img" />
              </div>
              <div class="content">
                <div class="logo">
                  <img
                    src="./assets/img/TouchPointsLogo.jpg"
                    alt="banner-img"
                  />
                </div>
                <p>
                  If my target account is on my website, i need to be there too,
                  and ready to help
                </p>
                <h6>Matt LoPresti</h6>
                <h5>Founder, TouchPoints</h5>
              </div>
            </div>
            <div class="list">
              <h2>
                Close more of your dream <span>customers</span> with <br />
                real conversations
              </h2>
              <a href="#" class="ghost-btn">
                Meet our customers
              </a>
              <div class="client-list center">
                <img src="./assets/img/TouchPointsLogo.jpg" alt="banner-img" />
                <img
                  src="./assets/img/purplepatch-services_20170804230450.jpeg"
                  alt="banner-img"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="para-section">
          <p>
            You worked hard to build <sapn>account-based marketing</sapn>{' '}
            programs that will attract your <span>ideal buyers</span>.
          </p>
          <p>
            So give your sales team what they want most: <br />
            <span>Conversations</span> and meetings with those exact people.
          </p>
        </section>
        <section class="two-col-block two-col-block---img-left">
          <div class="container">
            <div class="img-wrapper img-revel">
              <img src="./assets/img/abm1.png" alt="banner-img" />
            </div>
            <div class="text-wrapper">
              <h2>Pave a fastlane to your reps</h2>
              <p>
                These are your target, dream customers. You don’t want bots to
                be the face of your business, especially when your people can
                start building and owning those long-term relationships today.
              </p>
              <p>
                Insent will notify your SDRs/AEs when someone from your target
                account is on your website.
              </p>
            </div>
          </div>
        </section>
        <section class="two-col-block two-col-block---img-right">
          <div class="container">
            <div class="text-wrapper">
              <h2>Fuel sales conversations with full account view</h2>
              <p>
                With one centralized account view of data from your CRM,
                marketing automation platform, and our messaging platform,
                Insent helps make account-based sales more personalized than
                ever before.
              </p>
              <p> Start turning those MQLs/MQAs into buyers, today.</p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/abm2.png" alt="banner-img" />
            </div>
          </div>
        </section>
      </div>
      <section class="two-col-block two-col-block---img-left">
        <div class="container">
          <div class="img-wrapper img-revel">
            <img src="./assets/img/abm3.png" alt="banner-img" />
          </div>
          <div class="text-wrapper">
            <h2>Give your accounts VIP service</h2>
            <p>
              The most valuable buyers, the ones you’ve identified as target
              accounts and MQLs/MQAs, need that one-on-one, highly personalized
              buyer experience your competitor isn’t providing.{' '}
            </p>
            <p>
              Insent helps you show them what it means to be taken care of.
              Whether that is immediate access to your team, ungated content, or
              line bypass when they’re ready to talk to sales.
            </p>
          </div>
        </div>
      </section>
      <div class="lines-set__2">
        <section class="two-col-block two-col-block---last-row">
          <div class="container">
            <div class="text-wrapper">
              <h2>
                Insent was built to make your marketing <br />
                team <span> work effortlessly</span> with sales
              </h2>
              <p>
                No more marketing to build engagement. <br />
                Start marketing to build relationships{' '}
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/abm4.png" alt="banner-img" />
            </div>
          </div>
        </section>
        <section class="feature-grid---mob">
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon4.png" alt="banner-img" />
            </div>
            <h2>The ABM layer to your marketing automation & CRM</h2>
            <p>
              You don’t need an expensive platform to be really good at booking
              meetings with your target accounts.
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon2.png" alt="banner-img" />
            </div>
            <h2>Lead with humans, not bots</h2>
            <p>
              Stand out from your competition by leading your conversations with
              real people, not automated bots.
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon3.png" alt="banner-img" />
            </div>
            <h2>Continue the conversation in real-time</h2>
            <p>
              If your SDR or AE was communicating with a target prospect via
              email, move the conversation into real-time the moment they visit
              your website.{' '}
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon1.png" alt="banner-img" />
            </div>
            <h2>
              Discover the next level of account-based sales and marketing
            </h2>
            <p>
              As more members of your accounts’ buying committee visit your
              website, we identify and connect you to them right away.
            </p>
          </div>
        </section>
        <section class="para-section-bootom">
          <h6>
            Insent for <span>ABM</span>
          </h6>
          <p>
            It’s the humanized approach to account-based marketing that gets 5x
            responses.
          </p>
        </section>
      </div>
      <section class="get-started-footer">
        <div class="container">
          <div class="text-box">
            <h2>Get Started With Insent </h2>
            <p>
              Schedule a time with our team to learn how you can <br />
              start building relationships with your prospects today.
            </p>
            <CallToAction />
          </div>
          <div class="img img-revel">
            <img src="./assets/img/wrong.png" alt="banner-img" />
          </div>
        </div>
      </section>
    </div>
  </MarketingDiv>
);

const renderABMPage = () => (
  <MarketingDiv>
    <div class="marketing">
      <section class="marketing__banner">
        <div class="container">
          <div class="text-col">
            <h1>Your target account is on your website right now </h1>
            <p>
              Insent sends you instant alerts that help you
              <br />
              connect with VIP prospects the moment they land on your website.
            </p>
            <CallToAction />
          </div>
          <div class="img-col img-revel">
            <img
              src="./assets/img/abmHero.png"
              alt="banner-img"
              alt="banner-img"
            />
          </div>
        </div>
      </section>
      <div class="lines-set__1">
        <section class="marketing__client">
          <div class="container">
            <div class="testimonal testimonal---marketing">
              <div class="img-wrapper">
                <img src="./assets/img/p1.jpeg" alt="banner-img" />
              </div>
              <div class="content">
                <div class="logo">
                  <img
                    src="./assets/img/TouchPointsLogo.jpg"
                    alt="banner-img"
                  />
                </div>
                <p>
                  If my target account is on my website, i need to be there too,
                  and ready to help
                </p>
                <h6>Matt LoPresti</h6>
                <h5>Founder, TouchPoints</h5>
              </div>
            </div>
            <div class="list">
              <h2>
                Close more of your dream <span>customers</span> with <br />
                real conversations
              </h2>
              <a href="#" class="ghost-btn">
                Meet our customers
              </a>
              <div class="client-list center">
                <img src="./assets/img/TouchPointsLogo.jpg" alt="banner-img" />
                <img
                  src="./assets/img/purplepatch-services_20170804230450.jpeg"
                  alt="banner-img"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="para-section">
          <p>
            You worked hard to build <sapn>account-based marketing</sapn>{' '}
            programs that will attract your <span>ideal buyers</span>.
          </p>
          <p>
            So give your sales team what they want most: <br />
            <span>Conversations</span> and meetings with those exact people.
          </p>
        </section>
        <section class="two-col-block two-col-block---img-left">
          <div class="container">
            <div class="img-wrapper img-revel">
              <img src="./assets/img/abm1.png" alt="banner-img" />
            </div>
            <div class="text-wrapper">
              <h2>Pave a fastlane to your reps</h2>
              <p>
                These are your target, dream customers. You don’t want bots to
                be the face of your business, especially when your people can
                start building and owning those long-term relationships today.
              </p>
              <p>
                Insent will notify your SDRs/AEs when someone from your target
                account is on your website.
              </p>
            </div>
          </div>
        </section>
        <section class="two-col-block two-col-block---img-right">
          <div class="container">
            <div class="text-wrapper">
              <h2>Fuel sales conversations with full account view</h2>
              <p>
                With one centralized account view of data from your CRM,
                marketing automation platform, and our messaging platform,
                Insent helps make account-based sales more personalized than
                ever before.
              </p>
              <p> Start turning those MQLs/MQAs into buyers, today.</p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/abm2.png" alt="banner-img" />
            </div>
          </div>
        </section>
      </div>
      <section class="two-col-block two-col-block---img-left">
        <div class="container">
          <div class="img-wrapper img-revel">
            <img src="./assets/img/abm3.png" alt="banner-img" />
          </div>
          <div class="text-wrapper">
            <h2>Give your accounts VIP service</h2>
            <p>
              The most valuable buyers, the ones you’ve identified as target
              accounts and MQLs/MQAs, need that one-on-one, highly personalized
              buyer experience your competitor isn’t providing.{' '}
            </p>
            <p>
              Insent helps you show them what it means to be taken care of.
              Whether that is immediate access to your team, ungated content, or
              line bypass when they’re ready to talk to sales.
            </p>
          </div>
        </div>
      </section>
      <div class="lines-set__2">
        <section class="two-col-block two-col-block---last-row">
          <div class="container">
            <div class="text-wrapper">
              <h2>
                Insent was built to make your marketing <br />
                team <span> work effortlessly</span> with sales
              </h2>
              <p>
                No more marketing to build engagement. <br />
                Start marketing to build relationships{' '}
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/abm4.png" alt="banner-img" />
            </div>
          </div>
        </section>
        <section class="feature-grid">
          <div class="container">
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon4.png" alt="banner-img" />
              </div>
              <h2>The ABM layer to your marketing automation & CRM</h2>
              <p>
                You don’t need an expensive platform to be really good at
                booking meetings with your target accounts.
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon2.png" alt="banner-img" />
              </div>
              <h2>Lead with humans, not bots</h2>
              <p>
                Stand out from your competition by leading your conversations
                with real people, not automated bots.
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon3.png" alt="banner-img" />
              </div>
              <h2>Continue the conversation in real-time</h2>
              <p>
                If your SDR or AE was communicating with a target prospect via
                email, move the conversation into real-time the moment they
                visit your website.{' '}
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon1.png" alt="banner-img" />
              </div>
              <h2>
                Discover the next level of account-based sales and marketing
              </h2>
              <p>
                As more members of your accounts’ buying committee visit your
                website, we identify and connect you to them right away.
              </p>
            </div>
          </div>
        </section>
        <section class="para-section-bootom">
          <h6>
            Insent for <span>ABM</span>
          </h6>
          <p>
            It’s the humanized approach to account-based marketing that gets 5x
            responses.
          </p>
        </section>
      </div>
      <section class="get-started-footer">
        <div class="container">
          <div class="text-box">
            <h2>Get Started With Insent </h2>
            <p>
              Schedule a time with our team to learn how you can <br />
              start building relationships with your prospects today.
            </p>
            <CallToAction />
          </div>
          <div class="img img-revel">
            <img src="./assets/img/wrong.png" alt="banner-img" />
          </div>
        </div>
      </section>
    </div>
  </MarketingDiv>
);

const HeroTabletDesktopView = (props) => {
  return (
    <TabletDesktop>
      <HeroContainer>
        <Header />
        {renderABMPage()}
      </HeroContainer>
    </TabletDesktop>
  );
};

const HeroMobileView = (props) => {
  return (
    <Mobile>
      <HeroContainer>
        <Header />
        {renderABMPageMob()}
      </HeroContainer>
    </Mobile>
  );
};

const Hero = () => {
  React.useEffect(() => {
    let script1 = document.createElement('script');
    let inlineScript1 = document.createTextNode(`$('.client-list---mob').slick({
         centerMode: false,
         // centerPadding: '60px',
         slidesToShow: 2,
         arrows: false,
         dots: false,
         responsive: [
            {
               breakpoint: 768,
               settings: {
                  centerMode: false,
                  slidesToShow: 2,
                  autoplay: true,
                  autoplaySpeed: 1500,
                  // centerPadding: '40px',
               }
            },
            {
               breakpoint: 480,
               settings: {
                  autoplay: true,
                  slidesToShow: 2,
                  autoplaySpeed: 1500,
                  centerMode: false,
               }
            }
         ]
      });`);

    script1.appendChild(inlineScript1);
    document.body.appendChild(script1);

    let script2 = document.createElement('script');
    let inlineScript2 = document.createTextNode(`$('.feature-grid---mob').slick({
         slidesToShow: 1,
         arrows: true,
         dots: false,
         responsive: [
            {
               breakpoint: 480,
               settings: {
               }
            }
         ]
      });`);

    script2.appendChild(inlineScript2);
    document.body.appendChild(script2);

    let script3 = document.createElement('script');
    let inlineScript3 = document.createTextNode(`window.sr = ScrollReveal();
      sr.reveal('.img-revel', {
         duration: 1000,
         origin: 'bottom',
         distance: '40px',
         delay: 0,
      });`);

    script3.appendChild(inlineScript3);
    document.body.appendChild(script3);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeroTabletDesktopView />
      <HeroMobileView />
    </>
  );
};

Hero.propTypes = {};

export default Hero;
