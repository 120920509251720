import React, { Suspense, lazy }  from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalStyle from 'styles';

import Home from './Home';
import Marketing from './Marketing';
import Sales from './Sales';
import PrivacyPolicy from './PrivacyPolicy';
import MasterAgreement from './MasterAgreement';
import Terms from './Terms';
import GDPR from './GDPR';
import ABM from './ABM';
import QualifyEngage from './QualifyEngage';
import ConverseContext from './ConverseContext';
import WhyHumanFirst from './WhyHumanFirst';
import Login from './Login';
import MSTeamsConfig from './MSTeamsConfig';
import Marketo from './Marketo';
import Hubspot from './Hubspot';
import Salesforce from './Salesforce';
import LiveChat from './LiveChat';
import MarketingUseCase from './MarketingUseCase';
import ROI from './ROI';
import Pricing from './Pricing';

const App = props => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/signin" component={Login} />
          <Route exact path="/ms-teams-config" component={MSTeamsConfig} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-service" component={Terms} />
          <Route exact path="/sandbox-home" component={Home} />
          <Route exact path="/sandbox-sales" component={Sales} />
          <Route path="*" component={() => {
            window.location.replace("https://www.zoominfo.com/chat")
          }} />
        </Switch>
      </BrowserRouter>
      <GlobalStyle />
    </div>
  );
};

App.propTypes = {};

export default App;
