import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Button from 'ui/components/Button';

import salesforce from './img/salesforce.png';
import slack from './img/slack.png';
import marketo from './img/marketo.png';
import msteams from './img/msteams.png';
import hubspot from './img/hubspot.png';
import pardot from './img/pardot.png';
import outreach from './img/outreach.png';
import segment from './img/segment.png';
import AnimationInput from './AnimationInput';

const WhyHeader = styled.div`
    margin: auto;
  max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 71px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        justify-content: center;
        margin-bottom: 61px;
        flex-direction: column;
        button {
          text-align: center;
        }
      }

`;


const WhyFooter = styled.div`
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        margin-bottom: 118px;
      }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        flex-direction: column;
      }

`;
const WhyCard = styled.div`
    background: #FFFFFF;
    border-radius: 20px;
    padding: 33px 18px 24px 30px;
    height: 100%;
    max-width: 360px;
    min-height: 387px
    margin-bottom: 41px;
    flex: 1;
    min-width: 354px;
    &:not(:last-child) {
        margin-right: 12px;
    }


    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        min-width: unset;
        margin-right: 22px;
        margin-left: 22px;
        min-height: unset;
        padding: 33px 18px 36px 30px;
        &:not(:last-child) {
            margin-right: 22px;
        }
      }
`;


const WhyPointWrapper = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const WhyPointBullet = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #0A7EF9;
    margin-right: 20px;
`;


const WhyContainer = styled.div`
  overflow: hidden;
  padding: 0 ;

  background: ${p => p.theme.palette.background.five};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding:  0;

    button {
      text-align: center;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Why = props => {

    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >

            <WhyContainer>

                <WhyHeader>
                    <Text smMarginRight="23px" smMarginLeft="23px" marginBottom="37px" maxWidth="513px" fontWeight={700} textAlign="left" margin="0" fontSize="36px" smFontSize="24px" mdTextAlign="center" lineHeight="139.3%" >
                        Don't pay more for what we believe are table stakes for a fully integrated chat platform
                    </Text>
                    <AnimationInput 
                        portalId={5712917}
                        formId="d57dd9fa-56d7-40d1-9d88-48df13e6604a"  
                        isFormSubmitted={isFormSubmitted}
                        setIsFormSubmitted={setIsFormSubmitted}
                        pageName=""
                        pageUri=""
                        margin="0"
                    />
                </WhyHeader>

                <WhyFooter>
                    <WhyCard>
                        <Text marginBottom="37px" maxWidth="244px" fontWeight={700} textAlign="left" margin="0" fontSize="24px" lineHeight="150%"  >
                            Built to grow with you at the speed you expect
                        </Text>

                        <WhyPointWrapper>
                            <WhyPointBullet />
                            <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                                Out-of-the-Box Conversation Templates Ready-to-Use
                            </Text>
                        </WhyPointWrapper>
                        <WhyPointWrapper>
                            <WhyPointBullet />
                            <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                                100% Customizable Lead Qualification & Conversation Workflows
                            </Text>
                        </WhyPointWrapper>
                    </WhyCard>
                    <WhyCard>
                        <Text marginBottom="37px" maxWidth="244px" fontWeight={700} textAlign="left" margin="0" fontSize="24px" lineHeight="150%"  >
                            Integrate chat with your entire revenue tech stack
                        </Text>
                        <LogoContainer>
                            <Image marginRight="20px" marginBottom="38px" hdSrc={salesforce} src={salesforce} height="46px" width="65px" />
                            <Image marginRight="20px" marginBottom="38px" hdSrc={slack} src={slack} height="17px" width="70px" />
                            <Image marginRight="20px" marginBottom="38px" hdSrc={marketo} src={marketo} height="18px" width="77px" />
                            <Image marginRight="20px" marginBottom="38px" hdSrc={msteams} src={msteams} height="28px" width="105px" />
                            <Image marginRight="20px" marginBottom="38px" hdSrc={hubspot} src={hubspot} height="18px" width="62px" />
                            <Image marginRight="20px" marginBottom="38px" hdSrc={pardot} src={pardot} height="17px" width="57px" />
                            <Image marginRight="20px" hdSrc={outreach} src={outreach} height="17px" width="90px" />
                            <Image marginRight="20px" hdSrc={segment} src={segment} height="16px" width="75px" />
                        </LogoContainer>
                    </WhyCard>
                    <WhyCard>
                        <Text marginBottom="37px" maxWidth="244px" fontWeight={700} textAlign="left" margin="0" fontSize="24px" lineHeight="150%"  >
                            Comes with unrivaled customer training and support
                        </Text>
                        <WhyPointWrapper>
                            <WhyPointBullet />
                            <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                                Live Chat Support (Business Hours)
                            </Text>
                        </WhyPointWrapper>
                        <WhyPointWrapper>
                            <WhyPointBullet />
                            <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                                Training Experts
                            </Text>
                        </WhyPointWrapper>
                        <WhyPointWrapper>
                            <WhyPointBullet />
                            <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                                SDR Training Resources
                            </Text>
                        </WhyPointWrapper>
                        <WhyPointWrapper>
                            <WhyPointBullet />
                            <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                                Success Plan
                            </Text>
                        </WhyPointWrapper>
                        <WhyPointWrapper>
                            <WhyPointBullet />
                            <Text fontWeight={400} margin="0" color="black" opacity="0.8" fontSize="14px" lineHeight="150%"  >
                                Proactive Recommendations for Optimization
                            </Text>
                        </WhyPointWrapper>
                    </WhyCard>
                </WhyFooter>
            </WhyContainer>
        </Waypoint>
    );
};

Why.propTypes = {};

export default Why;
