import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { transparentize } from 'polished';
import CallToAction from 'ui/components/CallToAction';
import Text from 'ui/components/Text';
import ScheduleImg from 'resources/img/schedule.png';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const ScheduleContainer = styled.div`
  overflow: hidden;
  padding: 70px 2rem 70px;
  background-color: ${transparentize(0.9, '#9fbfff')};

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 24px 1rem 70px;
  }
`;

const ScheduleContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    display: block;
    height: 100%;
    padding: 0 0 0 0;
    width: 100%;
    max-width: 550px;
    margin: auto;
  }
`;

const ScheduleAnimatedImg = styled(animated.img)`

  width: 100%;
  max-width: 565px;
  display: block;
  margin: auto;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 60px;
  }
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    max-width: 475px;
  }
`;



const Schedule = props => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);

  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });

  return (
    <Waypoint
      scrollableAncestor={window}
      topOffset="10%"
      bottomOffset="35%"
      onEnter={() => setContentIsVisible(true)}
      onLeave={() => setContentIsVisible(false)}
    >
      <ScheduleContainer>
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ScheduleContentWrapper style={leftCardStyleProps}>
                <div>
                  <Text fontSize="25px" fontWeight={700} textAlign="left" mdTextAlign="center">
                    Get started with Insent
                  </Text>
                  <Text fontSize="18px" textAlign="left" mdTextAlign="center" marginTop="14px" marginBottom="30px">
                    Schedule a time with our team to learn how you can start building relationships with your prospects today.
                  </Text>
                  <CallToAction justify="left" mdJustify="center"/>
                </div>
              </ScheduleContentWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <ScheduleAnimatedImg style={rightCardStyleProps} src={ScheduleImg} alt="schedule" />
            </Grid>
          </Grid>
        </Container>
      </ScheduleContainer>
    </Waypoint>
  );
};

Schedule.propTypes = {};

export default Schedule;
