import React from 'react';
import { Container as MuiContainer, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import lightning from 'resources/img/lightning.png';
import yellowBg from 'resources/img/yellowBg.png';
import bullet from 'resources/img/bullet.png';
import blackLine from 'resources/img/blackLine.png';
import rightLine2 from 'resources/img/rightLine2.svg';
import Text from 'ui/components/Text';

const RightLine2 = styled.img`
    position: absolute;
  top: -23rem;
  right: -33rem;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: none;
    }
`;

const WHYContainer = styled.div`
    position: relative;
`;

const LiveChatContainer = styled.div`
    padding-top: 150px;
    position: relative;
    padding: 150px 1rem 120px;
    img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 90px;
    }
`;

const DifferenceContainer = styled.div`
    opacity: 1;
    display: flex;
    align-items: center;
    padding: 60px;
    background: #EDD9D933;   
    justify-content: center; 

    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        flex-direction: column;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        padding: 30px;
    }
`;

const TableContainer = styled.div`
    background: #F7FAFF;
    box-shadow: 0px 20px 30px #1A1A1A1A;
    border-radius: 20px;
    opacity: 1;
    padding: 0 25px 0px;
    display: flex;
    align-items: center;
    max-width: 650px;
    margin-left: 60px;
    position: relative;

    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        margin: 1rem 0 0
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        padding: 0 12px 0px;
    }
`;

const BGLine = styled.img`
    position: absolute;
    width: 92%;
    top: 87px;
`;

const TableHeader = styled.h3`
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    color: #707070;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        font-size: 16px;
    }
`;

const TableHeaderContainer = styled.div`
    opacity: 1;
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 50px;
`;

const DifferenceContentWrapper = styled.div`
    opacity: 1;
    position: relative;
    max-width: 310px;
    img {
        position: absolute;
        width: 310px;
        height: 94px;
        top: 75px;
    }
`;

const FlexContainer = styled.div`
    display: flex;
        padding-bottom: 30px;
    img {
        height: 10px;
        width: 10px;
        margin-top: 5px;
    }
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        img {
            margin-top: 2px;
        }
    }
`;


const LiveChat = props => {
    const [isContentVisible, setContentIsVisible] = React.useState(false);

    const leftCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
    });
    const rightCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
    });
    return (
        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
            onEnter={() => setContentIsVisible(true)}
            onLeave={() => setContentIsVisible(false)}
        >
            <WHYContainer>
                <RightLine2 src={rightLine2} />
                <LiveChatContainer>
                    <img src={lightning} />
                    <Text fontSize="18px" maxWidth="600px" textAlign="center">THE RISE OF</Text>
                    <Text fontSize="40px" smFontSize="30px" fontWeight={700} maxWidth="650px" textAlign="center" paddingTop="10px" paddingBottom="30px">Chatbots and their broken promises</Text>
                    <Text fontSize="18px" maxWidth="600px" textAlign="center">
                        When conversational marketing emerged as a popular marketing trend, chatbots became the B2B channel for marketers to forge one-on-one connections with their qualified leads and buyers.
                    <br />
                        <br />
                    What was once limited to customer service swiftly proved to be a worthy sales and marketing tool. From capturing email addresses and doing smart audience segmentation to responsively guiding customers along the sales funnel towards qualification, validation, nurturing, and retention, chatbots have enabled marketers to create a better digital customer experience.
                </Text>
                    <Text fontSize="40px" smFontSize="30px" fontWeight={700} maxWidth="600px" textAlign="center" paddingTop="20px" paddingBottom="20px">But...</Text>
                    <Text fontSize="18px" maxWidth="600px" textAlign="center">
                        Although more and more marketers are investing in a messaging platform to make those one-on-connections with their prospects, not all buyers feel the love.
                        <br />
                        <br />
                        According to Helpshift via eMarketer, 51% of US adults dislike chatbots because they keep them from accessing a live person, while 48% find that they provide unhelpful responses.
                        <br />
                        <br />
                        Isn’t this human-to-human interaction on the website considered live chat?
                    </Text>
                </LiveChatContainer>
                <DifferenceContainer>
                    <DifferenceContentWrapper>
                        <img src={yellowBg} />
                        <Text fontSize="40px" fontWeight={700} textAlign="left" paddingBottom="68px" paddingLeft="20px">Live Chat vs. Human-First Conversations</Text>
                        <Text fontSize="18px" maxWidth="600px" textAlign="left" smDisplay="none">
                            Now imagine what happens to your buyers who are significantly engaged and ready to talk to sales?
                        </Text>
                    </DifferenceContentWrapper>
                    <TableContainer>
                        <BGLine src={blackLine} />
                        <Grid container>
                            <Grid item xs={6}>
                                <TableHeaderContainer>
                                    <TableHeader>Live chat</TableHeader>
                                </TableHeaderContainer>

                            </Grid>
                            <Grid item xs={6}>
                                <TableHeaderContainer>
                                    <TableHeader>Human-first conversational Marketing</TableHeader>
                                </TableHeaderContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <FlexContainer>
                                    <img src={bullet} />
                                    <Text fontSize="18px" marginLeft="5px" paddingLeft="5px" smFontSize="14px">
                                        Restricted hours of the day
                                    </Text>
                                </FlexContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <FlexContainer>
                                    <img src={bullet} />
                                    <Text fontSize="18px" marginLeft="5px" paddingLeft="5px" smFontSize="14px">
                                        On-demand, and when the team is out of office, chatbots can book meetings for them.
                                    </Text>
                                </FlexContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <FlexContainer>
                                    <img src={bullet} />
                                    <Text fontSize="18px" marginLeft="5px" paddingLeft="5px" smFontSize="14px">
                                        Mostly used for customer support
                                    </Text>
                                </FlexContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <FlexContainer>
                                    <img src={bullet} />
                                    <Text fontSize="18px" marginLeft="5px" paddingLeft="5px" smFontSize="14px">
                                        Mostly used for marketing, sales, and customer success
                                    </Text>
                                </FlexContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <FlexContainer>
                                    <img src={bullet} />
                                    <Text fontSize="18px" marginLeft="5px" paddingLeft="5px" smFontSize="14px">
                                        Reactive approach to connecting with customers
                                    </Text>
                                </FlexContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <FlexContainer>
                                    <img src={bullet} />
                                    <Text fontSize="18px" marginLeft="5px" paddingLeft="5px" smFontSize="14px">
                                        Proactive approach to connecting with prospects and customers while they’re engaged.
                                    </Text>
                                </FlexContainer>
                            </Grid>
                        </Grid>
                    </TableContainer>
                </DifferenceContainer>
            </WHYContainer>
        </Waypoint>
    );
}


LiveChat.propTypes = {};

export default LiveChat;