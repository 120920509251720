import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Lines from 'resources/img/linesXC.svg';
import './styles.css';
import Header from '../Home/Header.js';
import Footer from '../Home/Footer.js';

const HeaderWrapper = styled.div`
    min-height: 6rem;
`;

const GDPRContainer = styled.div`
  background-image: url(${Lines});
  padding: 3rem 0;
`;

const ParagraphTag = styled.p`
    margin: 0;
    color: #000000;
    font-size: 11pt;
    text-align: justify;
    margin-bottom: 0.5rem;
`;

const ListTag = styled.li`
    color: #000000;
    font-size: 11pt;
    text-align: justify;
`;

const GDPR = props => {
    return (
        <>
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>
            <GDPRContainer>
                <Container>
                    <Grid container justify="center">
                        <Grid item xs={12} lg={8}>
                            <ParagraphTag class="c0"><span class="c4 c19">Insent Inc. + GDPR</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">Effective Date: February 08, 2020 &nbsp; &nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0 c5"><span class="c1"></span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">The GDPR (General Data Protection Regulation) is an important piece of legislation
                                    that is designed to strengthen and unify data protection laws for all individuals within the European Union.
                                    The regulation became effective and enforceable on the 25th May 2018. &nbsp;Per article 25 of the GDPR
            commitment, privacy by design has been a core principle from day 1.</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c6">Our Commitment &nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">Insent is fully committed to compliance with the GDPR. This page provides a
            high-level summary. Please contact privacy@Insent directly with any questions.</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c6">Overview</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">Insent, as a Data Processor, collects and stores a minimum of Personal Data only as
            instructed by our Customer, the Data Controller, for the purposes of delivering the Insent Services.</span>
                            </ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">This page will cover:</span></ParagraphTag>
                            <ul class="c7 lst-kix_qqsaobkzdtyb-0 start">
                                <ListTag class="c0 c2"><span class="c1">The data we collect and store on behalf of our customers</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">How we comply with GDPR with respect to obtaining consents, honoring Data
                                        Subject Requests, putting in place the appropriate contracts, and maintaining appropriate
                safeguards.</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Our Sub-processors and what we do to ensure they keep customer data safe.
            </span></ListTag>
                            </ul>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c6">The Data We Collect and Store</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c3">Scope</span><span class="c1">: Insent collects, processes and stores Personal Data
            about people who chat with the Customer via Insent. &nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">&zwj;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">Insent does not sell any Contact Data collected on behalf of the Customer or
            market Insent Services to the Customer&rsquo;s site visitors.</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">Categories of Data Subjects:</span></ParagraphTag>
                            <ul class="c7 lst-kix_tlyqw5gcux9z-0 start">
                                <ListTag class="c0 c2"><span class="c1">Customer&rsquo;s end-user customers or prospects</span></ListTag>
                                <ListTag class="c0 c2"><span class="c14">Customer&rsquo;s employees or other authorized users or administrators of
                Insent</span><span class="c4 c3">&zwj;</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Name</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Contact information (company, business email address and phone number)</span>
                                </ListTag>
                                <ListTag class="c0 c2"><span class="c1">IP Address*</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Cookie Data**</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Photographs (we will use photographs of Customer employees if the Customer is
                routing the chats to them after qualification)</span></ListTag>
                            </ul>
                            <ParagraphTag class="c0"><span class="c1">*Insent will only use the IP address for data enrichment, i.e. to determine if it is
                                    associated with a business and then give you additional information re: that business such as industry and
            number of employees).</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c14">**Insent will only use cookies to track the activities of your site visitors within
                                    your site, e.g. whether they visited a particular product page or the pricing page before engaging with the
            messaging widget. </span><span class="c3 c4">Insent will NOT track users across domains or build
            profiles.</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c6">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c6">How we comply with GDPR&zwj;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">Collecting Consents</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">You can configure Insent to collect consents via chat prior to collecting email
            addresses or additional personal data. </span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">Appropriate Safeguards</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c14">Per Article 32 of the GDPR, we have in place appropriate technical and
                                    organizational measures to keep your data secure. All data is securely stored in Amazon Web Services. Please
            visit the</span><span class="c17"><a class="c12"
                                    href="https://www.google.com/url?q=https://aws.amazon.com/security/&amp;sa=D&amp;ust=1580290313604000">&nbsp;AWS
                Security Page</a></span><span class="c1">&nbsp;for additional information on AWS Cloud Security.</span>
                            </ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">Honoring Data Subject Rights</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">We have processes in place to honor data subject requests. Insent will export,
                                    correct, or delete Contact Data upon request by the Customer. If we receive a request directly from a Data
            Subject, we will work with the Customer to honor the request.</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">Our Subprocessors</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c1">Some important notes on sub-processors:</span></ParagraphTag>
                            <ul class="c7 lst-kix_ii598iblnbs3-0 start">
                                <ListTag class="c0 c2"><span class="c1">We have a diligence process to ensure that we only trust parties who have
                stringent standards regarding the safety and security of Personal Data.</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">We rely on a combination of Privacy Shield Certifications and execution of EU
                Model Clauses where data is being transferred across borders.</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Here is the list of subprocessors. You may see the list of subprocessors
                here</span></ListTag>
                            </ul>
                            <ParagraphTag class="c0"><span class="c4 c3">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c0"><span class="c4 c3">List of Subprocessors</span></ParagraphTag>
                            <ul class="c7 lst-kix_s62pfejojdna-0 start">
                                <ListTag class="c0 c2"><span class="c1">Amazon Web Services, Inc - Cloud Infrastructure</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Salesforce Heroku - Cloud Infrastructure</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Salesforce &amp; Hubspot - Customer Relationship Management</span></ListTag>
                            </ul>
                            <ul class="c7 lst-kix_x9pd3i4ln0xw-0 start">
                                <ListTag class="c0 c2"><span class="c1">Hubspot &nbsp;- Marketing Automation</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Segment - Customer data infrastructure &amp; analytics</span></ListTag>
                                <ListTag class="c0 c2"><span class="c1">Mixpanel - Analytics </span></ListTag>
                            </ul>
                            <ParagraphTag class="c0"><span class="c1">&nbsp;</span></ParagraphTag>
                            <ParagraphTag class="c5 c16"><span class="c1"></span></ParagraphTag>
                            <ParagraphTag class="c5 c18"><span class="c4 c15"></span></ParagraphTag>
                            <div>
                                <ParagraphTag class="c13"><span class="c11">Insent.ai&#8203; </span><span class="c4 c10">| Insent Inc. | &#8203;9350 Cove
                Creek Drive, Highlands Ranch, CO 80129</span></ParagraphTag>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </GDPRContainer>
            <Footer />
        </>
    );
};

GDPR.propTypes = {};

export default GDPR;
