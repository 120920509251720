import React from 'react';
import Hero from './Hero';
import Product from './Product';
import Solution from './Solution';
import Why from './Why';
import Schedule from './Schedule';
import Integration from './Integration';
import Customer from './Customer';
import Blog from './Blog';
import Revenue from './Revenue';
import Buyer from './Buyer';
import Lead from './Lead';
import Chatbot from './Chatbot';
import Footer from './/Footer';
import {Helmet} from "react-helmet";

import Review from './Reviews';

const Home = props => {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content=" Qualify more leads and connect with your buyers using Insent’s AI bot. Seamless integrations and human-first chat support make sure no prospect gets overlooked. Get your demo today." />
          <meta name="keywords" content="ai bot,chatbot,ai chatbot(s),best chatbot,Chatbots ,Chat support" />
          <title>The Best Chatbot for Marketing & Sales Teams | ZoomInfo Chat Bot</title>
      </Helmet>
      <Hero />
      <Review />
      <Lead />
      <Customer />
      <Buyer />
      <Revenue />
      <Solution />
      {/* <Why /> */}
      <Integration />
      <Chatbot />
      <Blog />
      <Schedule />
      <Footer />
    </>
  );
};

Home.propTypes = {};

export default Home;
