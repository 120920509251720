import React, {useState, useEffect} from 'react';

import styled from 'styled-components';

import raInput from './icons/raInput.png';


const AnimatedInputFormWrapper = styled.form`
    width: 153px;
    position: relative;
    display: contents;
    opacity: 0;
`;

const AnimatedInput = styled.input`
    background: #171B350A;
    border-radius: 20px;
    height: 50px;
    border: none;
    width: 100%;
    padding-left: 20px;
    padding-right: 60px;


    &:focus, &:active {
        outline: none;
    }
`;

const AnimatedInputButton = styled.button`
    border: none;
    color: white;
background: #0A7EF9;
border-radius: 25px;
    height: 50px;
    padding: 12px 31px 12px 33px;
    margin: 0 auto;
    font-weight: 600;
font-size: 14px;
line-height: 139.3%;
/* identical to box height, or 20px */

letter-spacing: -0.035em;
    cursor pointer;
color: #FFFFFF;


display: flex;
    align-items: center;
    width: ${p => p.width ? p.width : '153px'};
    padding: 0;
    justify-content: center;


    &:focus, &:active {
        outline: none;
    }
`;



const FormSubmitElement = styled.p`
  text-align: center;
  color: green;
`;

const OverlayEmailField = styled.form`
  position: relative;
  height: 50px;
  max-width:264px;
  margin: ${p => p.margin ? p.margin : '0 auto'};
`;

const OverlayEmailInput = styled.input`
min-width: 300px;
border: 1px solid red;
    color: rgba(33, 36, 44, 0.54);
    padding: 0px 30px;
    border-radius: 24px;
    border-width: 0px;
    border-color: #e9ebf4;
    height: 37px;
    background-color: ${p => p.bgColor ? p.bgColor : '#e9ebf4'};
    width: 100%;
    min-width: 400px;
    outline: 0;
    font-size: 15px; 


  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    min-width: 300px;
  }
    
    &::placeholder {
      color: rgba(33, 36, 44, 0.54);
    }

`;

const OverlayEmailSubmit = styled.button`
position: absolute;
top:0;
right: 0;
z-index: 1;
height: 37px;
font-size: 12px;
font-weight: bold;
background-color: #0a7ef9;
color: white;
will-change: transform;
border-radius: 24px;
padding: 0px 20px;
border-color: #0a7ef9;
border-width: 0px;
transition: transform 0.1s ease-in-out 0s;
margin-bottom: -90px;
outline: 0;
-webkit-appearance: button;
cursor: pointer; 
`;


const AnimationInput = (props) => {

    const { 
        isFormSubmitted, 
        setIsFormSubmitted, 
        inputBgColor, 
        portalId, 
        formId, 
        pageUri,
        margin, 
        pageName } = props;
    const [email, setEmail] = useState('');
    const [IP, setIP] = useState('');
    const [isExpanded, setIsExpanded] = React.useState(false);

    // Get IP address from client for Hubspot analytics
    async function fetchIP() {
        const res = await fetch('https://ip.nf/me.json');
        res.json().then((res) => setIP(res.ip.ip)).catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchIP();
    }, []);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };


    const submitToHubspot = (portalId, formId, dataFields) => {
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

        // hsCookie gets the data necessary to track Hubspot analytics
        const hsCookie = document.cookie.split(';').reduce((cookies, cookie) => {
            const [name, value] = cookie.split('=').map((c) => c.trim());
            cookies[name] = value;
            return cookies;
        }, {});

        const data = {
            fields: dataFields,
            context: {
                hutk: hsCookie.hubspotutk,
                pageUri: `${pageUri}`,
                pageName: `${pageName}`,
                ipAddress: `${IP}`
            }
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((response) => console.log('success', response))
            .catch((error) => console.log('error: ', error));
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        if (email === '') return
        submitToHubspot(portalId, formId, [{ name: 'email', value: `${email}` }]);


        setEmail('');
        setIsFormSubmitted(true);
    };

    return (

        <OverlayEmailField onSubmit={handleSubmit} margin={margin}>
            {isFormSubmitted ? (
                <FormSubmitElement>Thank you for submitting.</FormSubmitElement>
            ) : isExpanded ? (
                        <AnimatedInputFormWrapper>
                         <AnimatedInput
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            bgColor={inputBgColor}
                            required
                            value={email}
                            onChange={handleChange}
                         />
                         <AnimatedInputButton   type="submit" width="50px" style={{
                             position: 'absolute',
                             right: '0',
                             top: '0'
                         }}  onClick={handleSubmit}><img src={raInput} /></AnimatedInputButton>
                     </AnimatedInputFormWrapper>
                ) : (
                    <AnimatedInputButton type="submit"  onClick={E => {
                        E.preventDefault();
                            setIsExpanded(true);
                    }}>
                        Request Pricing
                    </AnimatedInputButton>
                )}

        </OverlayEmailField>
    );
};
export default AnimationInput;
