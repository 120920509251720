import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import {
  useSpring,
  //  useSprings,
  animated,
} from 'react-spring';
import { Waypoint } from 'react-waypoint';
// import { TabletDesktop, Mobile } from 'ui/components/Media';
import styled from 'styled-components'; //  css
import ProductLogo from 'resources/img/product.jpg';
// import Carousel from 'nuka-carousel';

const ProductContainer = styled.div`
  background: #0a7ef9;
  padding: 110px 0;
  color: white;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 55px 0;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 2rem 0;
  }
`;

const ProductImgWrapper = styled(animated.div)`
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    display: flex;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
  }
`;

const ProductImg = styled(animated.div)`
  background-image: url(${ProductLogo});
  background-size: contain;
  border-radius: 4px;
  height: 382px;
  width: 500px;
  box-shadow: 20px 20px 99px 0 rgba(0, 0, 0, 0.16);

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    height: 382px;
    width: 500px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    height: 260px;
    width: 100%;
    background-size: cover;
  }
`;

const ProductInfoWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 382px;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: block;
    width: 100%;
    text-align: left;
  }
`;

const ProductInfoHeader = styled(animated.h1)`
  max-width: 380px;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: white;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    width: 100%;
    padding-top: 40px;
  }
`;

const ProductInfoDesc = styled(animated.p)`
  width: 407px;
  font-family: ProductSans;
  font-size: 18px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    width: 100%;
  }
`;

const ProductGrid = styled(({ textAlign, padding, ...others }) => <Grid {...others} />)`
  text-align: ${p => p.textAlign};
  padding: ${p => p.padding};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 0;
  }
`;

// const TrustedTitle = styled.p`
//   font-size: 20px;
//   padding-bottom: 1rem;

//   @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
//     text-align: center;
//   }
// `;

// const TrustedWrapper = styled.div`
//   justify-content: space-between;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;

//   @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
//     /* transform: scale(0.8); */
//     justify-content: space-evenly;
//     /* text-align: left; */
//   }
// `;

// const TrustedImg = styled(animated.img)`
//   @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
//     /* transform: scale(0.5); */
//   }

//   @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
//   }
// `;

// const ProductTouchCarousel = styled(Carousel)`
//   @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
//     width: 100vw !important;
//     margin-left: -1rem !important;
//   }
// `;

// const CarouselItem = styled.div`
//   margin: auto;
//   width: 100%;
//   text-align: center;
//   opacity: ${p => (p.currentSlide === p.currentIndex ? '1' : '0.2')};
// `;

// const CarouselImg = styled.img`
//   padding-bottom: 1rem;
//   height: 30px;
//   /* width: 150px; */
//   transition: transform 0.3s ease-in-out;
//   will-change: transform;

//   ${p => {
//     if (p.currentSlide === p.currentIndex) {
//       return css`
//         transform: scale(1);
//       `;
//     } else {
//       return css`
//         transform: scale(0.8);
//       `;
//     }
//   }};
// `;

// const trustedCoy = [
//   {
//     img: require('resources/img/newyorktimes.png'),
//     width: '322px',
//     height: '50px',
//   },
//   {
//     img: require('resources/img/forbes.png'),
//     width: '103px',
//     height: '30px',
//   },
//   {
//     img: require('resources/img/mashable.png'),
//     width: '142px',
//     height: '30px',
//   },
//   {
//     img: require('resources/img/zdnet.png'),
//     width: '123px',
//     height: '30px',
//   },
//   {
//     img: require('resources/img/fastcompany.png'),
//     width: '165px',
//     height: '30px',
//   },
// ];

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 4) / 20, 1.1];
const trans = (x, y, s) => `perspective(100vw) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const Product = props => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);
  // const [isTrustedVisible, setTrustedIsVisible] = React.useState(false);
  // const [currentSlide, setCurrentSlide] = React.useState(1);
  const contentStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    delay: 300,
  });
  // const [springs, setItems] = useSprings(trustedCoy.length, i => ({
  //   opacity: 0,
  //   transform: `translateY(50px)`,
  //   delay: (i + 1) * 150,
  // }));
  const [productImgStyleProps, set] = useSpring(() => ({
    xys: [0, 0, 1],
  }));

  // React.useEffect(() => {
  //   if (isTrustedVisible) {
  //     setItems(i => ({
  //       opacity: 1,
  //       transform: `translateY(0px)`,
  //       delay: (i + 1) * 150,
  //     }));
  //   } else {
  //     setItems(i => ({
  //       opacity: 0,
  //       transform: `translateY(50px)`,
  //       delay: (i + 1) * 150,
  //     }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isTrustedVisible]);

  return (
    <Element name="Product">
      <ProductContainer>
        <div>
          <Waypoint
            scrollableAncestor={window}
            onEnter={() => setContentIsVisible(true)}
            onLeave={() => setContentIsVisible(false)}
          >
            <Container>
              <ProductGrid container padding="0 0 100px 0">
                <ProductGrid item xs={12} sm={12} lg={6}>
                  <ProductImgWrapper style={contentStyleProps}>
                    <ProductImg
                      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                      onMouseLeave={() => set({ xys: [0, 0, 1] })}
                      style={{
                        transform: productImgStyleProps.xys.interpolate(trans),
                      }}
                    />
                  </ProductImgWrapper>
                </ProductGrid>
                <ProductGrid item xs={12} sm={12} lg={6}>
                  <ProductInfoWrapper>
                    <animated.div style={contentStyleProps}>
                      <ProductInfoHeader>
                        You did it. You got their attention. But now what?{' '}
                      </ProductInfoHeader>
                      <ProductInfoDesc>
                        You can either wait for them to submit a form, or you can speak to them NOW,
                        while they’re on your website.
                      </ProductInfoDesc>
                      <ProductInfoDesc>
                        Turn every hard-earned click into a conversation worth closing.
                      </ProductInfoDesc>
                    </animated.div>
                  </ProductInfoWrapper>
                </ProductGrid>
              </ProductGrid>
            </Container>
          </Waypoint>
          {/* <Container>
            <ProductGrid container>
              <ProductGrid item xs={12}>
                <TrustedTitle>Trusted for Improving Conversations</TrustedTitle>
              </ProductGrid>
              <ProductGrid item xs={12}>
                <Waypoint
                  onEnter={() => setTrustedIsVisible(true)}
                  onLeave={() => setTrustedIsVisible(false)}
                >
                  <div>
                    <TabletDesktop>
                      <TrustedWrapper>
                        {springs.map((props, i) => (
                          <TrustedImg
                            style={props}
                            key={i}
                            src={trustedCoy[i].img}
                            width={trustedCoy[i].width}
                            height={trustedCoy[i].height}
                            alt={trustedCoy[i].width}
                          />
                        ))}
                      </TrustedWrapper>
                    </TabletDesktop>

                    <Mobile>
                      <ProductTouchCarousel
                        slideIndex={currentSlide}
                        slidesToShow="1.9"
                        cellAlign="center"
                        withoutControls={true}
                        renderAnnounceSlideMessage={({ currentSlide }) =>
                          setCurrentSlide(currentSlide)
                        }
                      >
                        {trustedCoy.map((item, i) => {
                          return (
                            <CarouselItem
                              style={props}
                              currentSlide={currentSlide}
                              currentIndex={i}
                              key={i}
                            >
                              <CarouselImg
                                currentSlide={currentSlide}
                                currentIndex={i}
                                src={item.img}
                              />
                            </CarouselItem>
                          );
                        })}
                      </ProductTouchCarousel>
                    </Mobile>
                  </div>
                </Waypoint>
              </ProductGrid>
            </ProductGrid>
          </Container> */}
        </div>
      </ProductContainer>
    </Element>
  );
};

Product.propTypes = {};

export default Product;
