import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import RightArrow from 'resources/icons/right-arrow.svg';
import flashWithBg from 'resources/img/flashWithBg.png';
import flashWithBg2x from 'resources/img/flashWithBg.png';
import chatpopup from 'resources/img/chatpopup.png';
import chatpopup2x from 'resources/img/chatpopup.png';
import theme from 'styles/theme';
import Button from 'ui/components/Button';
import Image from 'ui/components/Image';
import { TabletDesktop, Mobile } from 'ui/components/Media';

import agentNotified from 'resources/img/agentNotified.png';
import agentNotified2x from 'resources/img/agentNotified2x.png';
import agentNotifiedMobile from 'resources/img/agentNotifiedMobile.png';
import agentNotifiedMobile2x from 'resources/img/agentNotifiedMobile2x.png';

const BuyerContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 0 100px;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    padding: 36px 0;
  }
`;

const BuyerPreHeader = styled.div`
  color: #191919;
  // border:1px solid red;
  text-align: center;
  font-weight: 100;
  font-size: 34px;
  max-width: 700px;
  margin: auto;
  margin-bottom: 80px;
  font-stretch: normal;
  line-height: 1.2;

  span {
    // font-style: italic;
    font-weight: 700;
    color: #0a7ef9;
  }
`;

const BuyerCardWrapper = styled.div`
  padding: 60px 40px;
  background: ${(p) => p.theme.palette.background.two + '1A'};
  border-radius: 5px;
`;

const BuyerCard = styled(({ bgColor, ...others }) => (
  <animated.div {...others} />
))`
  background-color: ${(p) => p.bgColor || 'white'};
  padding: ${(p) => p.padding || '30px 40px'};
  border-radius: 20px;
  //   @media only screen and (max-width: ${(p) =>
    p.theme.breakpoints.md - 1}px) {
  //     padding: 95px 74px;
  //   }

  //   @media only screen and (max-width: ${(p) =>
    p.theme.breakpoints.sm - 1}px) {
  //     padding: 17px;
  //   }
`;

const BuyerTtile = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BuyerDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BuyerRightArrowIcon = styled.img`
  height: 10px;
  width: 10px;
`;

const BuyerIllustrationContainer = styled.div`
  max-width: 600px;
  margin: auto;
  position: relative;
  height: 450px;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    display: none;
  }
  @media only screen and (max-width: 1048px) {
    height: 350px;
  }
`;

const BuyerIllustrationWrapper = styled.div`
  margin-left: -12rem;
  position: absolute;

  @media only screen and (max-width: 1048px) {
    margin-left: unset;
  }
`;

const BuyerIllustrationContainerMobile = styled.div`
  max-width: 400px;
  margin: auto;
  position: relative;

  display: none;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    display: block;
  }
`;

const BuyerIllustrationWrapperMobile = styled.div`
  margin-left: -12rem;
  position: absolute;

  @media only screen and (max-width: 1048px) {
    margin-left: unset;
  }
`;

const Buyer = (props) => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);
  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });

  const renderDesktopIllustration = () => (
    <BuyerIllustrationContainer>
      <BuyerIllustrationWrapper>
        <Image
          height="100%"
          width="100%"
          hdSrc={agentNotified2x}
          src={agentNotified}
        />
      </BuyerIllustrationWrapper>
    </BuyerIllustrationContainer>
  );

  const renderMobileIllustration = () => (
    <BuyerIllustrationContainerMobile>
      <Image
        height="100%"
        width="100%"
        hdSrc={agentNotifiedMobile2x}
        src={agentNotifiedMobile}
      />
    </BuyerIllustrationContainerMobile>
  );

  return (
    <Element name="Why ZoomInfo Chat">
      <BuyerContainer>
        <Waypoint
          scrollableAncestor={window}
          topOffset="10%"
          bottomOffset="35%"
          onEnter={() => setContentIsVisible(true)}
          onLeave={() => setContentIsVisible(false)}
        >
          <Container>
            {renderDesktopIllustration()}
            {renderMobileIllustration()}
            <Text
              fontSize="40px"
              smFontSize="30px"
              fontWeight={700}
              textAlign="center"
              maxWidth="810px"
              marginBottom="61px"
            >
              Why <span>ZoomInfo Chat</span> is the best chatbot to move your leads to
              the next step:
            </Text>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <BuyerCard
                  style={leftCardStyleProps}
                  bgColor={theme.palette.background.four + '33'}
                >
                  <Image
                    height="60px"
                    width="60px"
                    hdSrc={flashWithBg2x}
                    src={flashWithBg}
                  />
                  <BuyerTtile
                    fontSize="22px"
                    marginTop="20px"
                    fontWeight={700}
                    textAlign="left"
                  >
                    Get alerted when your most engaged buyers arrive.
                  </BuyerTtile>
                  <BuyerDescription
                    fontSize="18px"
                    smFontSize="14px"
                    fontWeight={400}
                    textAlign="left"
                    marginBottom="20px"
                    marginTop="25px"
                    color={theme.palette.text.four}
                  >
                    Use ZoomInfo Chat to identify and qualify your visitors in
                    real-time, so you know who to talk to.
                  </BuyerDescription>
                  <Button
                    onClick={() => {
                      window.location.href = 'https://insent.ai/qualify-engage';
                    }}
                  >
                    Learn More{' '}
                    <BuyerRightArrowIcon src={RightArrow} alt="Learn more" />
                  </Button>
                </BuyerCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BuyerCard
                  style={rightCardStyleProps}
                  bgColor={theme.palette.background.four + '33'}
                >
                  <Image
                    width="93px"
                    height="60px"
                    hdSrc={chatpopup2x}
                    src={chatpopup}
                  />
                  <BuyerTtile
                    fontSize="22px"
                    marginTop="20px"
                    fontWeight={700}
                    textAlign="left"
                  >
                    Start a conversation on site and engage with context.
                  </BuyerTtile>
                  <BuyerDescription
                    fontSize="18px"
                    smFontSize="14px"
                    fontWeight={400}
                    textAlign="left"
                    marginBottom="20px"
                    marginTop="25px"
                    color={theme.palette.text.four}
                  >
                    Empower your sales team to initiate conversations with
                    qualifying data and insights.
                  </BuyerDescription>
                  <Button
                    onClick={() => {
                      window.location.href = 'https://insent.ai/converse-context';
                    }}
                  >
                    Learn More{' '}
                    <BuyerRightArrowIcon src={RightArrow} alt="Learn more" />
                  </Button>
                </BuyerCard>
              </Grid>
            </Grid>
            <Text
              fontSize="24px"
              smFontSize="18px"
              marginTop="35px"
              fontWeight={700}
              textAlign="center"
              maxWidth="800px"
            >
              <span>Results:</span> Conversations on site generate more leads,
              accelerate deals and book meetings around the clock.
            </Text>
          </Container>
        </Waypoint>
      </BuyerContainer>
    </Element>
  );
};

Buyer.propTypes = {};

export default Buyer;
