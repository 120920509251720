import React from 'react';
import Hero from './Hero';
import Schedule from './Schedule';
import Process from './Process';
import Plug from './Plug';
import Header from '../Home/Header';
import styled from 'styled-components';
import Footer from '../Home/Footer';
import rightLine1 from 'resources/img/rightLine1.svg';
import leftLine1 from 'resources/img/leftLine1.svg';

const RightLine1 = styled.img`
    position: absolute;
    top: 30vh;
    width: 88%;
    right: -30rem;
    z-index: -1;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        display: none;
    }
`;

const LeftLine1 = styled.img`
  position: absolute;
  top: 100rem;
  left: -33rem;
  z-index: -1;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: none;
    }
`;


const HeaderWrapper = styled.div`
    min-height: 6rem;
    background: ${p => p.theme.palette.blue.one};
`;

const HubspotContainer = styled.div`
    position: relative;
    overflow: hidden;
`;


const Hubspot = props => {
  return (
    <HubspotContainer>
        <HeaderWrapper>
            <Header />
        </HeaderWrapper>
        <Hero />
        <RightLine1 src={rightLine1} />
        <Plug />
        <Process />
        <LeftLine1 src={leftLine1} />
        <Schedule />
        <Footer />
    </HubspotContainer>
  );
};

Hubspot.propTypes = {};

export default Hubspot;