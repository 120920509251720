import React from 'react';
import Footer from '../Home/Footer';
import Hero from './Hero.js';
import Header from '../Home/Header';
import Schedule from './Schedule.js';
import ConversationStarter from './ConversationStarter.js';
import LookAlike from './LookAlike.js';
import styled from 'styled-components';
import { Helmet } from "react-helmet";

const HeaderWrapper = styled.div`
    min-height: 6rem;
    background: ${p => p.theme.palette.blue.one};
`; 
const ConverseContext = props => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Create a better buyer journey with, Insent, the best conversation bot for customer engagement. It’s the messenger bot that both your sales and marketing teams will love. Book a demo and learn more today. " />
                <meta name="keywords" content="conversation bot, conversational marketing, messenger bot, online chat app, customer engagement" />
                <title>The Best Conversation Bot for Customer Engagement | Insent.ai Bot</title>
            </Helmet>
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>
            <Hero />
            <ConversationStarter />
            <LookAlike />
            <Schedule />
            <Footer />
        </>
    );
};

ConverseContext.propTypes = {};

export default ConverseContext;