import React from 'react';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import Header from '../Home/Header';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';

const HeroContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const MarketingDiv = styled.div`
  margin-top: 100px;
`;

const renderSalesPageMob = () => (
  <MarketingDiv>
    <div class="marketing">
      <section class="marketing__banner">
        <div class="container">
          <div class="text-col">
            <h1>Roll out the red carpet buying experience 😍️️</h1>
            <p>
              ZoomInfo Chat makes customer acquisition easier, connecting you to your
              ideal customers the moment they land on your website.
            </p>
            <CallToAction />
          </div>
          <div class="img-col img-revel">
            <img src="./assets/img/banner2.png" alt="banner-img" />
          </div>
        </div>
      </section>
      <div class="lines-set__1">
        <section class="marketing__client">
          <div class="container">
            <div class="testimonal">
              <div class="img-wrapper">
                <img src="./assets/img/p1.jpeg" alt="banner-img" />
              </div>
              <div class="content">
                <div class="logo">
                  <img
                    src="./assets/img/TouchPointsLogo.jpg"
                    alt="banner-img"
                  />
                </div>
                <p>
                  When I get a notification from ZoomInfo Chat, I switch tabs so
                  fast. Nothing else matters when my target account is here.
                </p>
                <h6>Matt LoPresti</h6>
                <h5>Founder at TouchPoints</h5>
              </div>
            </div>
            <div class="list">
              <h2>
                Close more of your dream <span>customers</span> with <br />
                real conversations
              </h2>
              <a href="#" class="ghost-btn">
                Meet our customers
              </a>

              <div class="client-list---mob">
                <img src="./assets/img/TouchPointsLogo.jpg" alt="banner-img" />
                <img
                  src="./assets/img/purplepatch-services_20170804230450.jpeg"
                  alt="banner-img"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="para-section para-section---sales">
          <p>
            Take the fast track to your <span>revenue goals</span> when you add
            AI chatbot to your sales acceleration software.
          </p>
          <p>
            ZoomInfo Chat helps you know when your best prospects are on your website,
            generating high-value opportunities by opening the door to meet with
            sales.
          </p>
        </section>
        <section class="two-col-block two-col-block---img-left">
          <div class="container">
            <div class="img-wrapper img-revel">
              <img src="./assets/img/t1.png" alt="banner-img" />
            </div>
            <div class="text-wrapper">
              <h2>Optimize your customer acquisition </h2>
              <p>
                Your time is valuable. If you could spend more time selling, and
                less time qualifying, scheduling meetings, and waiting for email
                responses, your pipeline could be a lot stronger.{' '}
              </p>
              <p>
                ZoomInfo Chat helps make that a reality - for only your best accounts.
              </p>
            </div>
          </div>
        </section>
        <section class="two-col-block two-col-block---img-right">
          <div class="container">
            <div class="text-wrapper">
              <h2>Know no bot can replace you</h2>
              <p>
                It’s sales enablement, not complete automation. ZoomInfo Chat helps
                enhance human connection, delivering instant notifications that
                help you convert prospects into customers at key moments along
                the buyer journey.{' '}
              </p>
              <p>
                We treat chatbots like your back-up plan. They do the heavy
                lifting when you can’t.
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/t2.png" alt="banner-img" />
            </div>
          </div>
        </section>
      </div>
      <section class="two-col-block two-col-block---img-left">
        <div class="container">
          <div class="img-wrapper img-revel">
            <img src="./assets/img/t3.png" alt="banner-img" />
          </div>
          <div class="text-wrapper">
            <h2>Streamline the sales process</h2>
            <p>
              All the metrics and insights from your CRM are visible right along
              ZoomInfo Chat messaging platform. There’s even a built-in meeting
              scheduler to help you move leads to the next level.
            </p>
            <p>Gathering intel on your prospects has never been easier.</p>
          </div>
        </div>
      </section>
      <div class="lines-set__2">
        <section class="two-col-block two-col-block---last-row">
          <div class="container">
            <div class="text-wrapper">
              <h2>
                ZoomInfo Chat was built to make your marketing <br />
                team <span> work effortlessly</span> with sales
              </h2>
              <p>
                No more marketing to build engagement. <br />
                Start marketing to build relationships{' '}
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s4.png" alt="banner-img" />
            </div>
          </div>
        </section>
        <section class="feature-grid---mob">
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon4.png" alt="banner-img" />
            </div>
            <h2>Prioritize high value accounts</h2>
            <p>
              Your target customers who are actually engaging deserve some
              prioritized, red-carpet service.
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon2.png" alt="banner-img" />
            </div>
            <h2>Convert the anonymous</h2>
            <p>
              Generate more pipeline by converting your anonymous website
              visitors into known leads, and sales-ready opportunities.
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon3.png" alt="banner-img" />
            </div>
            <h2>Continue the conversation in real-time</h2>
            <p>
              Once you’ve sparked a conversation, you can route it to the
              appropriate SDR or AE in real-time by moving them onto any
              messenger platform you use.{' '}
            </p>
          </div>
          <div class="single">
            <div class="img-wrapper">
              <img src="./assets/img/icon1.png" alt="banner-img" />
            </div>
            <h2>Book qualified meetings</h2>
            <p>
              Give your prospects quick access to your sales team by skipping
              the forms and instantly qualifying and booking meetings right from
              your website.
            </p>
          </div>
        </section>
        <section class="para-section-bootom">
          <h6>
            Let the <span>pipeline generation</span> begin!{' '}
          </h6>
          <p>
            Meet your pipeline goals by instantly opening the door for your
            dream prospects to meet with sales, from the moment they land on
            your website.
          </p>
        </section>
      </div>
      <section class="get-started-footer">
        <div class="container">
          <div class="text-box">
            <h2>Get Started With ZoomInfo Chat </h2>
            <p>
              Schedule a time with our team to learn how you can <br />
              start building relationships with your prospects today.
            </p>
            <CallToAction />
          </div>
          <div class="img img-revel">
            <img src="./assets/img/wrong.png" alt="banner-img" />
          </div>
        </div>
      </section>
    </div>
  </MarketingDiv>
);

const renderSalesPage = () => (
  <MarketingDiv>
    <div class="marketing">
      <section class="marketing__banner">
        <div class="container">
          <div class="text-col">
            <h1>Roll out the red carpet buying experience 😍️️</h1>
            <p>
              ZoomInfo Chat makes customer acquisition easier, connecting you to your
              ideal customers the moment they land on your website.
            </p>
            <CallToAction />
          </div>
          <div class="img-col img-revel">
            <img src="./assets/img/banner2.png" alt="banner-img" />
          </div>
        </div>
      </section>
      <div class="lines-set__1">
        <section class="marketing__client">
          <div class="container">
            <div class="testimonal">
              <div class="img-wrapper">
                <img src="./assets/img/p1.jpeg" alt="banner-img" />
              </div>
              <div class="content">
                <div class="logo">
                  <img
                    src="./assets/img/TouchPointsLogo.jpg"
                    alt="banner-img"
                  />
                </div>
                <p>
                  When I get a notification from ZoomInfo Chat, I switch tabs so
                  fast. Nothing else matters when my target account is here.
                </p>
                <h6>Matt LoPresti</h6>
                <h5>Founder at TouchPoints</h5>
              </div>
            </div>
            <div class="list">
              <h2>
                Close more of your dream <span>customers</span> with <br />
                real conversations
              </h2>
              <a href="#" class="ghost-btn">
                Meet our customers
              </a>
              <div class="client-list center">
                <img src="./assets/img/TouchPointsLogo.jpg" alt="banner-img" />
                <img
                  src="./assets/img/purplepatch-services_20170804230450.jpeg"
                  alt="banner-img"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="para-section para-section---sales">
          <p>
            Take the fast track to your <span>revenue goals</span> when you add
            AI chatbot to your sales acceleration software.
          </p>
          <p>
            ZoomInfo Chat helps you know when your best prospects are on your website,
            generating high-value opportunities by opening the door to meet with
            sales.
          </p>
        </section>
        <section class="two-col-block two-col-block---img-left">
          <div class="container">
            <div class="img-wrapper img-revel">
              <img src="./assets/img/t1.png" alt="banner-img" />
            </div>
            <div class="text-wrapper">
              <h2>Optimize your customer acquisition </h2>
              <p>
                Your time is valuable. If you could spend more time selling, and
                less time qualifying, scheduling meetings, and waiting for email
                responses, your pipeline could be a lot stronger.{' '}
              </p>
              <p>
                ZoomInfo Chat helps make that a reality - for only your best accounts.
              </p>
            </div>
          </div>
        </section>
        <section class="two-col-block two-col-block---img-right">
          <div class="container">
            <div class="text-wrapper">
              <h2>Know no bot can replace you</h2>
              <p>
                It’s sales enablement, not complete automation. ZoomInfo Chat helps
                enhance human connection, delivering instant notifications that
                help you convert prospects into customers at key moments along
                the buyer journey.{' '}
              </p>
              <p>
                We treat chatbots like your back-up plan. They do the heavy
                lifting when you can’t.
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/t2.png" alt="banner-img" />
            </div>
          </div>
        </section>
      </div>
      <section class="two-col-block two-col-block---img-left">
        <div class="container">
          <div class="img-wrapper img-revel">
            <img src="./assets/img/t3.png" alt="banner-img" />
          </div>
          <div class="text-wrapper">
            <h2>Streamline the sales process</h2>
            <p>
              All the metrics and insights from your CRM are visible right along
              ZoomInfo Chat messaging platform. There’s even a built-in meeting
              scheduler to help you move leads to the next level.
            </p>
            <p>Gathering intel on your prospects has never been easier.</p>
          </div>
        </div>
      </section>
      <div class="lines-set__2">
        <section class="two-col-block two-col-block---last-row">
          <div class="container">
            <div class="text-wrapper">
              <h2>
                ZoomInfo Chat was built to make your marketing <br />
                team <span> work effortlessly</span> with sales
              </h2>
              <p>
                No more marketing to build engagement. <br />
                Start marketing to build relationships{' '}
              </p>
            </div>
            <div class="img-wrapper img-revel">
              <img src="./assets/img/s4.png" alt="banner-img" />
            </div>
          </div>
        </section>
        <section class="feature-grid">
          <div class="container">
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon4.png" alt="banner-img" />
              </div>
              <h2>Prioritize high value accounts</h2>
              <p>
                Your target customers who are actually engaging deserve some
                prioritized, red-carpet service.
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon2.png" alt="banner-img" />
              </div>
              <h2>Convert the anonymous</h2>
              <p>
                Generate more pipeline by converting your anonymous website
                visitors into known leads, and sales-ready opportunities.
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon3.png" alt="banner-img" />
              </div>
              <h2>Continue the conversation in real-time</h2>
              <p>
                Once you’ve sparked a conversation, you can route it to the
                appropriate SDR or AE in real-time by moving them onto any
                messenger platform you use.{' '}
              </p>
            </div>
            <div class="single">
              <div class="img-wrapper">
                <img src="./assets/img/icon1.png" alt="banner-img" />
              </div>
              <h2>Book qualified meetings</h2>
              <p>
                Give your prospects quick access to your sales team by skipping
                the forms and instantly qualifying and booking meetings right
                from your website.
              </p>
            </div>
          </div>
        </section>

        <section class="para-section-bootom">
          <h6>
            Let the <span>pipeline generation</span> begin!{' '}
          </h6>
          <p>
            Meet your pipeline goals by instantly opening the door for your
            dream prospects to meet with sales, from the moment they land on
            your website.
          </p>
        </section>
      </div>
      <section class="get-started-footer">
        <div class="container">
          <div class="text-box">
            <h2>Get Started With ZoomInfo Chat </h2>
            <p>
              Schedule a time with our team to learn how you can <br />
              start building relationships with your prospects today.
            </p>
            <CallToAction />
          </div>
          <div class="img img-revel">
            <img src="./assets/img/wrong.png" alt="banner-img" />
          </div>
        </div>
      </section>
    </div>
  </MarketingDiv>
);

const HeroTabletDesktopView = (props) => {
  return (
    <TabletDesktop>
      <HeroContainer>
        <Header />
        {renderSalesPage()}
      </HeroContainer>
    </TabletDesktop>
  );
};

const HeroMobileView = (props) => {
  return (
    <Mobile>
      <HeroContainer>
        <Header />
        {renderSalesPageMob()}
      </HeroContainer>
    </Mobile>
  );
};

const Hero = () => {
  React.useEffect(() => {
    let script1 = document.createElement('script');
    let inlineScript1 = document.createTextNode(`$('.client-list---mob').slick({
         centerMode: false,
         // centerPadding: '60px',
         slidesToShow: 2,
         arrows: false,
         dots: false,
         responsive: [
            {
               breakpoint: 768,
               settings: {
                  centerMode: false,
                  slidesToShow: 2,
                  autoplay: true,
                  autoplaySpeed: 1500,
                  // centerPadding: '40px',
               }
            },
            {
               breakpoint: 480,
               settings: {
                  autoplay: true,
                  slidesToShow: 2,
                  autoplaySpeed: 1500,
                  centerMode: false,
               }
            }
         ]
      });`);

    script1.appendChild(inlineScript1);
    document.body.appendChild(script1);

    let script2 = document.createElement('script');
    let inlineScript2 = document.createTextNode(`$('.feature-grid---mob').slick({
         slidesToShow: 1,
         arrows: true,
         dots: false,
         responsive: [
            {
               breakpoint: 480,
               settings: {
               }
            }
         ]
      });`);

    script2.appendChild(inlineScript2);
    document.body.appendChild(script2);

    let script3 = document.createElement('script');
    let inlineScript3 = document.createTextNode(`window.sr = ScrollReveal();
      sr.reveal('.img-revel', {
         duration: 1000,
         origin: 'bottom',
         distance: '40px',
         delay: 0,
      });`);

    script3.appendChild(inlineScript3);
    document.body.appendChild(script3);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeroTabletDesktopView />
      <HeroMobileView />
    </>
  );
};

Hero.propTypes = {};

export default Hero;
