import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import RightArrow from 'resources/icons/right-arrow.svg';
import theme from 'styles/theme';
import Button from 'ui/components/Button';
import flash from 'resources/img/flash.png';
import flash2x from 'resources/img/flash2x.png';

const RevenueActionWrapper = styled(Text)`
  color: ${(p) => p.theme.palette.blue.two};
  margin-left: auto;
  margin-right: 2rem;
`;

const RevenueDetail = styled.div`
  margin-left: 20px;
`;

const RevenueRightArrowIcon = styled.img`
  height: 10px;
  width: 10px;
`;

const RevenueContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 60px 0;
  background-color: ${(p) => p.theme.palette.background.three};

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    padding: 36px 0;
  }
`;

const RevenueFlashIcon = styled.div`
  display: contents;
`;

const RevenueCard = styled.div`
  display: flex;
  border-radius: 10px;
  padding-bottom: 30px;
  overflow: hidden;
  height: 100%;
`;

const RevenueTtile = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const RevenueDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 90px;
`;

const Revenue = (props) => {
  return (
    <Element name="Revenue">
      <RevenueContainer>
        <Waypoint
          scrollableAncestor={window}
          topOffset="10%"
          bottomOffset="35%"
        >
          <Container>
            <Text
              fontSize="40px"
              fontWeight={700}
              textAlign="center"
              marginBottom="60px"
            >
              Used by your entire <span>revenue</span> organization
            </Text>

            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4}>
                <RevenueCard>
                  <RevenueFlashIcon>
                    <Image
                      height="33px"
                      width="16px"
                      hdSrc={flash2x}
                      src={flash}
                    />
                  </RevenueFlashIcon>
                  <RevenueDetail>
                    <RevenueTtile
                      fontSize="22px"
                      fontWeight={700}
                      textAlign="left"
                    >
                      For Marketing Teams
                    </RevenueTtile>
                    <RevenueDescription
                      fontSize="18px"
                      fontWeight={400}
                      textAlign="left"
                      marginBottom="20px"
                      marginTop="20px"
                      color={theme.palette.text.four}
                    >
                      Turn every hard-earned click into a marketing-sourced
                      lead, sales conversation—and even a scheduled meeting.
                    </RevenueDescription>
                    <Button
                      onClick={() => {
                        window.location.href = 'https://insent.ai/marketing-team';
                      }}
                    >
                      Learn More{' '}
                      <RevenueRightArrowIcon
                        src={RightArrow}
                        alt="Learn more"
                      />
                    </Button>
                  </RevenueDetail>
                </RevenueCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <RevenueCard>
                  <RevenueFlashIcon>
                    <Image
                      height="33px"
                      width="16px"
                      hdSrc={flash2x}
                      src={flash}
                    />
                  </RevenueFlashIcon>
                  <RevenueDetail>
                    <RevenueTtile
                      fontSize="22px"
                      fontWeight={700}
                      textAlign="left"
                    >
                      For Sales Teams
                    </RevenueTtile>
                    <RevenueDescription
                      fontSize="18px"
                      fontWeight={400}
                      textAlign="left"
                      marginBottom="20px"
                      marginTop="20px"
                      color={theme.palette.text.four}
                    >
                      Get connected to your most engaged buyers the moment they
                      land on your website.
                    </RevenueDescription>
                    <Button
                      onClick={() => {
                        window.location.href = 'https://insent.ai/sales-team';
                      }}
                    >
                      Learn More{' '}
                      <RevenueRightArrowIcon
                        src={RightArrow}
                        alt="Learn more"
                      />
                    </Button>
                  </RevenueDetail>
                </RevenueCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <RevenueCard>
                  <RevenueFlashIcon>
                    <Image
                      height="33px"
                      width="16px"
                      hdSrc={flash2x}
                      src={flash}
                    />
                  </RevenueFlashIcon>
                  <RevenueDetail>
                    <RevenueTtile
                      fontSize="22px"
                      fontWeight={700}
                      textAlign="left"
                    >
                      For ABM Teams
                    </RevenueTtile>
                    <RevenueDescription
                      fontSize="18px"
                      fontWeight={400}
                      textAlign="left"
                      marginBottom="20px"
                      marginTop="20px"
                      color={theme.palette.text.four}
                    >
                      Help sales and marketing work together to roll out the
                      red-carpet when your target accounts arrive.
                    </RevenueDescription>
                    <Button
                      onClick={() => {
                        window.location.href = 'https://insent.ai/abm';
                      }}
                    >
                      Learn More{' '}
                      <RevenueRightArrowIcon
                        src={RightArrow}
                        alt="Learn more"
                      />
                    </Button>
                  </RevenueDetail>
                </RevenueCard>
              </Grid>
            </Grid>
          </Container>
        </Waypoint>
      </RevenueContainer>
    </Element>
  );
};

Revenue.propTypes = {};

export default Revenue;
