import React from 'react';

const LeftLine = props => (
  <svg
    viewBox="0 0 611.479 453.363"
    stroke="#0a7ef9"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <g data-name="Group 6" fill="none">
      <path data-name="Line 1" d="M50.171.469l326 120" />
      <path data-name="Line 2" d="M.171 87.469l611 222" />
      <path data-name="Line 3" d="M50.171 384.469l327-264" />
      <path data-name="Line 12" d="M611.171 308.969l-184 144" />
    </g>
  </svg>
);

export default LeftLine;
