export const growthPricing = ["Up to 10 Users",
    "Up to 50,000 Monthly Visits",
    "Live Chat for Sales",
    "Proactive Sales Alerting",
    "Chatbot Automation",
    "Out-of-the-Box Conversation Templates",
    "Native Calendar Integration to Book Meetings (Human-First & Automated)",
    "Account Data Enrichment",
    "CRM, Marketing Automation, Zapier, Slack, MS Teams Integrations",
    "Reverse IP Resolution",
    "Office hours",
    "60 Day Onboarding",
    "Live Chat and Zoom Support"
];

export const midMarketPricing = [
    "Up to 25 Users",
    "Up to 500,000 Monthly Visits ",
    "Click-Based Chat ",
    "Advanced Greeting Builder",
    "Teams and Group Configuration",
    "Advanced Chat Routing",
    "Premium Reverse IP Resolution",
    "Account-Based Targeting & Platform Integrations",
    "Advanced & Automated A/B Testing",
    "Advanced Analytics Dashboards",
    "Sandbox", 
    "Live Chat & Automated Customer Support",
    "Support Ticket Configuration",
    "CS, Sales Platform Integrations",
    "90 Day Onboarding",
    "Dedicated Success Manager",
    "Quarterly Business Reviews",
]


export const pricingPlan = {
    GROWTH: 'Growth',
    MID_MARKET: 'Mid-Market',
    ENTERPRISE: 'Enterprise',
}

export const techStacksList = [
    { value: 'Marketo', label: 'Marketo' },
    { value: 'HubSpot', label: 'HubSpot' },
    { value: 'Salesforce', label: 'Salesforce' },
    { value: 'Pardot', label: 'Pardot' },
    { value: 'Eloqua', label: 'Eloqua' },
    { value: 'Slack', label: 'Slack' },
    { value: 'MS Teams', label: 'MS Teams' },
    { value: 'Zendesk', label: 'Zendesk' },
    { value: 'Outreach', label: 'Outreach' },
    { value: 'SalesLoft', label: 'SalesLoft' },
    { value: 'Segment', label: 'Segment' },
  ]

  export const premiumPricing = ["Zendesk","Outreach","SalesLoft","Segment"]

  export const selectStyles = {
    placeholder: styles => {
        return {
          ...styles,
          fontSize: '12px',
          lineHeight: '100%',
          color:  '#ffffffCC',
          marginTop: '-4px'
        };
    },
    menu: (style, state) => ({
        ...style,
        backgroundColor: '#ffffff',
        border: 'none',
        padding: '0',
        borderRadius: '6px',
        width: '100%',
        maxHeight: '190px',
      }),
      menuList: style => ({
          ...style,
        maxHeight: '190px',
        overflow: 'scroll',
        zIndex: 9999,
        overflowX: 'hidden'
      }),
      container: style => ({
          ...style,
        width: '100%',
        minHeight: '53PX',
        border: 'none',
      }),
    control: styles => ({ 
        ...styles, 
        backgroundColor: '#ffffff33', 
        width: '100%',
        minHeight: '53PX', 
        border: 'none',
        padding: '8px 0 0 0',
    }),
    indicatorSeparator: styles => ({ 
        ...styles, 
        display: 'none',
    }),
    dropdownIndicator: styles => ({ 
        ...styles, 
        opacity: 1,
        color: 'white',
        marginTop: '-4px'
    }),
    multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: '#ffffff4d',
          minHeight: '35px',
          alignItems: 'center',
            padding: '12px',
            margin: 0,
            marginRight: '8px',
            marginBottom: '8px',
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: 'white',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: 0,
        paddingLeft: '0px',
      }),
      multiValueRemove: (styles, { data }) => {
        return {
            ...styles,
            fill: 'white',
            paddingRight: 0,
          };
      },



    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : 'black'),
        },
      };
    },
    
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };