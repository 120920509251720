import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import styled from 'styled-components';
import { Formik } from 'formik';
import LeftLine from 'resources/img/leftLine.js';
import RightLine from 'resources/img/rightLine.js';
import Logo from 'resources/img/zoominfo-logo.png';
import LeftArrow from 'resources/icons/left-arrow.svg';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import request from 'utils/request.js';
import Alert from '@material-ui/lab/Alert';
import Footer from '../Home/Footer';

const LoginContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100vh;
  align-items: center;
  text-align: center;
  background-color: #f5f5f5;
  z-index: 0;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    background-color: #fff;
  }
`;



const LoginDesc = styled.p`
  font-size: 16px;
  line-height: 1.22;
  color: #757575;
  max-width: 532px;
  text-align: left;
  padding-bottom: 0.5rem;
  margin: 0 auto;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: 12px;
  }
`;


const ArrowDownWrapper = styled(animated.div)`
  width: 100%;
  position: absolute;
  bottom: 60px;
  right: 0;
  left: 0;
  height: 20px;
  width: 54px;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }
`;


const LeftWrapper = styled(animated.div)`
  position: absolute;
  left: -100px;
  bottom: -100px;
  z-index: -1;

  svg {
    height: 500px;
    width: 700px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      height: 500px;
      width: 450px;
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      display: none;
    }
  }
`;

const RightWrapper = styled(animated.div)`
  position: absolute;
  right: -60px;
  bottom: -100px;
  z-index: -1;

  svg {
    height: 700px;
    width: 500px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      height: 600px;
      width: 400px;
      display: none;
    }

    
  } 
`;

const LoginWrapper = styled.div`
  background: white;
  padding: 2rem 2rem 1rem;
  min-width: 500px;
  position: relative;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); */
  margin: 0 auto;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    min-width: 0;  
    width: 100%;
  }

`;

const LoginBackButton = styled.button`
  padding: 1rem 0;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  &:hover, &:active, &:focus {
    outline: none;
    border-color: #000010; 
  }
   @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    left: 1rem; 
  }
`;

const LoginBackIcon = styled.img`
  height: 1rem;
`;
const LoginHeaderImage = styled.img`
  width: 94.51px;
  height: 40px;
`;

const LoginFormContainer = styled.form``;

const ButtonContainer = styled.div`
  text-align: right;
  max-width: 313px;
  margin: 1rem 0 1rem auto;  
`;

const LoginHeader = styled.h2`
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #191919;
  padding-bottom: 1rem;
  text-align: left;

  span {
    text-decoration: underline;
    text-decoration-color: #0a7ef9;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    max-width: unset;
  }
`;

const LoginNavbar = styled.div`
  width: 100%;
  height: 100px;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.18);
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    min-width: 0;  
    height: 80px;
  padding: 1rem;
  }
`;

const LoginTabletDesktopView = props => {
  const [open, setOpen] = React.useState(false);

  
  const leftLineWrapperStyleProps = useSpring({
    delay: 500,
    from: { transform: 'translate3d(-500px,500px,0)' },
    to: { transform: 'translate3d(0,0,0)' },
  });

  const rightLineStyleProps = useSpring({
    delay: 500,
    from: { transform: 'translate3d(500px,500px,0)' },
    to: { transform: 'translate3d(0,0,0)' },
  });

  const handleSubmit = async (value) => { 
    try {
      const response = await request({
        method: 'get',
        endpoint: 'health',
        config: {},
        companyName: value.company
      }).then(data => {
        window.location.href = `https://${value.company}.insent.ai`;
      }).catch(e => {
        console.log("err",e);
        setOpen(!open)
      })

    } catch {
      setOpen(!open);
    }
    
  }
  return (
      <LoginContainer>
      <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(!open)}>
        <Alert onClose={() => setOpen(!open)} severity="error">
          Company name not found.
        </Alert>
      </Snackbar>
        <LoginWrapper>
        <LoginHeader>
          Sign in 
        </LoginHeader>
        <LoginDesc>
          Enter your company name below.
        </LoginDesc>
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              if (values) {
                handleSubmit(values)
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  width="100%"
                  type="text"
                  name="company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company}
                  placeholder="eg. zoominfo"
                  required
                />
                <ButtonContainer>
                  <Button >Continue</Button>
                </ButtonContainer>
                
              </form>
            )}
          </Formik>
        </LoginWrapper>
      <LeftWrapper style={leftLineWrapperStyleProps}>
        <LeftLine />
      </LeftWrapper>
      <RightWrapper style={rightLineStyleProps}>
        <RightLine />
      </RightWrapper>
      </LoginContainer>
  );
};

const Login = () => {

  return (
    <>
      <LoginNavbar>
        <LoginBackButton onClick={() => window.location.href = "https://www.zoominfo.com/chat"}><LoginBackIcon src={LeftArrow} /></LoginBackButton>
       <a href="https://www.zoominfo.com/chat">
        <LoginHeaderImage src={Logo} alt="zoominfo logo" />
       </a>
      </LoginNavbar>
      
      <LoginTabletDesktopView />
      <Footer />
    </>
  );
};

Login.propTypes = {};

export default Login;
