import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import salesforceHero from 'resources/img/salesforceHero.png';
import salesforceHero2x from 'resources/img/salesforceHero2x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;
  padding: 60px 0;
  align-items: center;
  display: flex;
  background: ${p => p.theme.palette.blue.one};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
`;


const HeroContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 50px;
    justify-content: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.blue.three};
    }
  }
`;

const Hero = props => {
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <HeroContainer>
                
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <HeroContentWrapper>
                                <div>
                                  <Text fontSize="16px" color={theme.palette.text.three} mdTextAlign="center" marginBottom="5px" smMarginBottom="14px">Live Chat Platform Powered By Your CRM</Text>
                                  <Text fontSize="40px" smFontSize="23px" fontWeight={700} mdTextAlign="center">
                                    Plug Salesforce into Insent
                                  </Text>
                                  <Text fontSize="18px" smFontSize="13px" mdTextAlign="center" marginTop="12px" marginBottom="36px" smMarginBottom="36px" maxWidth="440px">
                                    Convert more prospects on site through personalized, one-on-one conversations that are driven by your Salesforce data.
                                  </Text>
                                  <CallToAction justify="left" mdJustify="center"/>
                                </div>
                            </HeroContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Image height="100%" width="100%" maxWidth="610px" margin="0 auto" mdPaddingTop="60px" mdPaddingBottom="60px" display="flex" hdSrc={salesforceHero2x} src={salesforceHero} />
                        </Grid>
                    </Grid>
                </Container>
            </HeroContainer>
        </Waypoint>
    );
};

Hero.propTypes = {};

export default Hero;
