import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Lines from 'resources/img/linesXC.svg';
import './styles.css';

import Header from '../Home/Header.js';
import Footer from '../Home/Footer.js';


const HeaderWrapper = styled.div`
    min-height: 6rem;
`;
const TermsContainer = styled.div`
  background-image: url(${Lines});
  padding: 3rem 0;
`;

const ParagraphTag = styled.p`
    margin: 0;
    color: #000000;
    font-size: 11pt;
    text-align: justify;
    margin-bottom: 0.5rem;
`;

const ListTag = styled.li`
    color: #000000;
    font-size: 11pt;
    text-align: justify;

`;

const Terms = props => {
	return (
		<>
			<HeaderWrapper>
				<Header />
			</HeaderWrapper>
			<TermsContainer>
				<Container>
					<Grid container justify="center">
						<Grid item xs={12} lg={8}>
							<p class="c0"><span class="c5 c7">Insent Inc. Terms of Service</span></p>
							<p class="c0"><span class="c1">Effective Date: February 08, 2020</span></p>
							<p class="c0 c4"><span class="c1"></span></p>
							<p class="c0"><span class="c1">Please read these Insent Inc. Terms of Service (the &ldquo;Agreement&rdquo;)
							carefully before using the http://www.insent.ai website (the &ldquo;Website&rdquo;) or http://app.Insent.ai
							(the &ldquo;App&rdquo;) (together, or individually, the &ldquo;Services&rdquo;) operated by Insent Inc.
							(&ldquo;Insent&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;). Your access to and use of
							the Services is conditioned upon your acceptance of and compliance with this Agreement. This Agreement
							applies to all visitors, users, and others (each a &ldquo;Customer,&rdquo; &ldquo;you&rdquo;, or
							&ldquo;your&rdquo;) who wish to access or use the Services. If you are accessing or using the Services on
							behalf of your company, you represent that you are authorized to accept this Agreement on behalf of your
            company, and all references to &quot;you&quot; or &quot;Customer&quot; reference your company.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">IMPORTANT NOTICE:</span><span class="c1">&nbsp;THIS AGREEMENT IS SUBJECT TO BINDING
							ARBITRATION (WHICH REQUIRES THE USE OF ARBITRATION TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS) AND A
            WAIVER OF CLASS ACTION RIGHTS, AS DETAILED IN SECTION 10.</span></p>
							<p class="c0"><span class="c1">BY ACCESSING OR USING THE SERVICES YOU AGREE TO BE BOUND BY THIS AGREEMENT, AND IT IS
							IMPORTANT THAT YOU REVIEW THIS AGREEMENT CAREFULLY BEFORE USING THE SERVICES. IF YOU DISAGREE WITH ANY PART
							OF THIS AGREEMENT THEN YOU DO NOT HAVE PERMISSION TO ACCESS THE SERVICES. FOR THE AVOIDANCE OF DOUBT, EACH
            PARTY EXPRESSLY AGREES THAT THIS AGREEMENT IS LEGALLY BINDING UPON IT.</span></p>
							<p class="c0"><span class="c1">The &ldquo;Effective Date&rdquo; of this Agreement is the date which is the earlier
							of (a) Customer&rsquo;s initial access to any Services through any online provisioning, registration, or
            order process; or (b) the effective date of the first Order Form referencing this Agreement.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">1. Definitions</span></p>
							<p class="c0"><span class="c2">&ldquo;Customer Content&rdquo;</span><span class="c1">&nbsp;means content, data, and
							information, including text, graphics, videos, or other material, submitted, uploaded, imported, or
							otherwise provided to or through the Services by Customer or by a third party on behalf of or for the
							benefit of Customer, including Customer&rsquo;s customers and prospective customers and users of Customer
            Properties.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Customer Properties&rdquo;</span><span class="c1">&nbsp;means Customer&rsquo;s
							websites, applications, or other offerings owned and operated by (or for the benefit of) Customer through
            which Customer uses the App.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Documentation&rdquo;</span><span class="c1">&nbsp;means Insent&rsquo;s
							then-current generally available documentation, specifications, user manuals, for the Services, which can be
							located at www.Insent.ai or such other URL as Insent may provide from time to time, as well as any
							documentation included in or attached to any Order Form or such other Services-related documents provided to
            Customer.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Invite Link&rdquo;</span><span class="c1">&nbsp;means the link through which
            Customer may sign up and make payment in order to receive Services.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Laws&rdquo;</span><span class="c1">&nbsp;means all applicable local, state,
							federal and international laws, regulations and conventions, including, without limitation, those related to
							data privacy and data transfer, international communications, and the exportation of technical or personal
            data.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Order Form&rdquo;</span><span class="c1">&nbsp;the document describing the
            Services and pricing purchased by Customer and which incorporates this Agreement.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Personal Data&rdquo;</span><span class="c1">&nbsp;means any information
            relating to an identified or identifiable individual.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Sensitive Data&rdquo;</span><span class="c1">&nbsp;means (a) Personal Data
							subject to specialized privacy or security regimes, including without limitation the Health Insurance
							Portability and Accountability Act (&ldquo;HIPAA&rdquo;) and the standards promulgated by the Payment Card
							Industry (&ldquo;PCI&rdquo;) Security Standards Council (&ldquo;PCI SSC&rdquo;); (b) Personal Data
							considered to be a &ldquo;special category of personal data&rdquo; under the EU General Data Protection
							Regulation (&ldquo;GDPR&rdquo;); (c) Personal Data that, if breached, could trigger breach notification
							requirements or increase liability for Customer or Insent, including without limitation Social Security
							numbers, governmental identification information, health or medical information, biometric information, and
							information about criminal history; and (d) any other information that you consider to be confidential,
							proprietary, a trade secret, material non-public information, or that you would not want disclosed to a
            third party.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;Subscription Term&rdquo;</span><span class="c1">&nbsp;means the term for the
            Services set forth in the applicable Agreement or Order Form or Invite Link or Subscription page.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">&ldquo;User&rdquo;</span><span class="c1">&nbsp;means an individual employee,
							consultant, contractor, or agent of Customer who has been authorized by Customer to use the Services on
            behalf of Customer or its affiliates.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">2. General Commercial Terms</span></p>
							<p class="c0"><span class="c2 c5">&nbsp;</span></p>
							<p class="c0"><span class="c2">Access</span><span class="c1">. We will provide you with access to the App for the
							duration of the Subscription Term. Customer may access and use the App solely for its own benefit and in
							accordance with this Agreement, the Documentation, and any scope of use restrictions designated in the
							applicable Order Form. Only Users, as defined herein as those authorized by Customer to use the App, may use
							and access the App, and Customer agrees that it is responsible for its User(s)&rsquo;s compliance with this
            Agreement.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Fees and Payment.</span><span class="c1">&nbsp;You agree to pay all applicable fees
							for the Services as set forth on the applicable Order Form, unless you provide written notice of a dispute
							regarding such fees no later than thirty (30) days after the invoice date. The fees set forth in an Order
							Form are valid for the Subscription Term and thereafter may be subject to an automatic adjustment increase
							of up to ten percent (10%) per year. Customer is responsible for paying all taxes, and all taxes are
							excluded from any fees set forth in the applicable Order Form. If Customer is required by Laws to withhold
							any taxes from Customer&rsquo;s payment, the fees payable by Customer will be increased as necessary so that
							after making any required withholdings, Insent receives and retains (free from any liability for payment of
							taxes) an amount equal to the amount it would have received had no such withholdings been made. Any late
							payments will be subject to a service charge equal to 1.5% per month of the amount due or the maximum amount
            allowed by law, whichever is less.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Any and all payments you make to us for the Services are final and
            non-refundable.</span><span class="c1">&nbsp;If we agree to accept your payment via invoice rather than by
								credit card, full payment must be received within thirty (30) days from the invoice date. We will provide
								you with notice of non-payment of any undisputed amount due. Unless the full amount not in dispute has been
								paid, we may suspend your access to the Services thirty (30) days after such notice. We will not suspend the
								access to the Services while you are disputing the applicable charges reasonably and in good faith and are
								cooperating diligently to resolve the dispute. If your Services are suspended for non-payment, we may charge
            a reactivation fee to reinstate your access to the Services.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Payment Using Credit Card.</span><span class="c1">&nbsp;If you are paying via a
							credit card, you authorize us to charge your credit card or bank account for all fees payable during the
							Subscription Term. You further authorize us to use a PCI-compliant third party to process payments and
							consent to the disclosure of your payment information to such third party. If you are paying by invoice, we
							will invoice you no later than thirty (30) days before the beginning of the Subscription Term and all other
							times during the Subscription Term when fees are payable. Customer acknowledges that for certain Credit
							Cards, the issuer of Customer&rsquo;s Credit Card may charge a foreign transaction fee or other
            charges.</span></p>
							<p class="c0"><span class="c1">If a payment is not successfully settled due to expiration of a Credit Card,
							insufficient funds, or otherwise, Customer remains responsible for any amounts not remitted to Insent, and
							Insent may, in its sole discretion, (a) invoice Customer directly for the deficient amount, (b) continue
							billing the Credit Card once it has been updated by Customer (if applicable), or (c) terminate this
            Agreement.</span></p>
							<p class="c0"><span class="c2">Payment of Outstanding Fees.</span><span class="c1">&nbsp;Upon any termination or
							expiration of the Subscription Term, Insent will charge Customer&rsquo;s Credit Card (or invoice Customer
							directly) for any outstanding fees for Customer&rsquo;s use of the Services during the Subscription Term,
            after which Insent will not charge Customer&rsquo;s Credit Card for any additional fees.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">3. Customer Obligations, Limitations of Use, and Rights</span></p>
							<p class="c0"><span class="c5 c2">&nbsp;</span></p>
							<p class="c0"><span class="c2">Acceptable Use.</span><span class="c1">&nbsp;The acceptable use policy of Insent
							includes a) No Illegal, Harmful, of Offensive Use of Content b) No Security Violations c) No Network
            Abuse</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Security.</span><span class="c1">&nbsp;You are responsible for maintaining the
							confidentiality of any password and username you are given or select in connection with the Services, and
							you are fully responsible for all activities that occur under your password or account. You agree to
							immediately notify Insent if you become aware of any unauthorized use of your password or username or any
            other breach of security.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Age.</span><span class="c1">&nbsp;You must be eighteen (18) years or older to
            register for any parts of the Services.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Prohibited and Unauthorized Use.</span><span class="c1">&nbsp;You agree that you will
							not, directly or indirectly, (1) make the Services available to, or use the Services for the benefit of,
							anyone other than yourself or the Users using the Services on your behalf; (2) sell, resell, license,
							sublicense, distribute, rent, lease the Services, or include any Services in a service bureau or outsourcing
							offering; (3) use the Services to send SPAM or store or transmit infringing, libelous, or otherwise
							unlawful, or tortious content, material, or data; (4) store or transmit material or data on or through the
							Services in violation of law or third-party rights, including without limitation privacy rights or any
							contract to which you are a party; (5) use the Services to store or transmit malicious or disruptive code;
							(6) interfere with or disrupt the integrity or performance of the Services or third-party data contained
							therein; (7) attempt to gain unauthorized access to the Services or its related systems or networks; (8)
							permit direct or indirect access to or use of the Services in a way that circumvents a contractual usage
							limit; (9) copy the Services or any part, feature, function, or user interface thereof; (10) frame or mirror
							any part of any Services, other than framing on your own internal intranets; (11) access or use the Services
							for benchmarking or similar competitive analysis purposes or in order to build a competitive product or
							service; (12) modify, translate, or create derivative works based on the Services or any underlying
							software; (13) decompile, disassemble, decipher, or reverse-engineer the Services, or otherwise attempt to
							derive any source code or underlying ideas or algorithms of any part of the Services (except to the extent
							such restriction is expressly prohibited by applicable statutory law); or (14) breach Insent&rsquo;s
							Acceptable Use Policy. Insent may suspend any User&rsquo;s access to any or all Services immediately and
            without notice in the event of a violation of this Section.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">No Sensitive Data.</span><span class="c1">&nbsp;YOU AGREE NOT TO USE THE SERVICES TO
							COLLECT, MANAGE, OR PROCESS SENSITIVE DATA. Insent WILL NOT BE RESPONSIBLE FOR ANY LIABILITY RESULTING FROM
            YOUR USE OF THE SERVICES TO COLLECT OR PROCESS SENSITIVE DATA.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Compliance with Laws.</span><span class="c1">&nbsp;Customer agrees to comply with all
							Laws in its use of the Services. Without limiting the generality of the foregoing, Customer will not engage
							in any unsolicited advertising, marketing, or other activities using the Services, including without
							limitation any activities that violate the Telephone Consumer Protection Act of 1991, CAN-SPAM Act of 2003,
            or any other anti-spam laws and regulations.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Deployment of Code.</span><span class="c1">&nbsp;Subject to all of the terms and
							conditions of this Agreement, Insent grants to Customer a limited, non-transferable, non-sublicensable,
							non-exclusive license during any applicable Subscription Term to copy JavaScript code provided to Customer
							by Insent (&ldquo;Insent Code&rdquo;) in the form provided by Insent on Customer Properties solely to
							support Customer&rsquo;s use of the App and otherwise in accordance with the Documentation and this
							Agreement. Customer must implement Insent Code on the Customer Properties in order to enable features of the
							App. Customer will implement all Insent Code in strict accordance with the Documentation and other
							instructions provided by Insent. Customer acknowledges that any changes made to the Customer Properties
							after initial implementation of Insent Code may cause the App to cease working or function improperly and
            that Intercom will have no responsibility for the impact of any such Customer changes.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Customer Content.</span><span class="c1">&nbsp;You are responsible for the Customer
							Content that you post on or through the Services, including its legality, reliability, and appropriateness.
							By posting Customer Content on or through the Services, you represent and warrant that: (a) the Customer
							Content you post on or through the Services is yours (you own it) and/or you have the right to use it and
							the right to grant us the rights and license as provided in this Agreement; (b) the posting of your Customer
							Content on or through the Services does not violate the privacy rights, publicity rights, copyrights,
							contract rights, or any other rights of any person or entity; (c) the Customer Content any terms of service,
							privacy policies or other agreements governing the Customer; and (d) the Customer Content does not violate
            any Laws, including without limitation those related to data privacy or security.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Monitoring Customer Content.</span><span class="c1">&nbsp;Insent has no obligation to
							monitor Customer Content. Nevertheless, Insent may monitor or review Customer Content as it chooses and
							reserves the right to remove, terminate access to, reject, restrict, or revise content that it deems
							inappropriate or otherwise objectionable for any reason whatsoever at any time, without prior notice and in
							Insent&rsquo;s sole discretion, in the event a User is found or reasonably believed to be infringing on a
							copyright or otherwise in violation of this Section. We take no responsibility and assume no liability for
            Customer Content you or any third-party posts on or through the Services.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Disclosures on Customer Properties.</span><span class="c1">&nbsp;Customer
							acknowledges that its use of the App requires that a unique cookie ID be placed on Customer Properties
							associated with each individual who accesses the Customer Properties. This cookie ID enables Insent to
							provide the App. Customer will include on each Customer Property a link to a privacy policy that discloses
							Customer&rsquo;s use of third-party tracking technology. Customer&rsquo;s privacy policy must (a) disclose
							how, and for what purposes, the data collected through third-party tracking technologies will be used and
							shared; and (b) provide clear and comprehensive information about the storing and accessing of cookies or
							other information on the individuals&rsquo; devices. For clarity, as between Customer and Insent, Customer
							will be solely responsible for providing the necessary notices to, and obtaining the necessary clearances,
            consents and approvals from, individuals under all applicable laws.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Child-Targeted Customer Properties.</span><span class="c1">&nbsp;Customer agrees that
							its Customer Properties are not targeted to, nor does Customer knowingly collect Personal Data from,
							children under the age of sixteen (16), unless Customer first obtains consent, as required by applicable
            law.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Customer Proprietary Rights.</span><span class="c1">&nbsp;You own and retain all the
							rights to your Customer Content and you are responsible for protecting those rights. This Agreement does not
							grant us any ownership rights to Customer Content. You grant us a non-exclusive, worldwide, royalty-free
							right to use, copy, store, transmit, modify, create derivative works of and display the Customer Content as
							necessary to provide the Services and as permitted by this Agreement. If you are using our Services on
							behalf of another party, then you represent and warrant that you have the sufficient and necessary rights
            and permissions to do so.</span></p>
							<p class="c0"><span class="c1">&zwj;</span></p>
							<p class="c0"><span class="c5 c2">4. Subscription Term and Termination</span></p>
							<p class="c0"><span class="c5 c2">&nbsp;</span></p>
							<p class="c0"><span class="c2">Term and Renewal.</span><span class="c1">&nbsp;Your initial Subscription Term will be
							set forth in the Master Services agreement or Order Form or Invite Link. At the expiration of the initial
							period, the Subscription Term will automatically renew for additional periods of the same duration unless
							either party provides the other party with written notice of non-renewal at least thirty (30) days prior to
            the expiration of the then-current term.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Termination.</span><span class="c1">&nbsp;If either party materially breaches any of
							its duties or obligations under this Agreement or an Order Form, and such breach is not cured within thirty
							(30) calendar days of the non-breaching party providing the breaching party of written notice of the breach,
            the non-breaching party may terminate this Agreement or the applicable Order Form, as applicable.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Trial Subscriptions.</span><span class="c1">&nbsp;If Customer receives free access or
							a trial or evaluation subscription to the App (a &ldquo;Trial Subscription&rdquo;), then Customer may use
							the App in accordance with the terms and conditions of this Agreement for a period of fourteen (14) days or
							such other period granted by Insent (the &ldquo;Trial Period&rdquo;). Trial Subscriptions are permitted
							solely for Customer&#39;s use to determine whether to purchase a paid subscription to the App. Trial
							Subscriptions may not include all functionality and features accessible as part of a paid Subscription Term.
							If Customer does not enter into a paid Subscription Term, this Agreement and Customer&rsquo;s right to
							access and use the App will terminate at the end of the Trial Period. Insent has the right to terminate a
							Trial Subscription at any time for any reason. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT,
							INSENT WILL HAVE NO WARRANTY, INDEMNITY, SUPPORT, OR OTHER OBLIGATIONS WITH RESPECT TO TRIAL
            SUBSCRIPTIONS.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Effect of Termination or Expiration.</span><span class="c1">&nbsp;Upon any expiration
							or termination of this Agreement, Customer will immediately cease any and all use of and access to all
							Services. Provided this Agreement was not terminated for Customer&rsquo;s breach, Customer may retain and
							use internally copies of all reports exported from the Services prior to termination. Customer acknowledges
							that following termination it will have no further access to any Customer Data input into any Service, and
							that Insent may delete any such data as may have been stored by Insent at any time. Except where an
							exclusive remedy is specified, the exercise of either party of any remedy under this Agreement, including
							termination, will be without prejudice to any other remedies it may have under this Agreement, by law or
            otherwise.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Survival.</span><span class="c1">&nbsp;All provisions of this Agreement, which by
							their nature should survive termination shall survive termination, including, without limitation, ownership
            provisions, warranty disclaimers, indemnity, and limitations of liability.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">5. Insent Rights and Responsibilities</span></p>
							<p class="c0"><span class="c5 c2">&nbsp;</span></p>
							<p class="c0"><span class="c2">Insent Proprietary Rights.</span><span class="c1">&nbsp;You acknowledge that we
							retain all right, title, and interest in the Services, our name, logo, or other marks (the &ldquo;Insent
							Marks&rdquo;), and any related intellectual property rights, including, without limitation, all
							modifications, enhancements, derivative works, and upgrades thereto. You agree that you will not use or
							register any mark, business name, domain name, or social media account name or handle which incorporates in
							whole or in part the Insent Marks. In addition, information provided by Insent as part of provision of the
							Services is the property of Insent or used with permission. You may not distribute, modify, transmit, reuse,
							download, repost, copy, or use such information, whether in whole or in part, for commercial purposes or for
            personal gain, without express advance written permission from us.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Aggregated Data.</span><span class="c1">&nbsp;Notwithstanding anything to the
							contrary herein, Customer agrees that Insent may aggregate, collect, use, or disclose technical and other
							data about Customer&rsquo;s use of the Services that is non-personally identifiable with respect to
							Customer, and that Insent may use such data to analyze, improve, support, and operate the Services, optimize
							Insent operations, and otherwise for any business purpose during and after the term of this Agreement,
							including without limitation to generate best practice guidance, recommendations, or similar reports for
							distribution to and consumption by Customer and others. For clarity, this Section does not give Insent the
            right to identify Customer as the source of any data described in this Section.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">6. Indemnification</span></p>
							<p class="c0"><span class="c1">Insent will indemnify, defend, and hold you harmless against any claim made or
							brought by a third party, and any resulting damages or costs (including reasonable attorneys&rsquo; fees)
							awarded by a court or included as part of a final settlement (each a &ldquo;Claim&rdquo;), against you
							alleging that your use of the Services in accordance with this Agreement or any Order Form infringes or
							misappropriates such third party&rsquo;s intellectual property rights. The foregoing obligations do not
							apply with respect to any Claim based on or arising from (a) your unauthorized or illegal use of the
							Services, (b) your breach of this Agreement or any applicable Order Form or other contract between you and
							Insent, (c) your use of the Services combined with products, services, processes, content, or materials not
							supplied by Insent, or (d) the unauthorized use of the Services by a third party using your User
							information. You will indemnify, defend, and hold us harmless, at your expense, against any Claim brought
							against us (and our officers, directors, employees, agents, service providers, licensors, and affiliates) by
							a third party based upon or arising out of Customer Content or any of the foregoing clauses (a) &ndash; (d).
							The indemnified party will promptly: notify the indemnifying party in writing of any such Claim; give the
							indemnifying party sole control of the defense or settlement of such a Claim; and provide the indemnifying
							party with any and all information and assistance reasonably requested by it in connection with the defense
							or settlement of the Claim. The indemnifying party shall not accept any settlement that (i) requires the
							indemnified party to make an admission of fault or wrongdoing; or (ii) imposes liability not covered by
            these indemnification provisions without the indemnified party&rsquo;s consent.</span></p>
							<p class="c0"><span class="c1">&zwj;</span></p>
							<p class="c0"><span class="c5 c2">7. Confidentiality</span></p>
							<p class="c0"><span class="c1">Each party acknowledges that as a result of the Services provided, such party (the
							&ldquo;Receiving Party&rdquo;) may receive information from the other party (the &ldquo;Disclosing
							Party&rdquo;) that is designated as Confidential Information communicated orally will be considered
							Confidential Information if the information is confirmed in writing as being Confidential Information within
							a reasonable time after the initial disclosure (&ldquo;Confidential Information&rdquo;). Your Confidential
							Information includes, but is not limited to, internal business information; contact information, including
							names and email addresses of clients and prospective clients; and other information about clients and
            prospective clients.</span></p>
							<p class="c0"><span class="c1">Insent&rsquo;s Confidential Information includes, but is not limited to, information
							Insent provides in its provision of the Services, its business and marketing plans, technology and technical
							information, product plans and designs, and business processes disclosed by Insent. The Receiving Party
							acknowledges that the Disclosing Party&rsquo;s Confidential Information will remain solely the Disclosing
							Party&rsquo;s property and proprietary information of the Disclosing Party and that the Receiving
							Party&rsquo;s knowledge of the Disclosing Party&rsquo;s Confidential Information may enable the Receiving
							Party to cause the Disclosing Party&rsquo;s irreparable harm upon the unauthorized disclosure of such
							matters. The Receiving Party covenants and agrees that it will not use or appropriate for its own behalf, or
							disclose or communicate, directly or indirectly, any of the Disclosing Party&rsquo;s Confidential
							Information to any external third-party individual, firm, company or other entity or person without the
							Disclosing Party&rsquo;s prior written consent, except to the extent necessary to perform its obligations
            under the Agreement.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">&zwj;The Receiving Party shall take all commercially reasonable steps required to
							protect the Disclosing Party&rsquo;s Confidential Information from unauthorized disclosure to any third
							party and shall keep the Confidential Information protected while stored with industry standard and
            commercially reasonable measures typically used in similar commercial sectors.</span></p>
							<p class="c0"><span class="c1">The foregoing obligations of confidentiality do not apply to any information that:
							(a) is made publicly known without fault of the Receiving Party; (b) is lawfully disclosed to the Receiving
							Party by a third-party having the right to disclose the information; (c) is produced by the Receiving Party
							pursuant to legal process, or under a court or government agency order to be produced, provided that the
							Receiving Party shall promptly notify the Disclosing Party of the request or order so that the Disclosing
							Party has a timely opportunity to seek a protective order or other appropriate relief; or (d) is developed
							by the Receiving Party independently of the receipt of the Disclosing Party&rsquo;s Confidential
            Information.</span></p>
							<p class="c0"><span class="c1">&zwj;The Receiving Party shall, at the Disclosing Party&rsquo;s option, return or
							destroy all Confidential Information in Receiving Party&rsquo;s possession, and all copies thereof, at any
            time upon the Disclosing Party&rsquo;s request.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Publicity.</span><span class="c1">&nbsp;You grant us the right to add your name and
            company logo to our marketing materials, including our customer list and Website.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">8. Limited Warranties</span></p>
							<p class="c0"><span class="c5 c2">&nbsp;</span></p>
							<p class="c0"><span class="c2">Limited Warranties.</span><span class="c1">&nbsp;Insent represents and warrants, for
							Customer&#39;s benefit only, that the Services will be provided in a professional and workmanlike manner in
							accordance with industry standards the applicable Documentation. Insent&rsquo;s sole liability (and
							Customer&rsquo;s sole and exclusive remedy) for any breach of this limited warranty will be, at no charge to
							Customer, for Insent to use commercially reasonable efforts to correct the reported non-conformity, or if
							Insent determines such remedy to be impracticable, either party may terminate the applicable Subscription
							Term, and Customer will receive as its sole remedy a refund of any fees Customer has pre-paid for use of
							such Services for the terminated portion of the applicable Subscription Term. The limited warranty set forth
							in this Section will not apply: (a) unless Customer makes a claim within thirty (30) days of the date on
							which Customer first noticed the non-conformity; (b) if the error was caused by Customer&rsquo;s misuse or
							modifications to the Services or use of the Services in conjunction with third-party hardware, software, or
            services; or (c) to use the Services on a no-charge, trial, or evaluation basis.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Warranty Disclaimers.</span><span class="c1">&nbsp;EXCEPT AS EXPRESSLY PROVIDED
							HEREIN, THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo;. FURTHER, EXCEPT AS PROVIDED HEREIN, NEITHER PARTY
							MAKES ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, AND EACH PARTY
							SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS
							FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND ANY
							WARRANTIES ARISING OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE IN TRADE. Insent MAKES NO
							REPRESENTATIONS OR WARRANTIES REGARDING (A) THE SUITABILITY OR COMPLETENESS OF THE SERVICES, INCLUDING
							WHETHER THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; (B) THE RESULTS CUSTOMER MAY OBTAIN BY USING THE
							SERVICES; (C) THAT THE SERVICES WILL MEET CUSTOMER&rsquo;S REQUIREMENTS; OR (D) THE PRESERVATION OR
            MAINTENANCE OF THE CUSTOMER DATA WITHOUT LOSS OR CORRUPTION.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">9. Limitation of Liability</span></p>
							<p class="c0"><span class="c5 c2">&nbsp;</span></p>
							<p class="c0"><span class="c2">Cap.</span><span class="c1">&nbsp;EXCEPT FOR EXCLUDED CLAIMS, EACH PARTY&rsquo;S
							AGGREGATE LIABILITY WILL BE LIMITED TO THE TOTAL AMOUNTS YOU HAVE ACTUALLY PAID TO Insent IN THE TWELVE
							(12)-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO A CLAIM. &ldquo;EXCLUDED CLAIMS&rdquo; MEAN
							ANY CLAIMS ARISING FROM OR RELATING TO (A) YOUR FAILURE TO PAY FEES, (B) YOUR INDEMNIFICATION OBLIGATIONS,
							(C) YOUR VIOLATION OF ANY OBLIGATIONS UNDER THIS AGREEMENT, (D) YOUR USE OR INTEGRATION OF THIRD-PARTY
            PRODUCTS, OR (E) A PARTY&rsquo;S VIOLATION OF THE OTHER PARTY&rsquo;S INTELLECTUAL PROPERTY RIGHTS.</span>
							</p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">IN NO EVENT SHALL EITHER PARTY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR
							CONSEQUENTIAL DAMAGES OR LOSS OF PROFITS, REVENUE, DATA, OR BUSINESS OPPORTUNITIES, FAILURE OF SECURITY
            MECHANISMS, OR INTERRUPTION OF BUSINESS.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Nature of Claims.</span><span class="c1">&nbsp;The parties agree that the waivers and
							limitations specified in this Section apply regardless of the form of action, whether in contact, tort
							(including negligence), strict liability, or otherwise and will survive and apply even if any limited remedy
            specified in this Agreement is found to have failed of its essential purpose.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">10. Dispute Resolution, Arbitration of Claims, and Class Action Waiver</span></p>
							<p class="c0"><span class="c1">YOU SHOULD READ THIS SECTION CAREFULLY, AS IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL
            RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Dispute Resolution.</span><span class="c1">&nbsp;You may contact us at
							privacy@Insent.ai to address any concerns you may have regarding the Services. Insent is able to resolve
							most concerns quickly to our Users&rsquo; satisfaction. You and Insent agree to use best efforts through
							Insent&rsquo;s internal dispute resolution processes to settle any dispute, claim, question, or disagreement
							and engage in good faith negotiations, which shall be a condition to either party initiating a lawsuit or
            arbitration.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Arbitration.</span><span class="c1">&nbsp;If the parties do not reach an agreed upon
							solution within a period of thirty (30) days from the time informal dispute resolution under the initial
							dispute resolution provision, then either party may initiate binding arbitration as the sole means to
							resolve claims, subject to the terms set forth below. Any claim that you might have against Insent must be
							resolved through binding arbitration before the American Arbitration Association using its Commercial
            Arbitration Rules and must be brought within one (1) year of the claim arising.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">The arbitrator, and not any federal, state, or local court or agency, shall have
							exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability,
							enforceability or formation of this Agreement, including, but not limited to any claim that all or any part
							of this Agreement is void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be
							empowered to grant whatever relief would be available in a court under law or in equity. The
							arbitrator&rsquo;s award shall be written, and binding on the parties and may be entered as a judgment in
            any court of competent jurisdiction.</span></p>
							<p class="c0"><span class="c1">YOU UNDERSTAND AND AGREE THAT YOU WILL ARBITRATE WITH INSENT IN YOUR INDIVIDUAL OR
							CORPORATE CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS. Your claim may not be
							joined with any claim of any other person, and there shall not be authority for any dispute to be arbitrated
							on a class-action basis. If any court or arbitrator determines that the class action waiver set forth in
							this Section is void or unenforceable for any reason or that an arbitration can proceed on a class basis,
							then the arbitration provisions in this Section shall be deemed null and void in its entirety and the
            parties shall be deemed to have not agreed to arbitrate disputes.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">You understand and agree that unless you can demonstrate to Insent that arbitration
							in Washington state would create an undue burden for you, any arbitration hearing will be held in Washington
							State. If your claim is successful in arbitration, Insent agrees to reimburse your reasonable
            attorneys&rsquo; fees and costs.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">You understand and agree that by entering into this Agreement, you and Insent are
							each waiving the right to a jury trial or a trial before a judge in a public court. In the absence of this
							arbitration provision, you and Insent might otherwise have had a right or opportunity to bring disputes in a
							court, before a judge or jury, and/or to participate or be represented in a case filed in court by others
							(including class actions). Other rights that you would have if you went to court, such as the right to
            appeal and to certain types of discovery, may be more limited or may also be waived.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">Notwithstanding the parties&rsquo; decision to resolve all disputes through
							arbitration, either party may bring an action in state or federal court to protect its intellectual property
							rights (meaning patents, copyrights, moral rights, trademarks, and trade secrets, but not privacy or
            publicity rights).</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">You have the right to opt out and not be bound by the arbitration and class action
							waiver provisions set forth in this Section by sending written notice of your decision to opt out to the
							following address: Insent Inc, Startup Hall, 1100 NE Campus Pkwy suite 200, Seattle, WA 98105. The notice
							must be sent within thirty (30) days of the Effective Date; otherwise you shall be bound to arbitrate
							disputes in accordance with the terms of those paragraphs. If you opt out of these arbitration provisions,
            Insent also will not be bound by them.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Choice of Law.</span><span class="c1">&nbsp;For any dispute not subject to
							arbitration, you and Qualified agree to submit to the personal and exclusive jurisdiction of and venue in
							the federal and state courts located in San Francisco County, California. You further agree to accept
							service of process by mail, and hereby waive any and all jurisdictional and venue defenses otherwise
            available.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Injunctive Relief.</span><span class="c1">&nbsp;Notwithstanding the above provisions,
							Qualified may apply for injunctive remedies (or an equivalent type of urgent legal relief) in any
            jurisdiction.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">11. Privacy Policy</span></p>
							<p class="c0"><span class="c11">Please review our Privacy Policy, which also governs your use of the Services, to
							understand our practices regarding the information we collect about you through the Services. By using the
            Services, you indicate that you have read and understand our</span><span class="c11"><a class="c6"
									href="https://www.google.com/url?q=https://www.qualified.com/legal/privacy&amp;sa=D&amp;ust=1592569911404000">&nbsp;</a></span><span
										class="c8"><a class="c6"
											href="https://www.google.com/url?q=https://www.qualified.com/legal/privacy&amp;sa=D&amp;ust=1592569911405000">Privacy
                Policy</a></span><span class="c1">, including its disclosures regarding collection, use, and disclosure
            of your information in accordance with our Privacy Policy</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c5 c2">12. General Provisions</span></p>
							<p class="c0"><span class="c2">Force Majeure.</span><span class="c1">&nbsp;Neither party will be responsible for
							failure or delay of performance if caused by: an act of war, hostility or sabotage; act of God; electrical,
							internet or telecommunication outage that is not caused by the obligated party; government restrictions; or
							other event outside the reasonable control of the obligated party. Each party will use reasonable efforts to
            mitigate the effect of a force majeure event.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Relationship of the Parties.</span><span class="c1">&nbsp;The parties understand and
            agree that no joint venture, partnership, employment, or agency relationship exists between us.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Compliance with Laws</span><span class="c1">. We will comply with all applicable laws
            in our provision of the Services and in our processing of Customer Content.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">No Waiver.</span><span class="c1">&nbsp;No delay in exercising any right or remedy or
							failure to object will be considered a waiver of such right or remedy, or of any other right or remedy. A
            waiver on one occasion shall not be a waiver of any right or remedy on any future occasion.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Severability.</span><span class="c1">&nbsp;If any part of this Agreement or of an
							Order Form is determined to be invalid or unenforceable by applicable law, then the invalid or unenforceable
							provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent
            of the original provision and the remainder of this Agreement will continue in effect.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Notices.</span><span class="c1">&nbsp;Notice to Qualified will be sent to the contact
							address set forth herein and will be deemed delivered as of the date the notice is actually received. We
							will send you notices at the address you have provided in your Qualified subscription account information.
							We may give electronic notices by general notice via the Services or may give electronic notices specific to
							you by email to your email address(es) on record in our account information for you. You must keep all of
            your account information current.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Entire Agreement.</span><span class="c1">&nbsp;This Agreement (together with any
							Order Forms and Invite Links) constitute the entire agreement between us regarding our Services and
            supersedes and replaces any prior agreements we might have had between us regarding the Services.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Assignment.</span><span class="c1">&nbsp;Neither party may assign any of its rights
							or obligations hereunder, whether by operation of law or otherwise, without the other party&rsquo;s prior
							written consent (not to be unreasonably withheld); provided, however, either party may assign this Agreement
							(including all Order Forms and Invite Links), upon providing written notice to the other party, but without
							the other party&rsquo;s consent, to an affiliate or in connection with a merger, acquisition, corporate
            reorganization, or sale of all or substantially all of its stock or assets.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">No Third-Party Beneficiaries.</span><span class="c1">&nbsp;No person or entity not a
            party to the Agreement will be a third-party beneficiary.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Authority.</span><span class="c1">&nbsp;Each party represents and warrants that (a)
							it has full corporate power and authority, and has obtained all corporate approvals, permissions, and
							consents necessary, to enter into this Agreement and to perform its obligations hereunder; (b) this
							Agreement is legally binding upon it and enforceable in accordance with its terms; and (c) the execution,
							delivery, and performance of this Agreement do not and will not conflict with any agreement, instrument,
            judgment, or understanding, oral or written, to which it is a party or by which it may be bound.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Precedence.</span><span class="c1">&nbsp;In the event of a conflict of terms between
            this Agreement and any Order Form, the Order Form will control.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Governing Law.</span><span class="c1">&nbsp;This Agreement and your relationship with
							us shall be governed and construed in accordance with the laws of the State of California, United States,
            without regard to its conflict of law provisions.</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c2">Changes.</span><span class="c1">&nbsp;We reserve the right, at our sole discretion,
							to modify or replace this Agreement at any time. If a revision is material, we will provide at least 30
							days&rsquo; notice prior to any new terms taking effect. What constitutes a material change will be
							determined at our sole discretion. By continuing to access or use our Services after any revisions become
							effective, you agree to be bound by the revised terms of the Agreement. If you do not agree to the new
            terms, you are no longer authorized to use the Services.</span></p>
							<p class="c0"><span class="c1">&zwj;</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c0"><span class="c1">&nbsp;</span></p>
							<p class="c4 c10"><span class="c1"></span></p>
							<p class="c10 c4"><span class="c1"></span></p>
						</Grid>
					</Grid>
				</Container>
			</TermsContainer>
		</>
	);
};

Terms.propTypes = {};

export default Terms;